import AWS from 'aws-sdk';

export function uploadFile (fileNewName, file, car_id) {
  const AWS_S3_CONFIG = {
    accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_S3_ACCESS_SECRET,
    region: process.env.REACT_APP_region,
    bucket: process.env.REACT_APP_Bucket_SHARIK
  };

  const spacesEndpoint = new AWS.Endpoint('s3.us-east-2.amazonaws.com');

  const s3 = new AWS.S3({
    endpoint: spacesEndpoint,
    accessKeyId: AWS_S3_CONFIG.accessKeyId,
    secretAccessKey: AWS_S3_CONFIG.secretAccessKey
  });

  const params = {
    ACL: 'public-read',
    Bucket: `${AWS_S3_CONFIG.bucket}`,
    Key: `car/${car_id}/${file.name}`,
    Body: file,
    ContentType: file.type
  };
  return s3.upload(params, function (err, data) {
    if (err) {
      return false;
    }
    return data;
  });
};
