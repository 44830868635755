import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { useEffect, useState } from 'react';
import config from '../../../util/config.js';
import { carObj, ResponseInterface } from './../../../util/types';
import { userIdSelectorSelector } from '../login/corporateAuthSlice';
import { useSelector } from 'react-redux';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#fbfbfb',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'bold',
    fontSize: '15px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#fbfbfb'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

export default function CustomizedTables() {
  const [carList, setCarList] = useState<carObj[]>([]);
  const userID = useSelector(userIdSelectorSelector);

  useEffect(() => {
    if (userID) {
      axios
        .post(
          config.BASE_URL + 'listed-cars',
          {
            user_id: userID
          },
          {
            headers: {
              'x-access-token': localStorage.getItem('token') || '',
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )
        .then((res: ResponseInterface<carObj[]>) =>
          setCarList(Array.isArray(res.data?.response?.data) ?res.data?.response?.data : [])
        );
    }
  }, [userID]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: '100%' }} aria-label='customized table'>
        <TableHead sx={{ backgroundColor: '#fbfbfb' }}>
          <TableRow>
            <StyledTableCell>Car Name</StyledTableCell>
            <StyledTableCell align='right'>Type</StyledTableCell>
            <StyledTableCell align='right'>Rental Rate</StyledTableCell>
            <StyledTableCell align='right'>Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {carList?.map((row) => (
            <StyledTableRow key={row.car_id}>
              <StyledTableCell component='th' scope='row'>
                {row.car_make + ' ' + row.car_model}
              </StyledTableCell>
              <StyledTableCell align='right'>
                {row.car_category}
              </StyledTableCell>
              <StyledTableCell align='right'>{row.rental_rate}</StyledTableCell>
              <StyledTableCell align='right'>{'Active'}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
