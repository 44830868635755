import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { LatLng } from "../../../redux/locationSlice";
import {
  GetCarsListRequest,
  getCarsList as getCarsListApi,
  getNearByLocation,
  SearchCarRequest,
  getSearchCarsList,
} from "./carsAPI";

export interface CarData {
  car_category: string;
  car_id: string;
  car_make: string;
  car_model: string;
  currency_code: string;
  distance: string;
  duration_parameter: string;
  features: string[];
  image_path: string;
  isFav: string;
  latitude: number;
  longitude: number;
  owner_id: string;
  rating: string;
  rental_rate: string;
  trip_type: string;
  trips: number;
}

export interface CarSlice {
  isSuccess: boolean;
  isError: boolean;
  isFetching: boolean;
  errorMessage: string;
  formattedAddress: string;
  fromDate: string;
  endDate: string;
  list: CarData[];
}

export const getCarsListAsync = createAsyncThunk(
  "cars/getCarsList",
  async (req: GetCarsListRequest, thunkAPI) => {
    try {
      const response = await getCarsListApi(req);
      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getSearchCarsListAsync = createAsyncThunk(
  "cars/getSearchCarsList",
  async (req: SearchCarRequest, thunkAPI) => {
    try {
      const response = await getSearchCarsList(req);
      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getNearByLocationAsync = createAsyncThunk(
  "cars/getNearByLocation",
  async (req: LatLng, thunkAPI) => {
    try {
      const response = await getNearByLocation(req);
      return thunkAPI.fulfillWithValue(response);
    } catch (e: any) {
      console.log("Error", e);
      thunkAPI.rejectWithValue(e);
    }
  }
);

const initialState: CarSlice = {
  isSuccess: false,
  isError: false,
  isFetching: false,
  errorMessage: "",
  fromDate: "",
  endDate: "",
  formattedAddress: "",
  list: [],
};

export const carsSlice = createSlice({
  name: "cars",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = "";
      return state;
    },
    setSearchDate: (state, action: any) => {
      state.fromDate = action.payload.fromDate;
      state.endDate = action.payload.toDate;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCarsListAsync.fulfilled, (state, action: any) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.list = action.payload.data;
        return state;
      })
      .addCase(getCarsListAsync.rejected, (state, action: any) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload.userMessage;
      })
      .addCase(getCarsListAsync.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getSearchCarsListAsync.fulfilled, (state, action: any) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.list = action.payload.data;
        return state;
      })
      .addCase(getSearchCarsListAsync.rejected, (state, action: any) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload.userMessage;
      })
      .addCase(getSearchCarsListAsync.pending, (state) => {
        state.isFetching = true;
      });
  },
});

export default carsSlice.reducer;

export const { clearState, setSearchDate } = carsSlice.actions;

export const getCarsList = (state: RootState) => state.cars.list;
export const carsSelector = (state: RootState) => state.cars;
