import { Card, Typography, Grid, CardContent } from '@mui/material';
import { SideBar } from '../sidebar/sidebar';
import ListCar from './carList';
import OngoingTrip from './ongoingTrip';
import { BarChart } from './barChart'
import { LineChart } from './lineChart'

export function Dashboard () {
  return (
    <SideBar>
      <Grid container xs={12}>
        <Grid item xs={5.9}>
          <Card
            sx={{
              borderRadius: '12px',
              mb: 3
            }}
          >
            <CardContent>
              <Typography
                sx={{
                  mb: 2
                }}
              >
                Total Income
              </Typography>
              <BarChart />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={5.9}
          sx={{
            ml: '1%'
          }}
        >
          <Card
            sx={{
              borderRadius: '12px',
              mb: 3
            }}
          >
            <CardContent>
              <Typography
                sx={{
                  mb: 2
                }}
              >
                Income by Car
              </Typography>
              <LineChart />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* <Card
        sx={{
          borderRadius: '12px',
          mb: 3
        }}
      >
        <Grid
          container
          spacing={1}
          sx={{
            p: 1
          }}
        >
          <Grid container item xs={6}>
            <Typography
              sx={{
                mb: 2
              }}
            >
              Total Income
            </Typography>
            <BarChart />
          </Grid>
          <Grid container item xs={6}>
            <Typography
              sx={{
                mb: 2
              }}
            >
              Income by Car
            </Typography>
            <LineChart />
          </Grid>
        </Grid>
      </Card> */}
      <Grid container xs={12}>
        <Grid item xs={5.9}>
          <Card
            sx={{
              borderRadius: '12px',
              mb: 3,
              height: 530
            }}
          >
            <CardContent>
              <Typography
                sx={{
                  mb: 2
                }}
              >
                Top Cars
              </Typography>
              <ListCar />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={5.9}
          sx={{
            ml: '1%'
          }}
        >
          <Card
            sx={{
              borderRadius: '12px',
              mb: 3,
              height: 530
            }}
          >
            <CardContent>
              <Typography
                sx={{
                  mb: 2
                }}
              >
                Ongoing Trips
              </Typography>
              <OngoingTrip />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* <Card
        sx={{
          borderRadius: '12px'
        }}
      >
        <Grid
          container
          spacing={1}
          sx={{
            p: 1
          }}
        >
          <Grid container item xs={6}>
            <Typography
              sx={{
                mb: 2
              }}
            >
              Top Cars
            </Typography>
            <ListCar />
          </Grid>
          <Grid container item xs={6}>
            <Typography
              sx={{
                mb: 2
              }}
            >
              Ongoing Trips
            </Typography>
            <OngoingTrip />
          </Grid>
        </Grid>
      </Card> */}
    </SideBar>
  );
}
