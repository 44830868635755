import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
// import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import redLogo from './../../../assets/img/Sharik-logo/logoin-appp.png';
import CarRentalOutlinedIcon from '@mui/icons-material/CarRentalOutlined';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import { SxProps } from '@mui/material';
import { HeaderDashboard } from '../header/header';
import { userRoleSelectorSelector } from '../login/corporateAuthSlice';
import { useSelector } from 'react-redux';

const drawerWidth = 240;

const activeListItemSx: SxProps<Theme> | undefined = {
  borderLeft: '4px solid #e85656',
  color: '#E85656',
  pl: '10px'
};

const activeIconSx: SxProps<Theme> | undefined = {
  color: 'inherit'
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

export function SideBar(props: { children: React.ReactNode }) {
  const userRole = useSelector(userRoleSelectorSelector);
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const [aciveTab, setAvtiveTab] = React.useState<{
    location: string;
    title: string;
  }>({
    location: '/',
    title: 'Dashboard'
  });
  // const dispatch = useDispatch();

  // const logoutUser = () => {
  //   dispatch(logout());
  //   window.location.href = '/';
  // };

  React.useEffect(() => {
    if (location.pathname.includes('dashboard') || location.pathname === '/') {
      setAvtiveTab({
        location: 'dashboard',
        title: 'Dashboard'
      });
    }
    if (location.pathname.includes('cars')) {
      setAvtiveTab({
        location: 'cars',
        title: 'My Cars'
      });
    }
    if (location.pathname.includes('add-car')) {
      setAvtiveTab({
        location: 'add-car',
        title: 'Add Car'
      });
    }
    if (location.pathname.includes('car/')) {
      setAvtiveTab({
        location: 'cars',
        title: 'My Car'
      });
    }
    if (location.pathname.includes('trips')) {
      setAvtiveTab({
        location: 'trips',
        title: 'Trips'
      });
    }
    if (location.pathname.includes('upcoming-trips')) {
      setAvtiveTab({
        location: 'upcoming-trips',
        title: 'Upcoming Trips'
      });
    }
    if (location.pathname.includes('branches')) {
      setAvtiveTab({
        location: 'branches',
        title: 'My Branches'
      });
    }
    if (location.pathname.includes('add-branch')) {
      setAvtiveTab({
        location: 'add-branch',
        title: 'Add Branch'
      });
    }
  }, [location]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer variant='permanent' open={open}>
        {!open ? (
          <DrawerHeader
            sx={{
              justifyContent: 'center'
            }}
          >
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
            >
              <MenuIcon />
            </IconButton>
          </DrawerHeader>
        ) : (
          <DrawerHeader
            sx={{
              justifyContent: 'flex-start'
            }}
          >
            <IconButton onClick={handleDrawerClose}>
              <FormatAlignLeftIcon />
            </IconButton>
            <img
              src={redLogo}
              style={{
                width: '90px',
                margin: '10px',
                placeSelf: 'center'
              }}
            />
          </DrawerHeader>
        )}
        <List>
          <ListItem
            button
            onClick={() => {
              navigate('/dashboard');
            }}
            sx={
              aciveTab.location === 'dashboard'
                ? {
                    ...activeListItemSx
                  }
                : undefined
            }
          >
            <ListItemIcon
              sx={
                aciveTab.location === 'dashboard'
                  ? {
                      ...activeIconSx
                    }
                  : undefined
              }
            >
              <DashboardOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'Dashboard'} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              navigate('/cars');
            }}
            sx={
              aciveTab.location === 'cars'
                ? {
                    ...activeListItemSx
                  }
                : undefined
            }
          >
            <ListItemIcon
              sx={
                aciveTab.location === 'cars'
                  ? {
                      ...activeIconSx
                    }
                  : undefined
              }
            >
              <DirectionsCarFilledOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'My Car'} />
          </ListItem>
          {userRole === 6 && (
            <ListItem
              button
              onClick={() => {
                navigate('/add-car');
              }}
              sx={
                aciveTab.location === 'add-car'
                  ? {
                      ...activeListItemSx
                    }
                  : undefined
              }
            >
              <ListItemIcon
                sx={
                  aciveTab.location === 'add-car'
                    ? {
                        ...activeIconSx
                      }
                    : undefined
                }
              >
                <CarRentalOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'Add Car'} />
            </ListItem>
          )}
          {userRole === 6 && (
            <ListItem
              button
              onClick={() => {
                navigate('/trips');
              }}
              sx={
                aciveTab.location === 'trips'
                  ? {
                      ...activeListItemSx
                    }
                  : undefined
              }
            >
              <ListItemIcon
                sx={
                  aciveTab.location === 'trips'
                    ? {
                        ...activeIconSx
                      }
                    : undefined
                }
              >
                <HubOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'Trips'} />
            </ListItem>
          )}
          {userRole === 6 && (
            <ListItem
              button
              onClick={() => {
                navigate('/upcoming-trips');
              }}
              sx={
                aciveTab.location === 'upcoming-trips'
                  ? {
                      ...activeListItemSx
                    }
                  : undefined
              }
            >
              <ListItemIcon
                sx={
                  aciveTab.location === 'upcoming-trips'
                    ? {
                        ...activeIconSx
                      }
                    : undefined
                }
              >
                <HubOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'Upcoming Trips'} />
            </ListItem>
          )}
          {userRole === 5 && (
            <ListItem
              button
              onClick={() => {
                navigate('/branches');
              }}
              sx={
                aciveTab.location === 'branches'
                  ? {
                      ...activeListItemSx
                    }
                  : undefined
              }
            >
              <ListItemIcon
                sx={
                  aciveTab.location === 'branches'
                    ? {
                        ...activeIconSx
                      }
                    : undefined
                }
              >
                <HubOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'Branches'} />
            </ListItem>
          )}
          {userRole === 5 && (
            <ListItem
              button
              onClick={() => {
                navigate('/add-branch');
              }}
              sx={
                aciveTab.location === 'add-branch'
                  ? {
                      ...activeListItemSx
                    }
                  : undefined
              }
            >
              <ListItemIcon
                sx={
                  aciveTab.location === 'add-branch'
                    ? {
                        ...activeIconSx
                      }
                    : undefined
                }
              >
                <HubOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'Add Branch'} />
            </ListItem>
          )}
          <ListItem button onClick={() => {}}>
            <ListItemIcon>
              <ArticleOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'Reports'} />
          </ListItem>
          {/* <ListItem button onClick={logoutUser}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={'Logout'} />
          </ListItem> */}
        </List>
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1, bgcolor: '#fbfbfb', p: 3 }}>
        <HeaderDashboard title={aciveTab.title} />
        {props.children}
      </Box>
    </Box>
  );
}
