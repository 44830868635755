import { SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomSelect from '../../components/elements/select/Select';
import { getCarMake } from '../carRental/cars/carsAPI';
import { DropdownSelector, selectDropdown } from './dropdownSlice';

export default function CarMakeDropdown () {
  const [carMakeOptions, setCarMakeOptions] = useState<any[]>([]);
  const dispatch = useDispatch();
  const { selectedCategoryId, selectedMakeId } = useSelector(DropdownSelector);

  useEffect(() => {
    getCarMakeOptions();
  }, []);

  const getCarMakeOptions = async () => {
    const options = await getCarMake();
    if (options && options.data) {
      setCarMakeOptions(options.data);
    }
  };

  const handleMakeChange = (event: SelectChangeEvent) => {
    const selectedMakeId = event?.target.value;
    dispatch(selectDropdown({ selectedCategoryId, selectedMakeId, selectedModelId: '' }));
  };
  return <CustomSelect
        value={selectedMakeId || ''}
        label="Car Make"
        onChange={handleMakeChange}
        list={carMakeOptions || []} />
}
