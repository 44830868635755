import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownSelector, selectDropdown } from './dropdownSlice';

export default function TripDurationDropdown () {
  const dispatch = useDispatch();

  const tripDurationOptions = [{
    value: 'hour',
    label: 'Hour'
  }, {
    value: 'day',
    label: 'Day'
  }, {
    value: 'month',
    label: 'Month'
  }];

  const { selectedTripDuration } = useSelector(DropdownSelector);

  const handleTripDurationChange = (event: SelectChangeEvent) => {
    const selectedTripDuration = event?.target.value;
    dispatch(selectDropdown({ selectedTripDuration }));
  };

  return <Select
    value={selectedTripDuration || ''}
    label="Trip Duration"
    onChange={handleTripDurationChange}
    >
        <MenuItem value="">
            <em>Any</em>
        </MenuItem>
        {
           tripDurationOptions && tripDurationOptions.length > 0 && tripDurationOptions.map((item, index) => {
             return <MenuItem value={item.value} key={index}>{item.label}</MenuItem>
           })
        }
    </Select>
}
