import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import {
  CardActionArea,
  Chip,
  ImageList,
  ImageListItem,
  Rating,
} from "@mui/material";
import { CarData } from "./carsSlice";
import { Link } from "react-router-dom";

export interface CarBoxProps {
  carData: CarData;
}

export default function CarBox({ carData }: CarBoxProps) {
  return (
    <Card>
      {/* <CardMedia
        component="img"
        image={carData.image_path}
        alt={carData.car_make}
      /> */}
      <Link
        style={{
          textDecoration: "none",
        }}
        to={`/car-rental/book/${carData.car_id}`}
      >
        <ImageList
          sx={{ width: 300, height: 250, objectFit: "cover" }}
          cols={1}
        >
          <Box sx={{ objectFit: "fill", width: 250, height: 200 }}>
            <ImageListItem>
              {carData.image_path != "" ? (
                <img
                  src={`${carData.image_path}`}
                  alt={carData.car_make}
                  loading="lazy"
                />
              ) : (
                <img
                  src={`https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg`}
                  alt={carData.car_make}
                  loading="lazy"
                />
              )}
            </ImageListItem>
          </Box>
        </ImageList>
        <CardContent>
          <Typography gutterBottom variant="h6" component="h6">
            {carData.car_make + " " + carData.car_model}
          </Typography>
          <Box borderColor="transparent" mb={2}>
            <Rating
              name="read-only"
              value={carData.rating ? Number(carData.rating) : 5}
              readOnly
            />
          </Box>
          <Box display={"flex"} justifyContent="space-between">
            <Typography variant="body2" color="text.secondary">
              {carData?.trips || 0} Trips
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {carData.trip_type}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            sx={{
              mt: 2,
            }}
            justifyContent="space-between"
          >
            <Typography variant="body2">
              SAR {carData.rental_rate}/{carData.duration_parameter}
            </Typography>
            {/* <Chip
              label="save 30%"
              icon={<LocalOfferOutlinedIcon fontSize="small" />}
            /> */}
          </Box>
        </CardContent>
      </Link>
    </Card>
  );
}
