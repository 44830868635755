import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { addCarInfoApi } from '../addCarAPI';
import { AddCarInfoRequest } from '../interface/addCarInterface';

export interface AddCarInfoState {
  isCarInfoSuccess: boolean;
  isCarInfoError: boolean;
  isCarInfoFetching: boolean;
  errorCarInfoMessage: string;
  car_id: number;
  carInfoData: AddCarInfoRequest;
}

export const addCarInfoAsync = createAsyncThunk(
  'car/addCarInfo',
  async (req: AddCarInfoRequest, thunkAPI) => {
    try {
      const response = await addCarInfoApi(req);
      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log('Error', e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState: AddCarInfoState = {
  isCarInfoSuccess: false,
  isCarInfoError: false,
  isCarInfoFetching: false,
  errorCarInfoMessage: '',
  car_id: 0,
  carInfoData: {
    user_id: 0,
    location: '',
    purchased_date: '',
    category: '',
    make: '',
    model: '',
    odometer: 0,
    transmission: '',
    latitude: 0,
    longitude: 0
  }
};

export const AddCarInfoSlice = createSlice({
  name: 'addCarInfo',
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.isCarInfoSuccess = false;
      state.isCarInfoError = false;
      state.isCarInfoFetching = false;
      state.errorCarInfoMessage = '';
      state.carInfoData.user_id = 0;
      state.carInfoData.location = '';
      state.carInfoData.purchased_date = '';
      state.carInfoData.category = '';
      state.carInfoData.make = '';
      state.carInfoData.model = '';
      state.carInfoData.odometer = 0;
      state.carInfoData.transmission = '';
      state.carInfoData.latitude = 0;
      state.carInfoData.longitude = 0;
      return state;
    },
    setCarInfoFormState: (state, action: PayloadAction<AddCarInfoRequest>) => {
      state.carInfoData.user_id = action.payload.user_id;
      state.carInfoData.location = action.payload.location;
      state.carInfoData.purchased_date = action.payload.purchased_date;
      state.carInfoData.category = action.payload.category;
      state.carInfoData.make = action.payload.make;
      state.carInfoData.model = action.payload.model;
      state.carInfoData.odometer = action.payload.odometer;
      state.carInfoData.transmission = action.payload.transmission;
      state.carInfoData.latitude = action.payload.latitude;
      state.carInfoData.longitude = action.payload.longitude;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCarInfoAsync.fulfilled, (state, action: any) => {
        state.isCarInfoFetching = false;
        state.isCarInfoSuccess = true;
        state.car_id = action.payload.data.carId;
        return state;
      })
      .addCase(addCarInfoAsync.rejected, (state, action: any) => {
        state.isCarInfoFetching = false;
        state.isCarInfoError = true;
        state.errorCarInfoMessage = action.payload.userMessage;
      })
      .addCase(addCarInfoAsync.pending, (state) => {
        state.isCarInfoFetching = true;
      });
  }
});

export default AddCarInfoSlice.reducer;

export const { clearState, setCarInfoFormState } = AddCarInfoSlice.actions;

export const addCarInfoSelector = (state: RootState) => state.addCarInfo;
