import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { addTripInfoApi } from '../addCarAPI';
import { AddTripInfoRequest } from '../interface/addCarInterface';

export interface AddTripInfoState {
  isTripInfoSuccess: boolean;
  isTripInfoError: boolean;
  isTripInfoFetching: boolean;
  errorTripInfoMessage: string;
  tripInfoData: AddTripInfoRequest;
}

export const addTripInfoAsync = createAsyncThunk(
  'car/addTripInfo',
  async (req: AddTripInfoRequest, thunkAPI) => {
    try {
      const response = await addTripInfoApi(req);
      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log('Error', e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState: AddTripInfoState = {
  isTripInfoSuccess: false,
  isTripInfoError: false,
  isTripInfoFetching: false,
  errorTripInfoMessage: '',
  tripInfoData: {
    car_id: 0,
    trip_type: '',
    max_duration: 0,
    min_duration: 1,
    rental_rate: 0,
    currency: 1
  }
};

export const AddTripInfoSlice = createSlice({
  name: 'addCarInfo',
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.isTripInfoSuccess = false;
      state.isTripInfoError = false;
      state.isTripInfoFetching = false;
      state.errorTripInfoMessage = '';
      state.tripInfoData.car_id = 0;
      state.tripInfoData.trip_type = '';
      state.tripInfoData.max_duration = 0;
      state.tripInfoData.min_duration = 1;
      state.tripInfoData.rental_rate = 0;
      return state;
    },
    setTripInfoFormState: (
      state,
      action: PayloadAction<AddTripInfoRequest>
    ) => {
      state.tripInfoData.car_id = action.payload.car_id;
      state.tripInfoData.trip_type = action.payload.trip_type;
      state.tripInfoData.max_duration = action.payload.max_duration;
      state.tripInfoData.min_duration = action.payload.min_duration;
      state.tripInfoData.rental_rate = action.payload.rental_rate;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTripInfoAsync.fulfilled, (state, action: any) => {
        state.isTripInfoFetching = false;
        state.isTripInfoSuccess = true;
        return state;
      })
      .addCase(addTripInfoAsync.rejected, (state, action: any) => {
        state.isTripInfoFetching = false;
        state.isTripInfoError = true;
        state.errorTripInfoMessage = action.payload.userMessage;
      })
      .addCase(addTripInfoAsync.pending, (state) => {
        state.isTripInfoFetching = true;
      });
  }
});

export default AddTripInfoSlice.reducer;

export const { clearState, setTripInfoFormState } = AddTripInfoSlice.actions;

export const addTripInfoSelector = (state: RootState) => state.addTripInfo;
