import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface SelectedDropdownValues {
  selectedCategoryId?: string;
  selectedMakeId?: string;
  selectedModelId?: string;
  selectedTripDuration?: string;
}

const initialState: SelectedDropdownValues = {
  selectedCategoryId: '',
  selectedMakeId: '',
  selectedModelId: '',
  selectedTripDuration: ''
};

export const selectedDropdown = createSlice({
  name: 'SelectDropdown',
  initialState: initialState,
  reducers: {
    clearDropdownState: (state) => {
      state.selectedCategoryId = '';
      state.selectedMakeId = '';
      state.selectedModelId = '';
      state.selectedTripDuration = '';
      return state;
    },
    selectDropdown: (state, action: PayloadAction<SelectedDropdownValues>) => {
      state.selectedCategoryId = action.payload.selectedCategoryId;
      state.selectedMakeId = action.payload.selectedMakeId;
      state.selectedModelId = action.payload.selectedModelId;
      state.selectedTripDuration = action.payload.selectedTripDuration;
      return state;
    }
  }
});

export default selectedDropdown.reducer;

export const { clearDropdownState, selectDropdown } = selectedDropdown.actions;

export const DropdownSelector = (state: RootState) => state.selectDropdown;
