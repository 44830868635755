import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "../features/carRental/home/Home";
import { Cars } from "../features/carRental/cars/cars";
import Header from "../components/modules/Header/Header";
import Footer from "../components/modules/Footer/Footer";
import { Profile } from "../features/user-profile/profile";
import CarBooking from "../features/carRental/car-booking/car-booking";
import { CarList } from "../features/carRental/add-car/carlist";
import NotFoundPage from "../components/modules/commanPages/NotFoundPage";
import MyCars from "../features/carRental/my-car/myCars";
import Trips from "../features/corporate/my-cars/trips";
import Message2 from "../features/carRental/message/message2";
import Notification from "../features/carRental/Notification/notification";

export default function SharikUserRoutes({ isAuth }: { isAuth: boolean }) {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="notification" element={<Notification />} />
        <Route path="car-rental">
          <Route path="list" element={<Cars />} />
          <Route path="book/:id" element={<CarBooking />} />
          <Route path="add-car" element={<CarList />} />
          <Route path="my-cars" element={<MyCars />} />
          <Route path="trips" element={<Trips />} />
          <Route path="trips/:id" element={<Trips />} />
          <Route path="chat" element={<Message2 />} />
          <Route index element={<Home />} />
        </Route>
        {isAuth && (
          <Route path="user-profile">
            <Route path="bank-details" element={<Profile />} />
            <Route index element={<Profile />} />
          </Route>
        )}
        <Route index element={<Home />} />
        <Route path="/:path" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
