import axios from 'axios';
import config from './../../util/config.js';

export interface loginReq {
  phonecode: string;
  phonenumber: string;
};

export interface VerifyOTPRequest {
  phonecode: string;
  phonenumber: string;
  otp: string;
};

export async function loginGetOtp (req: loginReq) {
  const data = {
    phonecode: req.phonecode,
    phonenumber: req.phonenumber
  };
  try {
    const response = await axios.post(config.BASE_URL + 'login', data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.data.response;
  } catch (error: any) {
    throw new Error(error);
  }
};

export async function verifyOtp (req: VerifyOTPRequest) {
  const data = {
    phonecode: req.phonecode,
    phonenumber: req.phonenumber,
    otp: req.otp
  };
  try {
    const response = await axios.post(config.BASE_URL + 'verify_otp', data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.data.response;
  } catch (error: any) {
    throw new Error(error);
  }
}
