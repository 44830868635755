import { Card } from '@mui/material';
import CarListStepper from '../carListForms/carlistStepper';

export function CarList() {
  return (
    <Card>
      <CarListStepper />
    </Card>
  );
}
