import * as React from 'react';
import Box from '@mui/material/Box';
import { usePlacesWidget } from 'react-google-autocomplete';
import { ResponseInterface } from '../../../../util/types';
import {
  Card,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';
import { LocationOnOutlined } from '@mui/icons-material';
import { SideBar } from '../../sidebar/sidebar';
import { buttonRed } from '../../../../mui-sx/button';
import axios from 'axios';
import config from '../../../../util/config.js';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { GoogleMap } from '../../../../components/elements/map/googleMap';
import { LatLng, latLngConst } from '../../../../redux/locationSlice';

export default function AddBranch() {
  const navigate = useNavigate();
  const [firstName, setFirstName] = React.useState<string>('');
  const [lastName, setLastName] = React.useState<string>('');
  const [title, settitle] = React.useState<string>('');
  const [location, setlocation] = React.useState<string>('');
  const [latLng, setLatLng] = React.useState<LatLng>({
    latitude: latLngConst.latitude,
    longitude: latLngConst.longitude
  });
  const [email, setemail] = React.useState<string>('');
  const [isFetching, setisFetching] = React.useState<boolean>(false);

  const { ref: materialRef } = usePlacesWidget({
    apiKey: 'AIzaSyB8svhnyEyYYRYu9phI3afRxNjodA0eiYE',
    options: {
      types: 'geocode'
    },
    onPlaceSelected: (place) => {
      const latLng = {
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng()
      };
      const formattedAddress = place.formatted_address;
      setLatLng(latLng);
      setlocation(place.formatted_address);
    }
  });

  const addBranch = async () => {
    if (latLng.latitude && latLng.longitude) {
      const data = {
        firstName,
        lastName,
        title,
        location,
        location_lat: latLng.latitude,
        location_lng: latLng.longitude,
        email
      };

      try {
        setisFetching(true);
        axios
          .post(config.BASE_URL + 'branch/add', data, {
            headers: {
              'x-access-token': localStorage.getItem('token') || '',
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          })
          .then((res: ResponseInterface<any>) => {
            if (res.data.response.status === 200) {
              navigate('/branches');
            }
            setisFetching(false);
          })
          .catch(() => {
            setisFetching(false);
          });
      } catch (error: any) {
        setisFetching(false);
        throw new Error(error);
      }
    }
  };

  const btnEnable = (): boolean => {
    if (
      firstName &&
      lastName &&
      title &&
      location &&
      latLng.latitude &&
      latLng.longitude &&
      email
    ) {
      return true;
    }

    return false;
  };

  return (
    <SideBar>
      <Card
        sx={{
          height: '650px',
          p: 2
        }}
      >
        <Box
          display='flex'
          justifyContent={'space-between'}
          alignItems='center'
        >
          <Typography> *All fields are mandatory </Typography>
          <LoadingButton
            variant='contained'
            disabled={!btnEnable}
            loading={isFetching}
            loadingIndicator='Adding Branch...'
            sx={{
              ...buttonRed,
              m: 2
            }}
            onClick={addBranch}
          >
            Add
          </LoadingButton>
        </Box>
        <Box
          component='form'
          sx={{
            '& > :not(style)': { m: 3, width: '30%' },
            width: '100%'
          }}
          noValidate
          autoComplete='off'
        >
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor='branch'>Branch Manager First Name</InputLabel>
            <OutlinedInput
              label='Branch Name'
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              value={firstName}
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor='branch'>Branch Manager Last Name</InputLabel>
            <OutlinedInput
              label='Branch Name'
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              value={lastName}
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor='branch'>Branch Manager Email</InputLabel>
            <OutlinedInput
              label='Branch Name'
              onChange={(e) => {
                setemail(e.target.value);
              }}
              value={email}
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor='branch'>Branch Name</InputLabel>
            <OutlinedInput
              label='Branch Name'
              onChange={(e) => {
                settitle(e.target.value);
              }}
              value={title}
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor='branch'>Branch Location</InputLabel>
            <OutlinedInput
              label='Branch Location'
              inputRef={materialRef}
              autoComplete={'off'}
              value={location}
              onChange={(e) => {
                setlocation(e.target.value);
              }}
              inputProps={{
                autoComplete: 'off',
                form: {
                  autoComplete: 'off'
                }
              }}
              endAdornment={
                <InputAdornment position='end'>
                  <LocationOnOutlined />
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        <Box
          component='form'
          sx={{
            width: '100%',
            height: '440px'
          }}
          noValidate
          autoComplete='off'
        >
          <GoogleMap
            setLatLng={setLatLng}
            setlocation={setlocation}
            pointers={{
              lat: latLng.latitude,
              lng: latLng.longitude
            }}
          />
        </Box>
      </Card>
    </SideBar>
  );
}
