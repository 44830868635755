import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddVehicleSummary from './addVehicleSummary';
import { Alert, AlertColor, Grid, Snackbar } from '@mui/material';
import AddTripDetails from './addTripDetails';
import AddInsuranceDetails from './addInsuranceDetails';
import AddPhotos from './addPhotos';
import AddFeatures from './addFeatures';
import { fullButtonRed } from '../../../mui-sx/button';
import { useDispatch, useSelector } from 'react-redux';
import { addCarInfoAsync, addCarInfoSelector } from './slice/addCarInfoSlice';
import {
  addTripInfoAsync,
  addTripInfoSelector
} from './slice/addTripInfoSlice';
import {
  addInsuranceInfoAsync,
  addInsuranceInfoSelector
} from './slice/addInsuranceInfoSlice';
import { addFeaturesAsync, addFeatureSelector } from './slice/addFeaturesSlice';
import {
  addCarPhotosAsync,
  addCarPhotoSelector
} from './slice/addCarPhotosSlice';
import { userIdSelectorSelector } from '../../corporate/login/corporateAuthSlice';

import { clearState as clearStateCarInfo } from './slice/addCarInfoSlice';
import { clearState as clearStatePhoto } from './slice/addCarPhotosSlice';
import { clearState as clearStateFeatures } from './slice/addFeaturesSlice';
import { clearState as clearStateInsurance } from './slice/addInsuranceInfoSlice';
import { clearState as clearStateTrip } from './slice/addTripInfoSlice';
import { clearDropdownState } from '../../dropdowns/dropdownSlice';

/** Name list of forms to add car */
const steps = [
  'Vehicle Summary',
  'Trip Details',
  'Insurance Details',
  'Photos and Features'
];

export default function CarListStepper() {
  const dispatch = useDispatch();
  const userIdSelect = useSelector(userIdSelectorSelector);
  React.useEffect(() => {
    if (userIdSelect) {
      setuserId(userIdSelect);
    }
  }, [userIdSelect]);
  const [userId, setuserId] = React.useState<number>(0);
  const [open, setOpen] = React.useState(false);
  const [alertType, setAlertType] = React.useState<AlertColor>('error');
  const [alertMessage, setAlertMessage] = React.useState<string>('');
  const { carInfoData, isCarInfoSuccess, errorCarInfoMessage } =
    useSelector(addCarInfoSelector);
  const { tripInfoData, isTripInfoSuccess, errorTripInfoMessage } =
    useSelector(addTripInfoSelector);
  const { insuranceInfoData, isInsuranceSuccess, errorInsuranceMessage } =
    useSelector(addInsuranceInfoSelector);
  const { featuresData, isFeaturesSuccess, errorFeaturesMessage } =
    useSelector(addFeatureSelector);
  const { photosData } = useSelector(addCarPhotoSelector);
  const [btnEnable, setBtnEnable] = React.useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  /** Get Response of form submitted */
  React.useEffect(() => {
    if (
      isCarInfoSuccess ||
      isTripInfoSuccess ||
      isInsuranceSuccess ||
      isFeaturesSuccess
    ) {
      setAlertType('success');
      setAlertMessage('Car Information Added Succesfully !');
      handleNextActiveStep();
      setOpen(true);
      if (isFeaturesSuccess) {
        setActiveStep(0);
        dispatch(clearStateCarInfo());
        dispatch(clearStatePhoto());
        dispatch(clearStateFeatures());
        dispatch(clearStateInsurance());
        dispatch(clearDropdownState());
        dispatch(clearStateTrip());
        setCompleted({});
        // navigate('/car-rental/my-cars');
      }
    }

    /** Set error message for Snackbar */
    if (
      errorCarInfoMessage ||
      errorInsuranceMessage ||
      errorTripInfoMessage ||
      errorFeaturesMessage
    ) {
      const message =
        errorCarInfoMessage ||
        errorInsuranceMessage ||
        errorTripInfoMessage ||
        errorFeaturesMessage ||
        '';
      setAlertMessage(message);
      setAlertType('error');
      setOpen(true);
    }
  }, [
    isCarInfoSuccess,
    errorCarInfoMessage,
    errorInsuranceMessage,
    isTripInfoSuccess,
    errorTripInfoMessage,
    errorFeaturesMessage,
    isInsuranceSuccess,
    isFeaturesSuccess
  ]);

  // /** Vehicle Summary form validation enable/disable Next Btn */
  // React.useEffect(() => {
  //   if (carInfoData.user_id && carInfoData.category && carInfoData.latitude && carInfoData.location && carInfoData.longitude && carInfoData.make && carInfoData.model && carInfoData.odometer && carInfoData.purchased_date && carInfoData.transmission && activeStep === 0) {
  //     setBtnEnable(true);
  //   } else {
  //     setBtnEnable(false);
  //   };
  // }, [carInfoData, activeStep]);

  // /** Trip Details form validation enable/disable Next Btn */
  // React.useEffect(() => {
  //   if (tripInfoData.car_id && tripInfoData.max_duration && tripInfoData.rental_rate && tripInfoData.trip_type && activeStep === 1) {
  //     setBtnEnable(true);
  //   } else {
  //     setBtnEnable(false);
  //   };
  // }, [tripInfoData, activeStep]);

  // /** Insurance Details form validation enable/disable Next Btn */
  // React.useEffect(() => {
  //   if (insuranceInfoData.car_id && insuranceInfoData.min_charges && insuranceInfoData.policy_number && insuranceInfoData.provider && activeStep === 2) {
  //     setBtnEnable(true);
  //   } else {
  //     setBtnEnable(false);
  //   };
  // }, [insuranceInfoData, activeStep]);

  /** Features Details form validation enable/disable Next Btn */
  // React.useEffect(() => {
  //   if (featuresData.car_id && featuresData.features.length > 0 && activeStep === 3) {
  //     setBtnEnable(true);
  //   } else {
  //     setBtnEnable(false);
  //   };
  // }, [featuresData, activeStep]);

  React.useEffect(() => {
    validateForms();
  }, [activeStep, carInfoData, tripInfoData, insuranceInfoData, featuresData]);

  const validateForms = () => {
    if (activeStep === 0) {
      /** Vehicle Summary form validation enable/disable Next Btn */
      if (
        carInfoData.category &&
        carInfoData.latitude &&
        carInfoData.location &&
        carInfoData.longitude &&
        carInfoData.make &&
        carInfoData.model &&
        carInfoData.odometer &&
        carInfoData.purchased_date &&
        carInfoData.transmission
      ) {
        setBtnEnable(true);
      } else {
        setBtnEnable(false);
      }
    } else if (activeStep === 1) {
      /** Trip Details form validation enable/disable Next Btn */
      if (
        tripInfoData.car_id &&
        tripInfoData.max_duration &&
        tripInfoData.rental_rate &&
        tripInfoData.trip_type
      ) {
        setBtnEnable(true);
      } else {
        setBtnEnable(false);
      }
    } else if (activeStep === 2) {
      /** Insurance Details form validation enable/disable Next Btn */
      if (
        insuranceInfoData.car_id &&
        insuranceInfoData.min_charges &&
        insuranceInfoData.policy_number &&
        insuranceInfoData.provider
      ) {
        setBtnEnable(true);
      } else if (
        insuranceInfoData.min_charges ||
        insuranceInfoData.policy_number ||
        insuranceInfoData.provider
      ) {
        setBtnEnable(false);
      } else {
        setBtnEnable(true);
      }
    } else if (activeStep === 3) {
      /** Features Details form validation enable/disable Next Btn */
      if (featuresData.car_id && featuresData.features.length > 0) {
        setBtnEnable(true);
      } else {
        setBtnEnable(false);
      }
    } else {
      setBtnEnable(false);
    }
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    if (activeStep === 2) {
      if (
        insuranceInfoData.car_id &&
        insuranceInfoData.min_charges &&
        insuranceInfoData.policy_number &&
        insuranceInfoData.provider
      ) {
        callAPIhandler();
      } else {
        handleNextActiveStep();
      }
    } else {
      callAPIhandler();
    }
  };

  const handleNextActiveStep = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  /** Call APIs */
  const callAPIhandler = () => {
    if (userId) {
      switch (activeStep) {
        case 0:
          dispatch(addCarInfoAsync({ ...carInfoData, user_id: userId }));
          break;
        case 1:
          dispatch(addTripInfoAsync({ ...tripInfoData }));
          break;
        case 2:
          dispatch(addInsuranceInfoAsync({ ...insuranceInfoData }));
          break;
        case 3:
          dispatch(addCarPhotosAsync({ ...photosData }));
          dispatch(addFeaturesAsync({ ...featuresData }));
          break;

        default:
          break;
      }
    }
  };
  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  // const handleStep = (step: number) => () => {
  //   setActiveStep(step);
  // };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  /** Get Form component */
  const getCarForm = (index: number) => {
    switch (index) {
      case 0:
        return <AddVehicleSummary />;
      case 1:
        return <AddTripDetails />;
      case 2:
        return <AddInsuranceDetails />;
      case 3:
        return (
          <>
            <AddPhotos /> <AddFeatures />
          </>
        );

      default:
        return <AddVehicleSummary />;
    }
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Grid
          container
          sx={{
            padding: '20px 10px 10px 10px',
            borderBottom: '1px solid lightgrey'
          }}
        >
          <Grid item xs={12}>
            <Typography
              variant='h6'
              sx={{
                textAlign: 'center',
                fontWeight: 'bolder'
              }}
            >
              Add New Car
            </Typography>
          </Grid>
        </Grid>
        <Stepper
          nonLinear
          activeStep={activeStep}
          sx={{
            padding: '30px 10px 10px 10px',
            // paddingTop: 30px;
            borderBottom: '1px solid lightgrey'
          }}
        >
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color='inherit' /* onClick={handleStep(index)} */>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <Grid container>
              {getCarForm(activeStep)}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  pt: 2,
                  width: '94%'
                }}
              >
                {/* <Button
                                variant="outlined"
                                sx={{
                                  // ...button,
                                  m: 2,
                                  color: '#E85656'
                                }}
                                disabled={activeStep === 0}
                                // onClick={handleBack}
                                >
                                Previous
                            </Button> */}
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                  variant='contained'
                  disabled={!btnEnable}
                  sx={{
                    ...fullButtonRed,
                    m: 2,
                    width: '9rem'
                  }}
                  onClick={handleNext}
                >
                  Next
                </Button>
              </Box>
            </Grid>
          )}
        </div>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity={alertType} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
