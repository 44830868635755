import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { daysInThisMonth, getCurrentMonth } from '../../../util/util';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
      display: false
    },
    title: {
      display: false,
      text: ''
    }
  }
};

export function BarChart () {
  const [data, setData] = useState({
    labels: [''],
    datasets: [
      {
        label: 'Dataset 1',
        data: [12, 19, 3, 5, 2, 3, 5, 12, 19, 3, 12, 19, 3, 5, 2, 3, 5, 12, 19, 3],
        backgroundColor: 'rgb(87,125,232)',
        borderRadius: 20
      }
    ]
  })
  useEffect(() => {
    const currentMonth = getCurrentMonth();
    const daysIn = daysInThisMonth();
    const labels = []
    for (let i = 1; i <= daysIn; i++) {
      labels.push(`${i} ${currentMonth}`)
    }

    setData({
      labels: labels,
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3, 5, 12, 19, 3, 12, 19, 3, 5, 2, 3, 5, 12, 19, 3],
          backgroundColor: 'rgb(87,125,232)',
          borderRadius: 20
        }
      ]
    })
  }, [])

  return <Bar options={options} data={data} />;
}
