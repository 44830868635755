import * as React from 'react';
import TextField from '@mui/material/TextField';

export class CustomComponent extends React.Component {
    render() {
        return (
            <TextField
                sx={{
                    width: "100%"
                }}
                label='Date of Birth in Hijri'
                onFocus={() => {
                    this.props.openCalendar();
                }}
                value={this.props.value}
            />
        );
    }
}