import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import { GetData } from '../../Api/ApiMgr';
import { ApiData } from '../../Api/url';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userIdSelectorSelector } from '../login/authSlice';

export function Earning() {
  const userID = useSelector(userIdSelectorSelector);
  const [earningDatas, setEData] = useState([]);

  const [loading, setLoading] = useState(true);

  const update = () => {
    const data = {
      user_id: userID
    };

    GetData(data, ApiData.getTrans)
      .then((e) => {

        if (e.status) {
          var data = e.data;

          setEData(data['transactions']);
          setLoading(false);

        } else {
          alert('Error to get Data: ' + e.msg);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  useEffect(() => {
    update();
  }, []);

  function EarningrCard(amount: any, type: any, dateTime: any, balance: any) {
    var color = type != 'D' ? 'red' : 'green';

    var addType = type == 'D' ? 'Deposit' : 'Withdrawal';

    return (
      <Box sx={{ width: '100%' }} mt={3} mb={3}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexDirection={'row'}
        >
          <Box>
            <Box
              display={'flex'}
              justifyContent={'start'}
              alignItems={'start'}
              flexDirection={'column'}
            >
              <Typography variant='body2' color={'black'}>
                {type == 'D' ? <label>+</label> : <label>-</label>} {amount}
              </Typography>
              <Typography variant='body2' color={color}>
                {addType}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box
              display={'flex'}
              justifyContent={'end'}
              alignItems={'end'}
              flexDirection={'column'}
            >
              <Typography variant='body2' color='black'>
                {balance}
              </Typography>

              <Typography variant='body2' color='text.secondary'>
                {dateTime}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Divider flexItem />
      </Box>
    );
  }

  return (
      <Box
        mt={3}
        ml={3}
        sx={{ width: '90%' }}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        <Box
          m={2}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          sx={{
            background: '#FAFAFA 0% 0% no-repeat padding-box',
            width: '100%',
            height: '100px'
          }}
        >
          <Typography variant='h5' color='black'>
            SAR 1750.00
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            Total Wallet Balance
          </Typography>
        </Box>
        <Box sx={{ width: '100%' }} mt={3}>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            flexDirection={'row'}
          >
            <Box>
              <Typography variant='body2' color='black'>
                Amount
              </Typography>
            </Box>
            <Box>
              <Typography variant='body2' color='black'>
                Wallet Balance
              </Typography>
            </Box>
          </Box>

          <Divider flexItem />
        </Box>

        {!loading ? (
          <Box mt={2} sx={{ width: '100%' }}>
            {earningDatas.map((e) =>
              EarningrCard(
                e['amount'],
                e['flow_type'] == 'in' ? 'C' : 'D',
                'null',
                e['wallet_balance']
              )
            )}
          </Box>
        ) : (
          <div>Loading..</div>
        )}
      </Box>
  );
}
