import {
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import AppleIcon from "@mui/icons-material/Apple";
import TextField from "@mui/material/TextField";
import { CustomModal } from "../../components/elements/modal/modal";
import PhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  clearState,
  loginAsync,
  userSelector,
  verifyLoginAsync,
} from "./authSlice";
import { useDispatch, useSelector } from "react-redux";
import { fullButtonRed } from "../../mui-sx/button";

type Props = {
  children: JSX.Element;
};

export function Login({ children }: Props) {
  const dispatch = useDispatch();
  const { isSuccess, isError, isVerified, errorMessage } =
    useSelector(userSelector);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [isOtp, setIsOtp] = React.useState<boolean>(false);
  const [phonenumber, setPhonenumber] = React.useState<string>("");
  const [number, setNumber] = React.useState<string>("");
  const [phonecode, setPhonecode] = React.useState<string>("");
  const [otp1, setOtp1] = React.useState<string>("");
  const [otp2, setOtp2] = React.useState<string>("");
  const [otp3, setOtp3] = React.useState<string>("");
  const [otp4, setOtp4] = React.useState<string>("");
  const NumberArray1To9 = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
    }
    if (isSuccess) {
      dispatch(clearState());
      setIsOtp(true);
    }

    if (isVerified) {
      dispatch(clearState());
      setIsOtp(false);
      setModalOpen(false);
    }
  }, [dispatch, errorMessage, isError, isSuccess, isVerified]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const getOTP = () => {
    dispatch(loginAsync({ phonenumber, phonecode }));
  };

  const verifyOTP = () => {
    if (otp1 === "" || otp2 === "" || otp3 === "" || otp4 === "") {
      return;
    }
    const otp = otp1 + otp2 + otp3 + otp4;
    dispatch(verifyLoginAsync({ phonenumber, phonecode, otp }));
  };

  const mobileChange = (phonenumber: string, country: CountryData) => {
    if (phonenumber && country && country.dialCode) {
      const mobile = phonenumber.slice(country.dialCode.length);
      setPhonenumber(mobile);
      setNumber(phonenumber);
      setPhonecode(country.dialCode);
    }
  };

  return (
    <>
      <span onClick={toggleModal}>{children}</span>
      <CustomModal
        open={modalOpen}
        toggleModal={toggleModal}
        sx={{
          width: 614,
        }}
      >
        <Box
          sx={{
            pt: 2,
            pb: 2,
          }}
          textAlign={"center"}
          borderBottom="1px solid #EAEAEA;"
        >
          <Typography variant="h6" component="h2">
            Sign in or create your account
          </Typography>
          {/* <CloseRoundedIcon /> */}
        </Box>
        {isOtp ? (
          <Box
            sx={{
              p: 2,
            }}
          >
            <Typography
              variant="h5"
              component="h2"
              sx={{
                font: "normal normal 22px/28px Work Sans",
              }}
            >
              Enter verification code
            </Typography>
            <Typography
              sx={{
                mt: 3,
                mb: 3,
              }}
              variant="body2"
              color="text.secondary"
            >
              Enter the 4-digit code sent to you at {phonenumber}
            </Typography>
            <Grid
              container
              sx={{
                mb: 3,
              }}
            >
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    id="otp-input-1"
                    label=""
                    type="text"
                    value={otp1}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        verifyOTP();
                      }
                    }}
                    onChange={(e) => {
                      e.preventDefault();
                      if (
                        NumberArray1To9.includes(e.target.value) ||
                        e.target.value === ""
                      ) {
                        setOtp1(e.target.value);
                        if (NumberArray1To9.includes(e.target.value)) {
                          document.getElementById("otp-input-2")?.focus();
                        }
                      }
                    }}
                    inputProps={{
                      maxLength: 1,
                      className: "otp-input",
                    }}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="otp-input-2"
                    label=""
                    type="text"
                    value={otp2}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        verifyOTP();
                      }
                    }}
                    onChange={(e) => {
                      e.preventDefault();
                      if (
                        NumberArray1To9.includes(e.target.value) ||
                        e.target.value === ""
                      ) {
                        setOtp2(e.target.value);
                        if (NumberArray1To9.includes(e.target.value)) {
                          document.getElementById("otp-input-3")?.focus();
                        } else if (e.target.value === "") {
                          document.getElementById("otp-input-1")?.focus();
                        }
                      }
                    }}
                    inputProps={{
                      maxLength: 1,
                      className: "otp-input",
                    }}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="otp-input-3"
                    label=""
                    type="text"
                    value={otp3}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        verifyOTP();
                      }
                    }}
                    onChange={(e) => {
                      e.preventDefault();
                      if (
                        NumberArray1To9.includes(e.target.value) ||
                        e.target.value === ""
                      ) {
                        setOtp3(e.target.value);
                        if (NumberArray1To9.includes(e.target.value)) {
                          document.getElementById("otp-input-4")?.focus();
                        } else if (e.target.value === "") {
                          document.getElementById("otp-input-2")?.focus();
                        }
                      }
                    }}
                    inputProps={{
                      maxLength: 1,
                      className: "otp-input",
                    }}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="otp-input-4"
                    label=""
                    type="text"
                    value={otp4}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        verifyOTP();
                      }
                    }}
                    onChange={(e) => {
                      e.preventDefault();
                      if (
                        NumberArray1To9.includes(e.target.value) ||
                        e.target.value === ""
                      ) {
                        setOtp4(e.target.value);
                        if (NumberArray1To9.includes(e.target.value)) {
                          document.getElementById("otp-input-4")?.focus();
                        } else if (e.target.value === "") {
                          document.getElementById("otp-input-3")?.focus();
                        }
                      }
                    }}
                    inputProps={{
                      maxLength: 1,
                      className: "otp-input",
                    }}
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              sx={{
                ...fullButtonRed,
              }}
              onClick={() => {
                verifyOTP();
              }}
            >
              Next
            </Button>
            <ButtonGroup
              sx={{
                display: "flex",
                mt: 2,
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="text"
                sx={{
                  font: "normal normal 17px/22px",
                  color: "#1A281F",
                }}
                onClick={() => {
                  setIsOtp(false);
                  setOtp1("");
                  setOtp2("");
                  setOtp3("");
                  setOtp4("");
                }}
              >
                Change Number
              </Button>
              <Button
                variant="text"
                sx={{
                  font: "normal normal 17px/22px",
                  color: "#1A281F",
                }}
              >
                Resend Code
              </Button>
            </ButtonGroup>
          </Box>
        ) : (
          <Box
            sx={{
              p: 3,
            }}
          >
            <Typography
              variant="h5"
              component="h2"
              sx={{
                font: "normal normal 22px/28px Work Sans",
              }}
            >
              Welcome to Sharik
            </Typography>
            <FormControl
              sx={{
                width: "100%",
                mt: 2,
              }}
            >
              <PhoneInput
                countryCodeEditable={false}
                preferredCountries={["sa", "in"]}
                country={"sa"}
                enableSearch={true}
                inputProps={{
                  name: "mobile",
                  required: true,
                  autoFocus: true,
                }}
                disabled={isOtp}
                disableSearchIcon={true}
                value={number}
                onChange={(
                  value: string,
                  data: CountryData,
                  event: React.ChangeEvent<HTMLInputElement>,
                  formattedValue: string
                ) => {
                  mobileChange(value, data);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") getOTP();
                }}
                inputStyle={{
                  width: "100%",
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  border: "1px solid #EAEAEA",
                  borderRadius: "4px",
                  height: "48px",
                }}
              />
            </FormControl>
            <Typography
              sx={{
                mt: 3,
                mb: 3,
              }}
              variant="body2"
              color="text.secondary"
            >
              We’ll call or text you to confirm your number. Standard message
              and data rates apply.
              <Link href="/privacy-policy">Privacy Policy</Link>
            </Typography>
            <Button
              variant="contained"
              sx={{
                background: "#E85656 0% 0% no-repeat padding-box",
                width: "100%",
                height: "48px",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: "#E85656",
                },
              }}
              onClick={() => {
                getOTP();
              }}
            >
              Next
            </Button>
            <Divider
              sx={{
                mt: 2,
                mb: 2,
              }}
            >
              or
            </Divider>
            <Button
              variant="contained"
              sx={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                width: "100%",
                height: "48px",
                borderRadius: "10px",
                border: "1px solid #EAEAEA",
                color: "#1A281F",
                "&:hover": {
                  backgroundColor: "#fff",
                },
              }}
            >
              <AppleIcon
                sx={{
                  mr: 1,
                }}
              />
              Sign in with Apple
            </Button>
            <Typography
              sx={{
                mt: 3,
              }}
              variant="body2"
              color="text.secondary"
            >
              By signing In you accept our{" "}
              <Link href="/terms-conditions">Terms of Use</Link> and{" "}
              <Link href="/privacy-policy">Privacy Policy</Link>
            </Typography>
          </Box>
        )}
      </CustomModal>
    </>
  );
}
