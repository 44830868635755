import { Box, Container, Grid } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import NotFoundImage from './../../../assets/img/404.jpg';
import { useParams } from 'react-router-dom';
import { ApiData } from '../../../Api/url';
import { LoadingFall } from '../Loading/LoadingFall';

function NotFoundPage() {
  const params = useParams();
  const TestObj = {
    'cancellation-policy': '2',
    'terms-conditions': '3',
    'privacy-policy': '4'
  };
  const [description, setDescription] = useState('');
  const [Loding, setLoding] = useState(false);
  useEffect(() => {
    const id = params?.path && TestObj?.[params.path];
    setLoding(true);
    if (id) {
      axios.get(`${ApiData.pages + id}`).then((res) => {
        setDescription(res.data.response.data.page_desc);
        setLoding(false);
      });
    } else {
      setLoding(false);
    }
  }, []);

  return (
    <>
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        {Loding ? (
          <Box>
            <Grid>
              <Container
                maxWidth={'xl'}
                style={{
                  padding: '15px',
                  justifyContent: 'center',
                  height: '300px',
                  direction: "rtl"
                }}
              >
                <LoadingFall />
              </Container>
            </Grid>
          </Box>
        ) : (
          <Grid container>
            <Grid
              item
              xs={8}
              sx={{
                margin: 'auto'
              }}
            >
              {description ? (
                <div
                  dangerouslySetInnerHTML={{ __html: description }}
                  style={{
                    padding: '15px',
                    marginTop: '100px',
                    justifyContent: 'center',
                    direction: "rtl"
                  }}
                />
              ) : (
                <Box>
                  <Grid>
                    <Container maxWidth={'xl'}>
                      <img
                        src={NotFoundImage}
                        alt={'a'}
                        style={{
                          width: 'inherit',
                          marginTop: '4em'
                        }}
                        loading='lazy'
                      />
                    </Container>
                  </Grid>
                </Box>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
}

export default NotFoundPage;
