import { Box, Modal, SxProps, Theme } from '@mui/material';
import React from 'react';

export interface CustomModalProps {
  open: boolean;
  toggleModal(event: {}, reason: 'backdropClick' | 'escapeKeyDown'): void;
  children?: React.ReactNode;
  sx?: SxProps<Theme> | undefined;
  id?: string;
}

export const CustomModal = (props: CustomModalProps) => {
  const sx: SxProps<Theme> | undefined = {
    ...props.sx,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '10px'
  };

  return (
    <Modal open={props.open} onClose={props.toggleModal}>
      <Box sx={sx} id={props.id}>
        {props.children}
      </Box>
    </Modal>
  );
};
