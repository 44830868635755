import axios from 'axios';
import { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import config from '../../../../util/config.js';
import { carObj, ResponseInterface } from '../../../../util/types';
import { Button, Card } from '@mui/material';
import { SideBar } from '../../sidebar/sidebar';
import { useSelector } from 'react-redux';
import { userAccessInfoSelector } from '../../login/corporateAuthSlice';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { Branch } from '../types';

export function Branches() {
  const navigate = useNavigate();
  const [carList, setCarList] = useState<Branch[]>([]);
  const userData = useSelector(userAccessInfoSelector);

  const statusUpdate = (user_id: any, status: any) => {
    if (userData?.userID) {
      axios
        .patch(
          config.BASE_URL + '/branch/status/' + user_id,
          {
            status: status
          },
          {
            headers: {
              'x-access-token': localStorage.getItem('token') || '',
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )
        .then(() => {
          getBranches();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const deleteCar = (user_id: any) => {
    if (userData?.userID) {
      axios
        .patch(
          config.BASE_URL + 'branch/delete/' + user_id,
          {},
          {
            headers: {
              'x-access-token': localStorage.getItem('token') || '',
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )
        .then(() => {
          getBranches();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const columns: GridColDef<Branch>[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'email',
      headerName: 'Email',
      width: 220
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 180
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 180
    },
    {
      field: 'location',
      headerName: 'Location',
      width: 220
    },
    {
      field: 'total_cars',
      headerName: 'Total Cars',
      width: 120
    },
    {
      field: 'verified',
      headerName: 'Admin Approval',
      width: 150
    },
    {
      field: 'status_str',
      headerName: 'Status',
      width: 120
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 120,
      renderCell: (params: GridRenderCellParams<any, Branch, any>) => {
        return (
          <>
            {parseInt(params.row.active) === 1 ? (
              <VisibilityOffIcon
                color='error'
                titleAccess='De-Activete'
                sx={{
                  cursor: 'pointer'
                }}
                onClick={() => {
                  statusUpdate(params.row.user_id, 'inactive');
                }}
              />
            ) : (
              <VisibilityIcon
                color='success'
                sx={{
                  cursor: 'pointer'
                }}
                titleAccess='Activate'
                onClick={() => {
                  statusUpdate(params.row.user_id, 'active');
                }}
              />
            )}
            <DeleteIcon
              sx={{
                cursor: 'pointer',
                ml: 2
              }}
              titleAccess='Delete Car'
              onClick={() => {
                deleteCar(params.row.user_id);
              }}
            />
          </>
        );
      }
    },
    {
      field: 'view',
      headerName: 'View',
      width: 120,
      renderCell: (params: GridRenderCellParams<any, Branch, any>) => {
        return <Link to={'/branch/' + params.row.user_id}>View</Link>;
      }
    }
  ];

  useEffect(() => {
    getBranches();
  }, [userData]);

  const getBranches = () => {
    if (userData?.userID) {
      axios
        .post(
          config.BASE_URL + 'branch/list',
          {},
          {
            headers: {
              'x-access-token': localStorage.getItem('token') || '',
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )
        .then((res: ResponseInterface<Branch[]>) => {
          const data =
            res.data?.response?.data?.map((ele) => {
              return {
                ...ele,
                id: ele.user_id,
                status_str: parseInt(ele.active) === 1 ? 'Active' : 'In-Active',
                verified: parseInt(ele.verified) === 1 ? 'Verified' : 'Pending'
              };
            }) || [];

          setCarList(data);
        });
    }
  };

  return (
    <SideBar>
      <Button
        onClick={() => {
          navigate('/add-branch');
        }}
      >
        Add Branch
      </Button>
      <Card
        sx={{
          height: '650px',
          p: 2
        }}
      >
        <DataGrid
          rows={carList}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          // checkboxSelection
          showCellRightBorder
          disableSelectionOnClick
        />
      </Card>
    </SideBar>
  );
}
