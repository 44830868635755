import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Alert } from '@mui/material';
import { buttonRed } from '../../mui-sx/button';
import Typography from '@mui/material/Typography';

import { GetData, saveData, getMyIP } from '../../Api/ApiMgr';
import { ApiData } from '../../Api/url';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userIdSelectorSelector } from '../login/authSlice';

export function BankAccount() {
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setsuccessMessage] = useState("");
  const userID = useSelector(userIdSelectorSelector);

  const [country, setCountry] = useState('');
  const [iban, setIban] = useState('');
  const [name, setName] = useState('');
  const [holder, setHolder] = useState('');
  const [Error, setError] = useState<any>({
    Country: false,
    Iban: false,
    Name: false,
    Holder: false,
  });

  const updateData = () => {
    const data = {
      user_id: userID
    };

    GetData(data, ApiData.getBankInfo)
      .then((res) => {
        if (res.code === 200) {
          if (res.data) {
            setCountry(res.data['country']);
            setIban(res.data['iban']);
            setName(res.data['bank_name']);
            setHolder(res.data['name']);
          }
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  useEffect(() => {
    updateData();
  }, []);

  const update = () => {
    if (country === "") {
      setError({ Country: true });
    } else if (iban === "") {
      setError({ Iban: true });
    } else if (name === "") {
      setError({ Name: true });
    } else if (holder === "") {
      setError({ Holder: true });
    } else {
    getMyIP().then((e) => {
      var data = {
        user_id: userID,
        name: holder,
        bank: name,
        country: country,
        iban: iban,
        device_source: 'web',
        device_address: e
      };

      saveData(data, ApiData.updateBank)
        .then((e) => {
          if (e.data['response'].status === 200) {
            setsuccessMessage(e.data['response'].userMessage);
          seterrorMessage('');
          }else {
            seterrorMessage(e.data['response'].userMessage);
        setsuccessMessage('');
          }
        })
        .catch((e) => {
          alert(e);
        });
    })};
  };

  return (
    <Box sx={{ width: '80%' }}>
      <Box mt={2} ml={4}>
        <Typography variant='body2' color='text.secondary'>
          *All fields are mandatory.
        </Typography>
        {(errorMessage || successMessage) && (
        <Alert
          color={successMessage ? 'success' : 'error'}
          severity={successMessage ? 'success' : 'error'}
          sx={{
            mb: 2,
          }}
        >
          {errorMessage || successMessage}
        </Alert>
       )}
        <Box
          mt={2}
          component='form'
          sx={{
            '& > :not(style)': { m: 1, width: '47%' }
          }}
          noValidate
          autoComplete='off'
        >
          <TextField
            id='outlined-name'
            label='Country'
            value={country}
            onChange={(e) => {setCountry(e.target.value);
              if (e.target.value !== "") {
                setError({ Country: false });
              }}}
            error={Error.Country === true}
            helperText={Error.Country === true ? "Country is require" : " "}
          />

          <TextField
            id='outlined-name'
            label='IBAN'
            value={iban}
            onChange={(e) => {setIban(e.target.value);
              if (e.target.value !== "") {
                setError({ Iban: false });
              }}}
            error={Error.Iban === true}
            helperText={Error.Iban === true ? "IBAN is require" : " "}
          />

          <TextField
            id='outlined-name'
            label='Bank Name'
            value={name}
            onChange={(e) => {setName(e.target.value);
              if (e.target.value !== "") {
                setError({ Name: false });
              }}}
            error={Error.Name === true}
            helperText={Error.Name === true ? "Bank Name is require" : " "}
          />

          <TextField
            id='outlined-name'
            label='Account Holder Name'
            value={holder}
            onChange={(e) => {setHolder(e.target.value);
              if (e.target.value !== "") {
                setError({ Holder: false });
              }}}
            error={Error.Holder === true}
            helperText={Error.Holder === true ? "Account Holder Name is require" : " "}
          />
        </Box>

        <Box mt={3}>
          <Button
            onClick={() => {
              update();
            }}
            sx={{
              ...buttonRed,
              float: 'right'
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
