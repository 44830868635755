import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { authStateUpdate, checkIsAuth } from '../features/login/authSlice';
import SharikCorporateRoutes from './corporate';
import SharikUserRoutes from './user';
import { checkIsCorporateAuth, corporateAuthStateUpdate } from '../features/corporate/login/corporateAuthSlice';

export default function SharikRoutes () {
  // add corporate check in redux
  const corporateRoute = window.location.host.startsWith('corporate');
  const isAuth = useSelector(checkIsAuth);
  const isCorporateAuth = useSelector(checkIsCorporateAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (corporateRoute) {
      dispatch(corporateAuthStateUpdate())
    } else {
      dispatch(authStateUpdate());
    }
  }, []);

  return corporateRoute
    ? (
    <SharikCorporateRoutes isAuth={isCorporateAuth} />
      )
    : (
    <SharikUserRoutes isAuth={isAuth} />
      );
}
