import * as React from 'react';
import Box from '@mui/material/Box';
import { usePlacesWidget } from 'react-google-autocomplete';
import TextField from '@mui/material/TextField';
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { clearState as clearStateCarInfo } from './slice/addCarInfoSlice';
import { clearState as clearStatePhoto } from './slice/addCarPhotosSlice';
import { clearState as clearStateFeatures } from './slice/addFeaturesSlice';
import { clearState as clearStateInsurance } from './slice/addInsuranceInfoSlice';
import { clearState as clearStateTrip } from './slice/addTripInfoSlice';
import CarCategoryDropdown from '../../dropdowns/carCategoryDropdown';
import CarMakeDropdown from '../../dropdowns/carMakeDropdown';
import CarModelDropdown from '../../dropdowns/carModelDropdown';
import { LocationOnOutlined } from '@mui/icons-material';
import { AddCarInfoRequest } from './interface/addCarInterface';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCarInfoSelector,
  setCarInfoFormState
} from './slice/addCarInfoSlice';
import {
  clearDropdownState,
  DropdownSelector
} from '../../dropdowns/dropdownSlice';
import { getCarTransmission } from '../cars/carsAPI';
import { userAccessInfoSelector } from '../../corporate/login/corporateAuthSlice';

export default function AddVehicleSummary() {
  const dispatch = useDispatch();
  const { carInfoData } = useSelector(addCarInfoSelector);
  const corporateDataSelector = useSelector(userAccessInfoSelector);
  const [carTransmissionOptions, setCarTransmissionOptions] = React.useState<
    any[]
  >([]);
  const { selectedCategoryId, selectedMakeId, selectedModelId } =
    useSelector(DropdownSelector);

  React.useEffect(() => {
    const formo = {
      ...carInfoData,
      location: corporateDataSelector?.data?.location || ''
    };
    if (corporateDataSelector?.data?.location_lat) {
      formo.latitude = parseInt(corporateDataSelector?.data?.location_lat);
    }
    if (corporateDataSelector?.data?.location_lng) {
      formo.longitude = parseInt(corporateDataSelector?.data?.location_lng);
    }
    setCarInfoFormStateUpdate({
      ...formo
    });
  }, [corporateDataSelector]);

  const [selectedTransmission, setSelectedTransmission] =
    React.useState<string>('');

  React.useEffect(() => {
    getCarTransmissionOptions();
    dispatch(clearStateCarInfo());
    dispatch(clearStatePhoto());
    dispatch(clearStateFeatures());
    dispatch(clearStateInsurance());
    dispatch(clearDropdownState());
    dispatch(clearStateTrip());
  }, []);

  const getCarTransmissionOptions = async () => {
    const options = await getCarTransmission();
    if (options && options.data) {
      setCarTransmissionOptions(options.data);
    }
  };

  const setCarInfoFormStateUpdate = (carInfoData: AddCarInfoRequest) => {
    dispatch(setCarInfoFormState(carInfoData));
  };

  React.useEffect(() => {
    setCarInfoFormStateUpdate({
      ...carInfoData,
      transmission: selectedTransmission || ''
    });
  }, [selectedTransmission]);

  React.useEffect(() => {
    setCarInfoFormStateUpdate({
      ...carInfoData,
      category: selectedCategoryId || ''
    });
  }, [selectedCategoryId]);

  React.useEffect(() => {
    setCarInfoFormStateUpdate({ ...carInfoData, make: selectedMakeId || '' });
  }, [selectedMakeId]);

  React.useEffect(() => {
    setCarInfoFormStateUpdate({ ...carInfoData, model: selectedModelId || '' });
  }, [selectedModelId]);

  const { ref: materialRef } = usePlacesWidget({
    apiKey: 'AIzaSyB8svhnyEyYYRYu9phI3afRxNjodA0eiYE',
    options: {
      types: 'geocode'
    },
    onPlaceSelected: (place) => {
      setCarInfoFormStateUpdate({
        ...carInfoData,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        location: place.formatted_address
      });
      // why we have added corporateAuthStateUpdate ?
      // dispatch(corporateAuthStateUpdate());
    }
  });

  const onPurchaseDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.value == '' ||
      (parseInt(event.target.value) >= 0 &&
        parseInt(event.target.value) <
          new Date(
            new Date().setFullYear(new Date().getFullYear() + 2)
          ).getFullYear())
    ) {
      setCarInfoFormStateUpdate({
        ...carInfoData,
        purchased_date: event.target.value
      });
    }
  };

  const onOdometerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value == '' || parseInt(event.target.value) >= 0) {
      setCarInfoFormStateUpdate({
        ...carInfoData,
        odometer: Number(event.target.value)
      });
    }
  };

  const onTransmissionChange = (
    event: React.MouseEvent<HTMLElement>,
    selectedTransmission: string
  ) => {
    setSelectedTransmission(selectedTransmission);
    setCarInfoFormStateUpdate({
      ...carInfoData,
      transmission: selectedTransmission
    });
  };

  return (
    <Box
      component='form'
      sx={{
        '& > :not(style)': { m: 3, width: '45%' },
        width: '100%'
      }}
      noValidate
      autoComplete='off'
    >
      <Typography> *All fields are mandatory </Typography>
      <FormControl fullWidth sx={{ m: 1 }}>
        <InputLabel htmlFor='price'>Car Location</InputLabel>
        <OutlinedInput
          label='Car Location'
          inputRef={materialRef}
          value={carInfoData.location}
          onChange={(e) => {
            setCarInfoFormStateUpdate({
              ...carInfoData,
              location: e.target.value
            });
          }}
          autoComplete={'off'}
          inputProps={{
            autoComplete: 'off',
            form: {
              autoComplete: 'off'
            }
          }}
          endAdornment={
            <InputAdornment position='end'>
              <LocationOnOutlined />
            </InputAdornment>
          }
        />
      </FormControl>
      <TextField
        label='Purchase Year'
        id='purchase year'
        type={'number'}
        value={carInfoData.purchased_date || ''}
        onChange={onPurchaseDateChange}
      />
      <FormControl sx={{ m: 1 }}>
        <InputLabel>Car Category</InputLabel>
        <CarCategoryDropdown />
      </FormControl>
      <FormControl sx={{ m: 1 }}>
        <InputLabel>Car Make</InputLabel>
        <CarMakeDropdown />
      </FormControl>
      <FormControl sx={{ m: 1 }}>
        <InputLabel>Car Model</InputLabel>
        <CarModelDropdown />
      </FormControl>
      <TextField
        label='Odometer Reading'
        id='0dometer-reading'
        type={'number'}
        value={carInfoData.odometer || ''}
        onChange={onOdometerChange}
      />
      <Grid container>
        <Typography variant='body1'>Transmission</Typography>
      </Grid>
      <Grid container>
        <ToggleButtonGroup
          color='primary'
          value={selectedTransmission}
          exclusive
          onChange={onTransmissionChange}
        >
          {carTransmissionOptions &&
            carTransmissionOptions.length > 0 &&
            carTransmissionOptions.map((val) => (
              <ToggleButton value={val.variant_id} key={val.variant_id}>
                {val.value}
              </ToggleButton>
            ))}
        </ToggleButtonGroup>
      </Grid>
    </Box>
  );
}
