import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { latLngConst } from '../../../redux/locationSlice';
import { useEffect, useState } from 'react';
import Geocode from 'react-geocode';

export type GoogleMapPointers = {
  lat: number;
  lng: number;
};

export type GoogleMapProps = {
  pointers: GoogleMapPointers;
  setLatLng: Function;
  children?: React.ReactNode;
  setlocation: Function;
};

export const GoogleMap = (props: GoogleMapProps) => {
  const [apiLoaded, setApiLoaded] = useState(false);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const pos = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      };
      try {
        const address = await Geocode.fromLatLng(
          pos.latitude + '',
          pos.longitude + ''
        );
        props.setlocation(address.results[0].formatted_address);
        props.setLatLng(pos);
      } catch (error) {}
    });
  }, []);

  Geocode.setApiKey('AIzaSyB8svhnyEyYYRYu9phI3afRxNjodA0eiYE');

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: 'AIzaSyB8svhnyEyYYRYu9phI3afRxNjodA0eiYE',
        libraries: ['places']
      }}
      onGoogleApiLoaded={() => {
        setApiLoaded(true);
      }}
      center={props.pointers}
      yesIWantToUseGoogleMapApiInternals={true}
      defaultCenter={{
        lat: latLngConst.latitude,
        lng: latLngConst.longitude
      }}
      onClick={async (value: GoogleMapReact.ClickEventValue) => {
        try {
          const address = await Geocode.fromLatLng(
            value.lat + '',
            value.lng + ''
          );
          props.setlocation(address.results[0].formatted_address);
          props.setLatLng({
            latitude: value.lat,
            longitude: value.lng
          });
        } catch (error) {}
      }}
      defaultZoom={16}
      options={{
        clickableIcons: true
      }}
    >
      <GoogleMapPointers lat={props.pointers.lat} lng={props.pointers.lng} />
      {apiLoaded && props.children}
    </GoogleMapReact>
  );
};

export const GoogleMapPointers = (props: GoogleMapPointers) => (
  <LocationOnIcon />
);
