import { Card } from '@mui/material';
import CarListStepper from '../../carRental/carListForms/carlistStepperCorporate';
import { SideBar } from '../sidebar/sidebar';

export function CarList() {
  return (
    <SideBar>
      <Card>
        <CarListStepper />
      </Card>
    </SideBar>
  );
}
