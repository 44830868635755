import { MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

type Props = {
    value: string;
    label: string;
    list: Array<any>;
    onChange: (event: SelectChangeEvent) => void;
};

export default function CustomSelect (props: Props) {
  return <Select
        value={props.value}
        label={props.label}
        onChange={props.onChange}
    >
        <MenuItem value="">
            <em>Any</em>
        </MenuItem>
        {
            props.list && props.list.length > 0 && props.list.map((item, index) => {
              return <MenuItem value={item.variant_id} key={index}>{item.value}</MenuItem>
            })
        }
    </Select>
}
