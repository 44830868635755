import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { fullButton, fullButtonRed } from "../../mui-sx/button";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { MyProfile } from "./my-profile";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import StarBorderPurple500OutlinedIcon from "@mui/icons-material/StarBorderPurple500Outlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import { useState } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { BankAccount } from "./bank-account";
import { Review } from "./review";
import { Earning } from "./earning";
import { Withdrawal } from "./withdrawal";
import { ContactUs } from "./contact";
import { useSelector } from "react-redux";
import { userAccessInfoSelector } from "../login/authSlice";

export function Profile() {
  const UserData = useSelector(userAccessInfoSelector);
  
  const [page, setPageNo] = useState(0);

  const screen = [
    <MyProfile />,
    <BankAccount />,
    <Review />,
    <Earning />,
    <Withdrawal />,
    <></>,
    <ContactUs />,
  ];

  return (
    <Container maxWidth={"xl"} className="main-container">
      <Grid
        container
        spacing={1}
        sx={{
          m: 1,
        }}
      >
        <Grid container item xs={4}>
          <Card
            variant="outlined"
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                p: 3,
              }}
            >
              <Typography
                variant="h6"
                fontWeight={600}
                sx={{
                  mb: 2,
                }}
              >
                My Profile
              </Typography>
              <Box
                sx={{
                  p: 2,
                  background: "#FAFAFA 0% 0% no-repeat padding-box",
                }}
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  {UserData?.data?.image_path ? (
                    <img
                      src={UserData?.data?.image_path}
                      style={{
                        width: "45px",
                        height: "45px",
                        borderRadius: "25PX",
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        bgcolor: "#fff",
                      }}
                    >
                      <PersonOutlineOutlinedIcon
                        sx={{
                          color: "#000",
                        }}
                      />
                    </Avatar>
                  )}
                  <Typography
                    variant="body1"
                    fontWeight={600}
                    sx={{
                      ml: 2,
                    }}
                  >
                    {/* User */}
                    {UserData?.data?.firstname || "User"}
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  textAlign={"center"}
                  sx={{
                    mt: 2,
                  }}
                >
                  <Box>
                    <Rating name="read-only" value={4} readOnly />
                    <Typography variant="body2" color={"text.secondary"}>
                      Rating
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1" fontWeight={600}>
                      SAR 1720
                    </Typography>
                    <Typography variant="body2" color={"text.secondary"}>
                      Car Earning
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1" fontWeight={600}>
                      SAR 50.00
                    </Typography>
                    <Typography variant="caption" color={"text.secondary"}>
                      Withdrawal
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box>
              <Button
                variant="text"
                onClick={() => {
                  setPageNo(0);
                }}
                sx={{
                  ...(page == 0 ? fullButtonRed : fullButton),
                  justifyContent: "left",
                  padding: "30px",
                }}
              >
                <PersonOutlineOutlinedIcon
                  sx={{
                    padding: "0 10px",
                  }}
                />{" "}
                My Account
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  setPageNo(1);
                }}
                sx={{
                  ...(page == 1 ? fullButtonRed : fullButton),
                  justifyContent: "left",
                  padding: "30px",
                }}
              >
                <AccountBalanceOutlinedIcon
                  sx={{
                    padding: "0 10px",
                  }}
                />{" "}
                Bank Account
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  setPageNo(2);
                }}
                sx={{
                  ...(page == 2 ? fullButtonRed : fullButton),
                  justifyContent: "left",
                  padding: "30px",
                }}
              >
                <StarBorderPurple500OutlinedIcon
                  sx={{
                    padding: "0 10px",
                  }}
                />{" "}
                Review
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  setPageNo(3);
                }}
                sx={{
                  ...(page == 3 ? fullButtonRed : fullButton),
                  justifyContent: "left",
                  padding: "30px",
                }}
              >
                <PercentOutlinedIcon
                  sx={{
                    padding: "0 10px",
                  }}
                />{" "}
                Earnings
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  setPageNo(4);
                }}
                sx={{
                  ...(page == 4 ? fullButtonRed : fullButton),
                  justifyContent: "left",
                  padding: "30px",
                }}
              >
                <AccountBalanceWalletOutlinedIcon
                  sx={{
                    padding: "0 10px",
                  }}
                />{" "}
                Withdrawal
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  setPageNo(5);
                }}
                sx={{
                  ...(page == 5 ? fullButtonRed : fullButton),
                  justifyContent: "left",
                  padding: "30px",
                }}
              >
                <ArticleOutlinedIcon
                  sx={{
                    padding: "0 10px",
                  }}
                />{" "}
                Legal
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  setPageNo(6);
                }}
                sx={{
                  ...(page == 6 ? fullButtonRed : fullButton),
                  justifyContent: "left",
                  padding: "30px",
                }}
              >
                <CallOutlinedIcon
                  sx={{
                    padding: "0 10px",
                  }}
                />{" "}
                Contact Us
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  setPageNo(7);
                }}
                sx={{
                  ...(page == 7 ? fullButtonRed : fullButton),
                  justifyContent: "left",
                  padding: "30px",
                }}
              >
                <ExitToAppOutlinedIcon
                  sx={{
                    padding: "0 10px",
                  }}
                />{" "}
                Logout
              </Button>
            </Box>
          </Card>
        </Grid>
        <Grid container item xs={8}>
          <Card
            variant="outlined"
            sx={{
              width: "100%",
            }}
          >
            {screen[page]}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
