import axios from 'axios';
import { ApiData } from '../../../Api/url.js';
import config from '../../../util/config.js';
import {
  AddCarInfoRequest,
  AddKeySpecificationsRequest,
  AddFeaturesRequest,
  AddInsuranceInfoRequest,
  AddTripInfoRequest,
  AddCarPhotosRequest
} from './interface/addCarInterface';

export async function addCarInfoApi(req: AddCarInfoRequest) {
  const data = {
    user_id: req.user_id,
    location: req.location,
    purchased_date: req.purchased_date,
    category: req.category,
    make: req.make,
    model: req.model,
    odometer: req.odometer,
    transmission: req.transmission,
    location_lat: req.latitude,
    location_lng: req.longitude
  };

  try {
    const response = await axios.post(config.BASE_URL + 'addCarInfo', data, {
      headers: {
        'x-access-token': localStorage.getItem('token') || '',
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.data.response;
  } catch (error: any) {
    throw new Error(error);
  }
}

export async function addKeySpecificationsApi(
  req: AddKeySpecificationsRequest
) {
  const data = {
    car_id: req.car_id,
    plate_letter_left: req.plate_letter_left,
    plate_letter_middle: req.plate_letter_middle,
    plate_letter_right: req.plate_letter_right,
    plate_type: req.plate_type,
    sequence_number: req.sequence_number,
    ownerIdentityNumber: req.ownerIdentityNumber,
    ownerDateOfBirthHijri: req.ownerDateOfBirthHijri,
    plate_number: req.plate_number
  };

  try {
    const response = await axios.post(ApiData.addCarDetails, data, {
      headers: {
        'x-access-token': localStorage.getItem('token') || '',
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.data.response;
  } catch (error: any) {
    throw new Error(error);
  }
}

export async function addTripInfoApi(req: AddTripInfoRequest) {
  const data: AddTripInfoRequest = {
    car_id: req.car_id,
    trip_type: req.trip_type,
    max_duration: req.max_duration,
    min_duration: req.min_duration,
    rental_rate: req.rental_rate,
    currency: 1
  };

  try {
    const response = await axios.post(config.BASE_URL + 'addTripInfo', data, {
      headers: {
        'x-access-token': localStorage.getItem('token') || '',
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.data.response;
  } catch (error: any) {
    throw new Error(error);
  }
}

export async function addInsuranceInfoApi(req: AddInsuranceInfoRequest) {
  const data: AddInsuranceInfoRequest = {
    car_id: req.car_id,
    provider: req.provider,
    policy_number: req.policy_number,
    min_charges: req.min_charges
  };

  try {
    const response = await axios.post(
      config.BASE_URL + 'addInsuranceInfo',
      data,
      {
        headers: {
          'x-access-token': localStorage.getItem('token') || '',
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data.response;
  } catch (error: any) {
    throw new Error(error);
  }
}

export async function addFeaturesApi(req: AddFeaturesRequest) {
  const data: AddFeaturesRequest = {
    car_id: req.car_id,
    features: req.features
  };

  try {
    const response = await axios.post(config.BASE_URL + 'addFeatures', data, {
      headers: {
        'x-access-token': localStorage.getItem('token') || '',
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.data.response;
  } catch (error: any) {
    throw new Error(error);
  }
}

export async function addCarPhotosApi(req: AddCarPhotosRequest) {
  const data: AddCarPhotosRequest = {
    car_id: req.car_id,
    file_paths: req.file_paths
  };

  try {
    const response = await axios.post(config.BASE_URL + 'addCarPhotos', data, {
      headers: {
        'x-access-token': localStorage.getItem('token') || '',
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.data.response;
  } catch (error: any) {
    throw new Error(error);
  }
}
