import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { useEffect, useState } from 'react';
import config from '../../../util/config.js';
import { carObj, ResponseInterface } from './../../../util/types';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#fbfbfb',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'bold',
    fontSize: '15px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#fbfbfb'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

export default function OngoingTrip () {
  const [carList, setCarList] = useState<carObj[]>([]);
  useEffect(() => {
    if ('asas'.includes('r')) {
      axios
        .post(config.BASE_URL + 'listed-cars', {
          user_id: 8,
          type: 'active'
        })
        .then((res: ResponseInterface<carObj[]>) =>
          setCarList(res.data?.response?.data || [])
        );
    } else {
      setCarList([]);
    }
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label='customized table'>
        <TableHead sx={{ backgroundColor: '#fbfbfb' }}>
          <TableRow>
            <StyledTableCell>Driver Name</StyledTableCell>
            <StyledTableCell align='right'>Trip Type</StyledTableCell>
            <StyledTableCell align='right'>Amount</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {carList.map((row) => (
            <StyledTableRow key={row.car_id}>
              <StyledTableCell component='th' scope='row'>
                {row.car_make}
              </StyledTableCell>
              <StyledTableCell align='right'>{0}</StyledTableCell>
              <StyledTableCell align='right'>{row.rental_rate}</StyledTableCell>
              <StyledTableCell align='right'>{'Active'}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
