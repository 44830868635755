import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { RootState } from '../../../app/store';
import { getJsonParse } from '../../../util/util';
import { loginReq, login } from './corporateLoginAPI';

export const loginAsync = createAsyncThunk(
  'corporate-login/start',
  async (req: loginReq, thunkAPI) => {
    try {
      const response = await login(req);
      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log('Error', e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const corporateAuthSlice = createSlice({
  name: 'AuthCorporateUser',
  initialState: {
    isSuccess: false,
    isError: false,
    isFetching: false,
    errorMessage: '',
    successMessage: '',
    email: '',
    phonenumber: '',
    isVerified: false,
    userData: null as any,
    userAccessInfo: null as {
      userID: number;
      roleId: number;
      data: {
        name: string;
        title: string;
        active: number;
        created_at: number;
        email: string;
        softdelete: number;
        verified: number;
        location: string;
        location_lat: string;
        location_lng: string;
      };
    } | null,
    token: '',
    isAuth: false,
    userId: 0
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      state.successMessage = '';
      return state;
    },
    logout: (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('userData');
      state.isAuth = false;
      state.token = '';
      state.userAccessInfo = null;
      return state;
    },
    corporateAuthStateUpdate: (state) => {
      const userDataStr = localStorage.getItem('userData');
      const token = localStorage.getItem('token');
      if (token) {
        if (userDataStr) {
          state.userData = getJsonParse(userDataStr);
        }
        state.userAccessInfo = jwtDecode(token);
        state.token = token;
        state.isAuth = true;
      } else {
        state.token = '';
        state.isAuth = false;
      }
      return state;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.fulfilled, (state, action: any) => {
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('userData', getJsonParse(action.payload.data));
        state.userAccessInfo = jwtDecode(action.payload.token);
        state.token = action.payload.token;
        state.userData = action.payload.data;
        state.isFetching = false;
        state.isSuccess = true;
        state.isVerified = true;
        state.isAuth = true;
        state.successMessage = action.payload.userMessage;
        return state;
      })
      .addCase(loginAsync.rejected, (state, action: any) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload.userMessage;
      })
      .addCase(loginAsync.pending, (state) => {
        state.isFetching = true;
      });
  }
});

export default corporateAuthSlice.reducer;

export const { clearState, corporateAuthStateUpdate, logout } =
  corporateAuthSlice.actions;

export const AuthUserSelector = (state: RootState) => state.corporateAuth;

export const checkIsCorporateAuth = (state: RootState) =>
  state.corporateAuth.isAuth;

export const userAccessInfoSelector = (state: RootState) =>
  state.corporateAuth.userAccessInfo;

export const userDataSelector = (state: RootState) =>
  state.corporateAuth.userData;

export const userRoleSelectorSelector = (state: RootState) =>
  state.corporateAuth.userAccessInfo?.roleId;

export const userIdSelectorSelector = (state: RootState) =>
  state.corporateAuth.userAccessInfo?.userID;
