import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid, Stack, SxProps, Checkbox } from '@mui/material';
import { getCarFeatures } from '../cars/carsAPI';
import { useDispatch, useSelector } from 'react-redux';
import { addFeatureSelector, setFeaturesFormState } from './slice/addFeaturesSlice';
import { addCarInfoSelector } from './slice/addCarInfoSlice';
import { AddFeaturesRequest } from './interface/addCarInterface';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const BoxFeatures: SxProps | undefined = {
  width: '70px',
  height: '70px',
  border: '1px solid grey',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const BoxFeaturesSelected: SxProps | undefined = {
  width: '70px',
  height: '70px',
  border: '1px solid grey',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'lightgrey'
};

export default function AddFeatures () {
  const dispatch = useDispatch();
  const { featuresData } = useSelector(addFeatureSelector);
  const { car_id } = useSelector(addCarInfoSelector);
  const [featuresOptions, setFeaturesOptions] = React.useState<any[]>([]);
  const [selectedFeatures, setSelectedFeatures] = React.useState<string[]>([]);
  const [formValues, setFormValues] = React.useState<AddFeaturesRequest>({ ...featuresData });

  React.useEffect(() => {
    dispatch(setFeaturesFormState(formValues));
  }, [formValues]);

  React.useEffect(() => {
    getFeaturesOptions();
  }, []);

  React.useEffect(() => {
    setOptionsCheckValue();
    const list = getCommaSeparatedFeatures();
    setFormValues({ ...formValues, car_id: car_id, features: list });
  }, [car_id, selectedFeatures]);

  const setOptionsCheckValue = () => {
    const optionsCopy = JSON.parse(JSON.stringify(featuresOptions));
    for (let i = 0; i < optionsCopy.length; i++) {
      if (selectedFeatures.length > 0) {
        for (let j = 0; j < selectedFeatures.length; j++) {
          if (optionsCopy[i].feature_id === selectedFeatures[j]) {
            optionsCopy[i].checked = true;
            break;
          } else {
            optionsCopy[i].checked = false;
          }
        }
      } else {
        optionsCopy[i].checked = false;
      }
    };
    setFeaturesOptions(optionsCopy);
  };

  const getCommaSeparatedFeatures = () => {
    const list = [...selectedFeatures];
    const features = list.join(',');
    return features;
  };

  const getFeaturesOptions = async () => {
    const options = await getCarFeatures();
    if (options && options.data) {
      const addedCheckedStatusList = options.data.map((val: { checked: boolean; }) => ({ ...val, checked: false }));
      setFeaturesOptions(addedCheckedStatusList);
    };
  };

  const onFeatureSelect = (
    event: React.ChangeEvent<HTMLInputElement>, checked: boolean, id: string
  ) => {
    const feat = [...selectedFeatures];
    if (checked) {
      const hasId = feat.some(val => val === id);
      if (!hasId) {
        setSelectedFeatures([...feat, id]);
      }
    } else {
      const removedIdList = feat.filter(val => val !== id);
      setSelectedFeatures(removedIdList);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        width: '100%', m: 3
      }}
      noValidate
      autoComplete="off"
    >
      <Grid container>
        <Stack direction="row" alignItems="center" spacing={2}>
          {
            featuresOptions.length > 0 && featuresOptions.map(val => (
              <Box sx={val.checked ? BoxFeaturesSelected : BoxFeatures} key={val.feature_id}>
                <Checkbox
                  {...label}
                  icon={<img src={val.icon?.path_prefix + val.icon?.types[0]?.value || ''} />}
                  checkedIcon={<img src={val.icon?.path_prefix + val.icon?.types[0]?.value || ''} />}
                  onChange={(e, checked) => onFeatureSelect(e, checked, val.feature_id)}
                />
              </Box>
            ))}
        </Stack>
      </Grid>
    </Box >
  );
}
