import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { addInsuranceInfoApi } from '../addCarAPI';
import { AddInsuranceInfoRequest } from '../interface/addCarInterface';

export interface AddInsuranceInfoState {
  isInsuranceSuccess: boolean;
  isInsuranceError: boolean;
  isInsuranceFetching: boolean;
  errorInsuranceMessage: string;
  insuranceInfoData: AddInsuranceInfoRequest;
}

export const addInsuranceInfoAsync = createAsyncThunk(
  'car/addInsuranceInfo',
  async (req: AddInsuranceInfoRequest, thunkAPI) => {
    try {
      const response = await addInsuranceInfoApi(req);
      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log('Error', e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState: AddInsuranceInfoState = {
  isInsuranceSuccess: false,
  isInsuranceError: false,
  isInsuranceFetching: false,
  errorInsuranceMessage: '',
  insuranceInfoData: {
    car_id: 0,
    provider: '',
    policy_number: '',
    min_charges: 0
  }
};

export const AddInsuranceInfoSlice = createSlice({
  name: 'addInsuranceInfo',
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.isInsuranceSuccess = false;
      state.isInsuranceError = false;
      state.isInsuranceFetching = false;
      state.errorInsuranceMessage = '';
      state.insuranceInfoData.car_id = 0;
      state.insuranceInfoData.provider = '';
      state.insuranceInfoData.policy_number = '';
      state.insuranceInfoData.min_charges = 0;
      return state;
    },
    setInsuranceInfoFormState: (
      state,
      action: PayloadAction<AddInsuranceInfoRequest>
    ) => {
      state.insuranceInfoData.car_id = action.payload.car_id;
      state.insuranceInfoData.provider = action.payload.provider;
      state.insuranceInfoData.policy_number = action.payload.policy_number;
      state.insuranceInfoData.min_charges = action.payload.min_charges;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addInsuranceInfoAsync.fulfilled, (state, action: any) => {
        state.isInsuranceFetching = false;
        state.isInsuranceSuccess = true;
        return state;
      })
      .addCase(addInsuranceInfoAsync.rejected, (state, action: any) => {
        state.isInsuranceFetching = false;
        state.isInsuranceError = true;
        state.errorInsuranceMessage = action.payload.userMessage;
      })
      .addCase(addInsuranceInfoAsync.pending, (state) => {
        state.isInsuranceFetching = true;
      });
  }
});

export default AddInsuranceInfoSlice.reducer;

export const { clearState, setInsuranceInfoFormState } =
  AddInsuranceInfoSlice.actions;

export const addInsuranceInfoSelector = (state: RootState) =>
  state.addInsuranceInfo;
