import { Box, CardContent, Container, Grid, Typography } from "@mui/material";
import homeBanner from "./../../../assets/img/home-page-banner1-2x.png";
import banner from "./../../../assets/img/desert1.jpg";
import "./home.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { SwitchBar } from "../../../components/modules/SwitchBar/SwitchBar";
import { getCarsList, getCarsListAsync } from "../cars/carsSlice";
import { getLatLng } from "../../../redux/locationSlice";
import { getCarMake } from "../cars/carsAPI";
import { CarMakeSlider } from "./CarMakeSlider";
import { NearByLocation } from "../../../util/types/location";
import { distance } from "../../../util/util";

export function Home() {
  const dispatch = useDispatch();
  const { latLng } = useSelector(getLatLng);
  const [carMakeOptions, setCarMakeOptions] = useState<any[]>([]);
  const [location, setLocation] = useState<NearByLocation[]>([]);

  const styles = {
    paperContainer: {
      background: `url(${banner}) no-repeat`,
      backgroundSize: "cover",
      backgroundPosition: "bottom",
    },
  };

  useEffect(() => {
    getCarMakeOptions();
    getNearByLocationAsync();
  }, [(window as any)?.google, latLng.latitude, latLng.longitude]);

  const getCarMakeOptions = async () => {
    const options = await getCarMake();
    if (options && options.data) {
      setCarMakeOptions(options.data);
    }
  };

  useEffect(() => {
    const latitude = latLng.latitude;
    const longitude = latLng.longitude;
    dispatch(getCarsListAsync({ latitude, longitude }));
  }, [latLng.latitude, latLng.longitude]);

  const getNearByLocationAsync = async () => {
    try {
      const latitude = latLng.latitude;
      const longitude = latLng.longitude;

      const googleService = (window as any)?.google;
      if (googleService as any) {
        let placesService = new googleService.maps.places.PlacesService(
          document.getElementById("mapIDDI")
        );
        placesService.nearbySearch(
          {
            location: {
              lat: latitude,
              lng: longitude,
            },
            radius: 50000,
          },
          (e: any) => {
            setLocation(e);
          }
        );
      }
    } catch (error: any) {
      setLocation([]);
    }
  };

  return (
    <>
      <Grid
        container
        alignItems={"center"}
        style={styles.paperContainer}
        minHeight={720}
      >
        <Container maxWidth={"xl"}>
          <SwitchBar showFeatureSelection />
        </Container>
      </Grid>
      <Container maxWidth={"xl"} className="home-container">
        <Typography fontWeight={"600"} variant="h4" margin={"30px 0"}>
          Nearby Locations
        </Typography>
        <div id="mapIDDI" />
        <Grid container>
          <Grid container item xs={12} spacing={2}>
            {location.slice(0, 6).map((ele: NearByLocation, index: any) => (
              <Grid item xs={4} key={index}>
                <div>
                  <Grid container>
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={4}>
                        {ele?.photos?.[0]?.getUrl ? (
                          <img
                            src={ele.photos[0].getUrl()}
                            className="home-card-image"
                            alt={"a"}
                            loading="lazy"
                          />
                        ) : (
                          "No Image"
                        )}
                      </Grid>
                      <Grid item xs={8}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <CardContent sx={{ flex: "1 0 auto" }}>
                            <Typography component="div" variant="h5">
                              {ele?.name}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="text.secondary"
                              component="div"
                            >
                              {distance(
                                (ele.geometry.location.lat as any)(),
                                (ele.geometry.location.lng as any)(),
                                latLng.latitude,
                                latLng.longitude,
                                "K"
                              )?.toFixed(2) + "KM Away"}
                            </Typography>
                          </CardContent>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth={"xl"}>
        {/* <Typography
          fontWeight={"600"}
          variant="h4"
          margin={"30px 0"}
          marginTop={'97px'}
          width={"80%"}
          marginLeft={"65px"}
          // height={"80%"}
          // height= {39}
          style={{
            height: "36",
          }}
        > */}
        <Typography fontWeight={"600"} variant="h4" margin={"30px 0"}>
          Browse by make
        </Typography>
        <CarMakeSlider carMakeOptions={carMakeOptions} />
      </Container>
      <Container maxWidth={"xl"}>
        <img
          src={homeBanner}
          alt={"a"}
          style={{
            width: "inherit",
            marginTop: "4em",
          }}
          loading="lazy"
        />
      </Container>
    </>
  );
}
