import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';

import { Button } from '@mui/material';
import { buttonRed } from '../../mui-sx/button';

export function Withdrawal() {
  const history = [
    {
      amount: '150.0',
      date: '01/08/2021 18:12:56'
    },

    {
      amount: '45.0',
      date: '01/08/2021 18:12:56'
    },

    {
      amount: '985.0',
      date: '01/08/2021 18:12:56'
    },

    {
      amount: '410.0',
      date: '01/08/2021 18:12:56'
    }
  ];

  function histoyCard(amount: any, date: any, i:number) {
    return (
      <div  key={i}>
        <Box
          mt={2}
          mb={2}
          sx={{ width: '90%' }}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexDirection={'row'}
        >
          <Box>
            <Typography variant='h6' color='black'>
              {amount}
            </Typography>
          </Box>
          <Box>
            <Typography variant='body2' color='black'>
              {date}
            </Typography>
          </Box>
        </Box>

        <Divider flexItem />
      </div>
    );
  }

  return (
    <div>
      <Box
        mt={3}
        ml={3}
        sx={{ width: '90%' }}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        <Box
          m={2}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          sx={{
            background: '#FAFAFA 0% 0% no-repeat padding-box',
            width: '100%',
            height: '100px'
          }}
        >
          <Typography variant='h5' color='black'>
            SAR 1750.00
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            Total Wallet Balance
          </Typography>
        </Box>
        <Box sx={{ width: '100%' }} mt={3}>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            flexDirection={'row'}
          >
            <Box>
              <Typography variant='body2' color='black'>
                Withdrawal Requests
              </Typography>
            </Box>
            <Box></Box>
          </Box>

          <Divider flexItem />
        </Box>

        <Box mt={2} sx={{ width: '100%' }}>
          {history.map((e,i) => histoyCard(e.amount, e.date, i))}
        </Box>
      </Box>
      <Box mt={4} mr={4}>
        <Button
          sx={{
            ...buttonRed,
            float: 'right'
          }}
        >
          New Request
        </Button>
      </Box>
    </div>
  );
}
