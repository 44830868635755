import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
      display: false
    },
    title: {
      display: false,
      text: ''
    }
  }
};

const labels = ['10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM', '4 PM'];

export const data = {
  labels,
  datasets: [
    {
      label: '',
      data: [12, 19, 3, 5, 2, 3, 5, 12, 19, 3],
      borderColor: 'rgb(94,84,206)',
      backgroundColor: 'rgb(94,84,206)'
    }
  ]
};

export function LineChart () {
  return <Line options={options} data={data} />;
}
