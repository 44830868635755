import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Alert,
} from "@mui/material";
import { buttonRed } from "../../mui-sx/button";
import Typography from "@mui/material/Typography";
import { getMyIP, saveData } from "../../Api/ApiMgr";
import { ApiData } from "../../Api/url";
import TextareaAutosize from "@mui/material/TextareaAutosize";

export function ContactUs() {
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setsuccessMessage] = useState("");
  const [Error, setError] = useState<any>({
    Fullname: false,
    Email: false,
    Message: false,
  });
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const Contact_Us = () => {
    if (fullname === "") {
      setError({ Fullname: true });
    } else if (email === "") {
      setError({ Email: true });
    } else if (message === "") {
      setError({ Message: true });
    } else {
      getMyIP().then((e) => {
        var data = {
          fullname: fullname,
          email: email,
          message: message,
          device_source: "web",
          device_id: e,
        };

        saveData(data, ApiData.contact)
          .then((e) => {
            if (e.data["response"].status === 200) {
              setsuccessMessage(e.data["response"].userMessage);
              seterrorMessage("");
            } else {
              seterrorMessage(e.data["response"].userMessage);
              setsuccessMessage("");
            }
          })
          .catch((e) => {
            alert(e);
          });
      });
    }
  };
  return (
    <Box sx={{ width: "80%" }}>
      <Box mt={2} ml={4}>
        <Typography variant="body2" color="text.secondary">
          *All fields are mandatory.
        </Typography>

        {(errorMessage || successMessage) && (
          <Alert
            color={successMessage ? "success" : "error"}
            severity={successMessage ? "success" : "error"}
            sx={{
              mb: 2,
            }}
          >
            {errorMessage || successMessage}
          </Alert>
        )}

        <Box
          mt={2}
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "47%" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-name"
            label="Full Name"
            // value={''}
            // onChange={(e) => {
            //   alert(e.target.value);
            // }}
            value={fullname}
            onChange={(e) => {
              setFullname(e.target.value);
              if (e.target.value !== "") {
                setError({ Fullname: false });
              }
            }}
            error={Error.Fullname === true}
            helperText={Error.Fullname === true ? "Fullname is require" : " "}
          />

          <TextField
            id="outlined-name"
            label="Email"
            // value={''}
            // onChange={(e) => {
            //   alert(e.target.value);
            // }}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              if (e.target.value !== "") {
                setError({ Email: false });
              }
            }}
            error={Error.Email === true}
            helperText={Error.Email === true ? "Email is require" : " "}
          />
        </Box>
        <Box
          mt={2}
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "96%" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextareaAutosize
            aria-label="maximum height"
            // label="Message"
            minRows={8}
            required
            placeholder="message ..."
            style={{ display: "flex" }}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              if (e.target.value !== "") {
                setError({ Message: false });
              }
            }}
          />
          {Error.Message === true && (
            <p
              style={{
                color: "red",
                fontWeight: 400,
                fontSize: 13,
                lineHeight: 1.66,
                textAlign: "left",
                marginTop: 3,
                marginRight: 14,
                marginBottom: 0,
                marginLeft: 14,
              }}
            >
              Message is require
            </p>
          )}
        </Box>

        <Box mt={3}>
          <Button
            sx={{
              ...buttonRed,
              float: "right",
            }}
            onClick={() => {
              Contact_Us();
            }}
          >
            Send Message
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
