import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import RR2 from "./../../../assets/img/BrowseByMake/RR2.webp";
import { Navigation, Mousewheel, Keyboard } from "swiper";
import "./home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export function CarMakeSlider(props: any) {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={5}
      style={{
        width: "100%",
        maxWidth: "880",
        height: "inherit",
        marginTop: "40",
        borderRadius: "20",
      }}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 50,
        },
      }}
      cssMode={true}
      navigation={true}
      mousewheel={true}
      keyboard={true}
      modules={[Navigation, Mousewheel, Keyboard]}
      className="home-swiper-slide"
    >
      {props.carMakeOptions &&
        props.carMakeOptions.length > 0 &&
        props.carMakeOptions.map((item: any, index: any) => (
          <SwiperSlide
            key={index}
            // style={{
            //   height: "200",
            //   width: "255",
            //   marginRight: "50px",
            //   // alignItems: "center",
            // }}
          >
            <Card style={{
              borderRadius: "11",
            }}>
              <Card>
                <CardMedia
                  component="img"
                  height="194"
                  image={RR2}
                  alt="Paella dish"
                />
                <CardContent>
                  <Typography variant="h6">{item.value}</Typography>
                </CardContent>
              </Card>
            </Card>
          </SwiperSlide>
        ))}
    </Swiper>
  );
}
