import { Alert, Card, Container, FormControl, Typography } from '@mui/material';
import { fullButtonRed } from '../../../mui-sx/button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';
import config from '../../../util/config';
import axios from 'axios';

export function CorporateForgot() {
  const [email, setEmail] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [errorMessage, seterrorMessage] = useState('');
  const [successMessage, setsuccessMessage] = useState('');
  const forgot = () => {
    setIsFetching(true);
    axios
      .post(config.BASE_URL + 'branch/reset-password', {
        email: email
      })
      .then((res) => {
        console.log(res);
        if (res.data.response.status === 200) {
          setsuccessMessage(res.data.response.userMessage);
          seterrorMessage('');
        } else {
          seterrorMessage(res.data.response.userMessage);
          setsuccessMessage('');
        }
        setIsFetching(false);
      })
      .catch((e) => {
        setIsFetching(false);
      });
  };

  return (
    <Container maxWidth={'md'} className='home-container'>
      <Card
        sx={{
          p: 3,
          mt: 5
        }}
      >
        <Typography
          variant='h5'
          component='h2'
          sx={{
            font: 'normal normal 22px/28px Work Sans'
          }}
        >
          Welcome to Sharik
        </Typography>
        <Box
          sx={{
            maxWidth: '100%',
            m: 5
          }}
        >
          {(errorMessage || successMessage) && (
            <Alert
              color={successMessage ? 'success' : 'error'}
              sx={{
                mb: 3
              }}
            >
              {errorMessage || successMessage}
            </Alert>
          )}
          <TextField
            sx={{ mt: 3, mb: 5 }}
            label='Email'
            variant='outlined'
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                forgot();
              }
            }}
            value={email}
            fullWidth
            type={'email'}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <Link
            style={{
              float: 'right'
            }}
            to={'/'}
          >
            Back
          </Link>
        </Box>
        <LoadingButton
          variant='contained'
          loading={isFetching}
          loadingIndicator='Submitting ...'
          sx={{
            ...fullButtonRed
          }}
          onClick={() => {
            forgot();
          }}
        >
          Submit
        </LoadingButton>
      </Card>
    </Container>
  );
}
