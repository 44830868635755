import * as React from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import arabic_ar from 'react-date-object/locales/arabic_ar';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import { addCarInfoSelector } from './slice/addCarInfoSlice';
import { AddKeySpecificationsRequest } from './interface/addCarInterface';
import { useDispatch, useSelector } from 'react-redux';
import {
  addKeySpecificationsSelector,
  setKeySpecificationsFormState
} from './slice/addKeySpecificationsSlice';
import { CustomComponent } from './calender';

export default function AddKeySpecifications() {
  const dispatch = useDispatch();
  const { car_id } = useSelector(addCarInfoSelector);
  const { keySpecificationsData } = useSelector(addKeySpecificationsSelector);

  React.useEffect(() => {
    setKeySpecificationsFormStateUpdate({
      ...keySpecificationsData,
      car_id: car_id
    });
  }, [car_id]);

  const setKeySpecificationsFormStateUpdate = (
    keySpecificationsData: AddKeySpecificationsRequest
  ) => {
    dispatch(setKeySpecificationsFormState({ ...keySpecificationsData }));
  };

  const onidentityNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value == '' || parseInt(event.target.value) >= 0) {
      setKeySpecificationsFormStateUpdate({
        ...keySpecificationsData,
        ownerIdentityNumber: String(event.target.value)
      });
    }
  };
  const onDobHijriChange = (
    selectedDates: DateObject | DateObject[] | null
  ) => {
    if (selectedDates?.toString()) {
      setKeySpecificationsFormStateUpdate({
        ...keySpecificationsData,
        ownerDateOfBirthHijri: selectedDates?.toString()
      });
    }
  };
  const onVehiclePlateNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value == '' || parseInt(event.target.value) >= 0) {
      setKeySpecificationsFormStateUpdate({
        ...keySpecificationsData,
        plate_number: String(event.target.value)
      });
    }
  };
  const onPlateTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // if (event.target.value == '') {
    setKeySpecificationsFormStateUpdate({
      ...keySpecificationsData,
      plate_type: String(e.target.value)
    });
    // }
  };
  const onVehicleSequenceNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value == '' || parseInt(event.target.value) >= 0) {
      setKeySpecificationsFormStateUpdate({
        ...keySpecificationsData,
        sequence_number: String(event.target.value)
      });
    }
  };
  const onRightLetterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // if (event.target.value == '') {
    setKeySpecificationsFormStateUpdate({
      ...keySpecificationsData,
      plate_letter_right: String(event.target.value)
    });
    // }
  };
  const onMiddleLetterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // if (event.target.value == '') {
    setKeySpecificationsFormStateUpdate({
      ...keySpecificationsData,
      plate_letter_middle: String(event.target.value)
    });
    // }
  };
  const onLeftLetterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // if (event.target.value == '') {
    setKeySpecificationsFormStateUpdate({
      ...keySpecificationsData,
      plate_letter_left: event.target.value
    });
    // }
  };

  return (
    <Box
      component='form'
      sx={{
        '& > :not(style)': { m: 3, width: '45%' },
        width: '100%'
      }}
      noValidate
      autoComplete='off'
    >
      <Typography> *All fields are mandatory </Typography>
      <TextField
        label="Owner's Plate Identity Number"
        id='identityNumber'
        type={'number'}
        value={keySpecificationsData.ownerIdentityNumber || ''}
        onChange={onidentityNumberChange}
      />
      <DatePicker
        render={<CustomComponent />}
        format='DD-MM-YYYY'
        value={keySpecificationsData.ownerDateOfBirthHijri}
        onChange={onDobHijriChange}
        locale={arabic_ar}
      />
      <TextField
        label='Vehicle Plate Number'
        id='VehiclePlateNumber'
        type={'number'}
        value={keySpecificationsData.plate_number || ''}
        onChange={onVehiclePlateNumberChange}
      />
      <TextField
        label='Plate Type'
        id='PlateType'
        type='text'
        value={keySpecificationsData.plate_type || ''}
        onChange={onPlateTypeChange}
      />
      <TextField
        label='Vehicle Sequence Number'
        id='VehicleSequenceNumber'
        type='text'
        value={keySpecificationsData.sequence_number || ''}
        onChange={onVehicleSequenceNumberChange}
      />
      <TextField
        label='Vehicle Plate Right Letter'
        id='VehiclePlateRightLetter'
        type='text'
        value={keySpecificationsData.plate_letter_right || ''}
        onChange={onRightLetterChange}
      />
      <TextField
        label='Vehicle Plate Middle Letter'
        id='VehiclePlateMiddleLetter'
        type='text'
        value={keySpecificationsData.plate_letter_middle || ''}
        onChange={onMiddleLetterChange}
      />
      <TextField
        label='Vehicle Plate Left Letter'
        id='VehiclePlateLeftLetter'
        type='text'
        value={keySpecificationsData.plate_letter_left || ''}
        onChange={onLeftLetterChange}
      />
    </Box>
  );
}
