import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { addCarPhotosApi } from '../addCarAPI';
import { AddCarPhotosRequest } from '../interface/addCarInterface';

export interface CarPhotoState {
  isCarPhotosSuccess: boolean;
  isCarPhotosError: boolean;
  isCarPhotosFetching: boolean;
  errorCarPhotosMessage: string;
  photosData: AddCarPhotosRequest;
}

export const addCarPhotosAsync = createAsyncThunk(
  'car/CarPhoto',
  async (req: AddCarPhotosRequest, thunkAPI) => {
    try {
      const response = await addCarPhotosApi(req);
      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log('Error', e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState: CarPhotoState = {
  isCarPhotosSuccess: false,
  isCarPhotosError: false,
  isCarPhotosFetching: false,
  errorCarPhotosMessage: '',
  photosData: {
    car_id: 0,
    file_paths: []
  }
};

export const CarPhotoSlice = createSlice({
  name: 'addCarPhotos',
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.isCarPhotosSuccess = false;
      state.isCarPhotosError = false;
      state.isCarPhotosFetching = false;
      state.errorCarPhotosMessage = '';
      state.photosData.car_id = 0;
      state.photosData.file_paths = [];
      return state;
    },
    setCarPhotosFormState: (
      state,
      action: PayloadAction<AddCarPhotosRequest>
    ) => {
      state.photosData.car_id = action.payload.car_id;
      state.photosData.file_paths = action.payload.file_paths;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCarPhotosAsync.fulfilled, (state, action: any) => {
        state.isCarPhotosFetching = false;
        state.isCarPhotosSuccess = true;
        return state;
      })
      .addCase(addCarPhotosAsync.rejected, (state, action: any) => {
        state.isCarPhotosFetching = false;
        state.isCarPhotosError = true;
        state.errorCarPhotosMessage = action.payload.userMessage;
      })
      .addCase(addCarPhotosAsync.pending, (state) => {
        state.isCarPhotosFetching = true;
      });
  }
});

export default CarPhotoSlice.reducer;

export const { clearState, setCarPhotosFormState } = CarPhotoSlice.actions;

export const addCarPhotoSelector = (state: RootState) => state.addCarPhotos;
