import axios from 'axios';
import { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import config from '../../../util/config.js';
import { TripsType, ResponseInterface } from './../../../util/types';
import { Card } from '@mui/material';
import { SideBar } from '../sidebar/sidebar';
import { useSelector } from 'react-redux';
import { userAccessInfoSelector } from '../login/corporateAuthSlice';
import { Link } from 'react-router-dom';
import { Cancel, Check, CheckCircleOutline } from '@mui/icons-material';
import { capitalize } from '../../../util/util';

export default function UpcomingTrips() {
  const [carList, setCarList] = useState<TripsType[]>([]);
  const userData = useSelector(userAccessInfoSelector);

  const tripAction = (trip_id: any, accept: boolean) => {
    if (userData?.userID) {
      axios
        .post(
          config.BASE_URL + (accept ? 'trips/accept' : 'trips/reject'),
          {
            user_id: userData?.userID,
            trip_id: trip_id
          },
          {
            headers: {
              'x-access-token': localStorage.getItem('token') || '',
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )
        .then((res: ResponseInterface<TripsType[]>) => {
          getUpcomingTrips();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const tripEnd = (trip_id: any) => {
    if (userData?.userID) {
      axios
        .post(
          config.BASE_URL + 'trips/end_trip',
          {
            user_id: userData?.userID,
            trip_id: trip_id
          },
          {
            headers: {
              'x-access-token': localStorage.getItem('token') || '',
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )
        .then((res: ResponseInterface<TripsType[]>) => {
          getUpcomingTrips();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const columns: GridColDef<TripsType>[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'car_make',
      headerName: 'Make Car',
      width: 120
    },
    {
      field: 'car_model',
      headerName: 'Car Model',
      width: 120
    },
    {
      field: 'traveller_name',
      headerName: 'Traveller Name',
      width: 180
    },
    {
      field: 'traveller_email',
      headerName: 'Traveller Email',
      width: 180
    },
    {
      field: 'traveller_phonenumber',
      headerName: 'Traveller Phone Number',
      renderCell: (params: GridRenderCellParams<any, TripsType, any>) => {
        return (
          (params.row.traveller_country_code || '') +
          '' +
          (params.row.traveller_phonenumber || '')
        );
      },
      width: 180
    },
    {
      field: 'payable_amount',
      headerName: 'Amount',
      width: 180
    },
    {
      field: 'from_date',
      headerName: 'From Date',
      width: 180
    },
    {
      field: 'end_date',
      headerName: 'To Date',
      width: 180
    },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   width: 180
    // },
    {
      field: 'action',
      headerName: 'Action / Status',
      width: 150,
      renderCell: (params: GridRenderCellParams<any, TripsType, any>) => {
        return params.row.status === 'booked' ? (
          <>
            <Check
              color='success'
              sx={{
                cursor: 'pointer',
                ml: 2,
                mr: 3
              }}
              titleAccess={'Approve'}
              onClick={() => {
                tripAction(params.row.trip_id, true);
              }}
            />
            <Cancel
              color='error'
              sx={{
                cursor: 'pointer'
              }}
              titleAccess={'Reject'}
              onClick={() => {
                tripAction(params.row.trip_id, false);
              }}
            />
          </>
        ) : params.row.status === 'ongoing' ? (
          <>
            {capitalize(params.row.status)}
            <Cancel
              color='error'
              sx={{
                cursor: 'pointer',
                ml: 1
              }}
              titleAccess={'End Trip'}
              onClick={() => {
                tripEnd(params.row.trip_id);
              }}
            />
          </>
        ) : (
          capitalize(params.row.status)
        );
      }
    },
    {
      field: 'view',
      headerName: 'View',
      width: 120,
      renderCell: (params: GridRenderCellParams<any, TripsType, any>) => {
        return <Link to={'/car/' + params.row.car_id}>View</Link>;
      }
    }
  ];

  useEffect(() => {
    getUpcomingTrips();
  }, [userData]);

  const getUpcomingTrips = () => {
    if (userData?.userID) {
      axios
        .post(
          config.BASE_URL + 'trips/upcoming',
          {
            user_id: userData.userID
          },
          {
            headers: {
              'x-access-token': localStorage.getItem('token') || '',
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )
        .then((res: ResponseInterface<TripsType[]>) => {
          console.log(res);
          const data =
            res.data?.response?.data?.map((ele) => {
              return {
                ...ele,
                id: ele.trip_id,
                status_str: parseInt(ele.status) === 1 ? 'Active' : 'In-Active'
              };
            }) || [];

          setCarList(data);
        });
    }
  };
  if (userData?.roleId === 5) {
    columns.splice(1, 0, {
      field: 'title',
      headerName: 'Branch',
      width: 150
    });
  }
  return (
    <SideBar>
      <Card
        sx={{
          height: '650px',
          p: 2
        }}
      >
        <DataGrid
          rows={carList}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          // checkboxSelection
          showCellRightBorder
          disableSelectionOnClick
        />
      </Card>
    </SideBar>
  );
}
