import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Alert } from '@mui/material';
import { getSearchCarsListAsync } from '../../features/carRental/cars/carsSlice';
import { buttonRed } from '../../mui-sx/button';
import { GetData, saveData, getMyIP } from '../../Api/ApiMgr';
import { ApiData } from '../../Api/url';
import { userIdSelectorSelector } from '../login/authSlice';
import { useSelector, useDispatch } from 'react-redux';
import { getLatLng, setLatLng } from '../../redux/locationSlice';
import { usePlacesWidget } from 'react-google-autocomplete';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isDate } from 'date-fns';

interface MyProfileProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: MyProfileProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component='span'>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export function MyProfile(props: { showFeatureSelection?: boolean }) {
  const dispatch = useDispatch();
  const userID = useSelector(userIdSelectorSelector);

  const [value, setValue] = React.useState(0);
  const { latLng } = useSelector(getLatLng);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [Error, setError] = useState<any>({
    fname: false,
    lname: false,
    lic: false,
    phone: false,
    email: false,
    dob: false,
    addr: false,
    Street: false,
    City: false,
    State: false,
    Country: false,
    PostalCode: false
  });

  const [ename, seteName] = React.useState<string>('');
  const [elame, seteLame] = React.useState<string>('');

  const [elic, seteLic] = React.useState<string>('');
  const [emobo, seteMobo] = React.useState<string>('');
  const [eemail, seteEmail] = React.useState<string>('');
  const [edob, setDob] = React.useState<Date | null>(null);

  const [eaddr, seteAddr] = React.useState<string>('');
  const [estreet1, setstreet] = React.useState<string>('');
  const [ecity, setcity] = React.useState<string>('');
  const [estate, setstate] = React.useState<string>('');
  const [ecountry, setcountry] = React.useState<string>('');
  const [epostalCode, setpostalCode] = React.useState<string>('');
  const [elatitude, setlatitude] = React.useState<string>('');
  const [elongitude, setlongitude] = React.useState<string>('');
  const [errorMessage, seterrorMessage] = useState('');
  const [successMessage, setsuccessMessage] = useState('');

  const update = () => {
    const data = {
      user_id: userID
    };

    GetData(data, ApiData.getProile).then((res) => {
      if (res.resp['status'] === 200) {
        seteName(res.data['firstname']);
        seteLame(res.data['lastname']);
        seteLic(res.data['license_number']);
        seteMobo(res.data['phonenumber']);
        seteEmail(res.data['email']);
        const dateDbStr = res.data['dob'];
        if (dateDbStr) {
          const arrayDate = dateDbStr.split('/');
          if (arrayDate.length === 3) {
            const dateDb = new Date();
            dateDb.setDate(parseInt(arrayDate[0]));
            dateDb.setMonth(parseInt(arrayDate[1]) - 1);
            dateDb.setFullYear(parseInt(arrayDate[2]));
            setDob(dateDb);
          }
        }
        seteAddr(res.data['location']);
        setlatitude(res.data['latitude']);
        setlongitude(res.data['longitude']);
        setstreet(res.data['street1']);
        setcity(res.data['city']);
        setstate(res.data['state']);
        setcountry(res.data['country']);
        setpostalCode(res.data['postcode']);
      }
    });
  };

  useEffect(() => {
    update();
  }, []);

  const updateData = () => {
    let dateToSend = '';
    if (edob && isDate(edob)) {
      dateToSend =
        edob.getFullYear() + '-' + (edob.getMonth() + 1) + '-' + edob.getDate();
    }
    if (ename === '') {
      setError({ fname: true });
    } else if (elame === '') {
      setError({ lname: true });
    } else if (elic === '') {
      setError({ lic: true });
    } else if (emobo === '') {
      setError({ phone: true });
    } else if (eemail === '') {
      setError({ email: true });
    } else if (!edob || !dateToSend) {
      setError({ dob: true });
    } else if (eaddr === '') {
      setError({ addr: true });
    } else if (estreet1 === '') {
      setError({ Street: true });
    } else if (ecity === '') {
      setError({ City: true });
    } else if (estate === '') {
      setError({ State: true });
    } else if (ecountry === '') {
      setError({ Country: true });
    } else if (epostalCode === '') {
      setError({ PostalCode: true });
    } else {
      getMyIP().then((e) => {
        var data = {
          user_id: userID,
          firstname: ename,
          lastname: elame,
          image_path: '',
          dob: dateToSend,
          email: eemail,
          location: eaddr,
          latitude: elatitude,
          longitude: elongitude,
          license_number: elic,
          license_image: '',
          device_source: 'web',
          device_address: "114.143.70.86",
          street1: estreet1,
          city: ecity,
          state: estate,
          country: ecountry,
          postcode: epostalCode,
          hyperPayMerchantTransactionId: ''
        };

        saveData(data, ApiData.updateProile)
          .then((res) => {
            if (res.code === 200) {
              setsuccessMessage(res.data['response'].userMessage);
              seterrorMessage('');
              update();
            } else {
              seterrorMessage(res.data['response'].userMessage);
              setsuccessMessage('');
            }
          })
          .catch((res) => {});
      });
    }
  };

  const { ref: materialRef } = usePlacesWidget({
    apiKey: 'AIzaSyB8svhnyEyYYRYu9phI3afRxNjodA0eiYE',
    options: {
      types: 'geocode'
    },
    onPlaceSelected: (place) => {
      if (place) {
        place.address_components.length > 0 &&
          place.address_components.map(
            (
              item: { types: any[]; long_name: React.SetStateAction<string> },
              i: any
            ) => {
              return (
                item.types.length > 0 &&
                item.types.map((type: string, i: any) => {
                  if (type === 'locality') {
                    setstreet(item.long_name);
                  }
                  if (type === 'administrative_area_level_2') {
                    setcity(item.long_name);
                  }
                  if (type === 'administrative_area_level_1') {
                    setstate(item.long_name);
                  }
                  if (type === 'country') {
                    setcountry(item.long_name);
                  }
                  if (type === 'postal_code') {
                    setpostalCode(item.long_name);
                  } else {
                    setpostalCode('');
                  }
                })
              );
            }
          );
      }

      const latLng = {
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng()
      };
      const formattedAddress = place.formatted_address;
      seteAddr(place.formatted_address);
      setlatitude(place.geometry.location.lat());
      setlongitude(place.geometry.location.lng());
      dispatch(setLatLng({ latLng, formattedAddress }));
      if (!props.showFeatureSelection) {
        onSearchHandle();
      }
    }
  });

  const onSearchHandle = () => {
    const latitude = latLng.latitude;
    const longitude = latLng.longitude;
    dispatch(
      getSearchCarsListAsync({
        latitude,
        longitude
      })
    );
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab label='My Profile' {...a11yProps(0)} />
          {/* <Tab label="Account Settings" {...a11yProps(1)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Typography
          variant='body2'
          color='text.secondary'
          sx={{
            mb: 3
          }}
        >
          *All fields are mandatory.
        </Typography>
        {(errorMessage || successMessage) && (
          <Alert
            color={successMessage ? 'success' : 'error'}
            severity={successMessage ? 'success' : 'error'}
            sx={{
              mb: 3
            }}
          >
            {errorMessage || successMessage}
          </Alert>
        )}
        <Box
          component='form'
          sx={{
            '& > :not(style)': { m: 1, width: '47%' }
          }}
          noValidate
          autoComplete='off'
        >
          <TextField
            id='outlined-name'
            label='First Name'
            value={ename}
            onChange={(e) => {
              seteName(e.target.value);
              if (e.target.value !== '') {
                setError({ fname: false });
              }
            }}
            error={Error.fname === true}
            helperText={Error.fname === true ? 'First Name is require' : ' '}
          />
          <TextField
            id='outlined-name'
            label='Last Name'
            value={elame}
            onChange={(e) => {
              seteLame(e.target.value);
              if (e.target.value !== '') {
                setError({ lname: false });
              }
            }}
            error={Error.lname === true}
            helperText={Error.lname === true ? 'Last Name is require' : ' '}
          />
          <TextField
            id='outlined-name'
            label='Driving License ID'
            value={elic}
            onChange={(e) => {
              seteLic(e.target.value);
              if (e.target.value !== '') {
                setError({ lic: false });
              }
            }}
            error={Error.lic === true}
            helperText={Error.lic === true ? "Driving License ID is require" : " "}
          />
          <TextField
            id='outlined-name'
            label='Phone Number'
            type='phone'
            value={emobo}
            onChange={(e) => {
              seteMobo(e.target.value);
              if (e.target.value !== '') {
                setError({ phone: false });
              }
            }}
            error={Error.phone === true}
            helperText={Error.phone === true ? 'Mobile number is require' : ' '}
          />
          <TextField
            required
            id='outlined-name'
            label='Email'
            type='email'
            value={eemail}
            onChange={(e) => {
              seteEmail(e.target.value);
              if (e.target.value !== '') {
                setError({ email: false });
              }
            }}
            error={Error.email === true}
            helperText={Error.email === true ? 'Email is require' : ' '}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              inputFormat="dd/MM/yyyy"
              label="Date of birth"
              maxDate={new Date()}
              value={edob}
              onChange={(e) => {
                if (e) {
                  const newDate = new Date(e);
                  if (newDate) {
                    setDob(newDate);
                    setError({ dob: false });
                  }
                }
              }}
              renderInput={(params: any) => (
                <TextField
                  name='edob'
                  error={Error.dob === true}
                  helperText={
                    Error.dob === true ? 'Date of birth is require' : ' '
                  }
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <TextField
            id='outlined-name'
            label='Address'
            placeholder='Enter Location'
            inputRef={materialRef}
            value={eaddr}
            // defaultValue={formattedAddress}
            onChange={(e) => {
              seteAddr(e.target.value);
              if (e.target.value !== '') {
                setError({ addr: false });
              }
            }}
            error={Error.addr === true}
            helperText={Error.addr === true ? 'Address is require' : ' '}
          />
          <TextField
            id='outlined-name'
            label='Street'
            value={estreet1}
            onChange={(e) => {
              setstreet(e.target.value);
              if (e.target.value !== '') {
                setError({ Street: false });
              }
            }}
            error={Error.Street === true}
            helperText={Error.Street === true ? 'Street is require' : ' '}
          />
          <TextField
            id='outlined-name'
            label='City'
            value={ecity}
            onChange={(e) => {
              setcity(e.target.value);
              if (e.target.value !== '') {
                setError({ City: false });
              }
            }}
            error={Error.City === true}
            helperText={Error.City === true ? 'City is require' : ' '}
          />
          <TextField
            id='outlined-name'
            label='State'
            value={estate}
            onChange={(e) => {
              setstate(e.target.value);
              if (e.target.value !== '') {
                setError({ State: false });
              }
            }}
            error={Error.State === true}
            helperText={Error.State === true ? 'State is require' : ' '}
          />
          <TextField
            id='outlined-name'
            label='Country'
            value={ecountry}
            onChange={(e) => {
              setcountry(e.target.value);
              if (e.target.value !== '') {
                setError({ Country: false });
              }
            }}
            error={Error.Country === true}
            helperText={Error.Country === true ? 'Country is require' : ' '}
          />
          <TextField
            id='outlined-name'
            label='PostalCode'
            value={epostalCode}
            onChange={(e) => {
              setpostalCode(e.target.value);
              if (e.target.value !== '') {
                setError({ PostalCode: false });
              }
            }}
            error={Error.PostalCode === true}
            helperText={
              Error.PostalCode === true ? 'PostalCode is require' : ' '
            }
          />
        </Box>
        <Button
          onClick={(e) => {
            updateData();
          }}
          sx={{
            ...buttonRed,
            float: 'right'
          }}
        >
          Save
        </Button>
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        <Typography variant="body2" color="text.secondary">
          *All fields are mandatory.
        </Typography>
        {(errorMessage || successMessage) && (
          <Alert
            color={successMessage ? "success" : "error"}
            severity={successMessage ? "success" : "error"}
            sx={{
              mb: 3,
            }}
          >
            {errorMessage || successMessage}
          </Alert>
        )}
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "47%" },
          }}
          noValidate
          autoComplete="off"
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Age"
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Button
          sx={{
            ...buttonRed,
            float: "right",
          }}
        >
          Save
        </Button>
      </TabPanel> */}
    </Box>
  );
}
