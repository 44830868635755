import { useRef, useEffect } from 'react';

export function CheckoutPage(props) {
    useEffect(() => {
        const el = document.getElementById('payment-parent-div');

        const script = document.createElement("script");

        script.src = props.checkout.checkoutJsLink;
        script.async = true;

        document.body.appendChild(script);

        const form = document.createElement("form")
        form.action = props.checkout.formAction;
        form.setAttribute("class", "paymentWidgets");
        form.setAttribute("data-brands", props.checkout.dataBrands)
        el.append(form);
    }, []);

    return (
        <div id="payment-parent-div">
        </div>
    );
}