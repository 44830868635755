import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import AuthUserReducer from '../features/login/authSlice';
import carsReducer from '../features/carRental/cars/carsSlice';
import locationReducer from './../redux/locationSlice';
import corporateAuthReducer from '../features/corporate/login/corporateAuthSlice';
import selectedDropdownReducer from '../features/dropdowns/dropdownSlice';
import addCarInfoReducer from '../features/carRental/carListForms/slice/addCarInfoSlice';
import addKeySpecificationsReducer from '../features/carRental/carListForms/slice/addKeySpecificationsSlice';
import addTripInfoReducer from '../features/carRental/carListForms/slice/addTripInfoSlice';
import addInsuranceInfoReducer from '../features/carRental/carListForms/slice/addInsuranceInfoSlice';
import addFeaturesReducer from '../features/carRental/carListForms/slice/addFeaturesSlice';
import addCarPhotosReducer from '../features/carRental/carListForms/slice/addCarPhotosSlice';

export const store = configureStore({
  reducer: {
    AuthUser: AuthUserReducer,
    cars: carsReducer,
    location: locationReducer,
    corporateAuth: corporateAuthReducer,
    selectDropdown: selectedDropdownReducer,
    addKeySpecifications: addKeySpecificationsReducer,
    addCarInfo: addCarInfoReducer,
    addTripInfo: addTripInfoReducer,
    addInsuranceInfo: addInsuranceInfoReducer,
    addFeatures: addFeaturesReducer,
    addCarPhotos: addCarPhotosReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
