import axios from 'axios';

export async function saveData(param, url) {
  var data = {
    code: 0,
    status: true,
    msg: 'url: ' + url,
    data: [],
    resp: []
  };

  try {
    await axios
      .post(url, param, {
        headers: {
          'content-Type': 'application/json; charset=utf-8',
          'x-access-token': localStorage.getItem('token')
        }
      })
      .then((e) => {

        const respo = {
          code: e.status,
          status: true,
          msg: 'Urls: ' + url,
          data: e.data,
          resp: e
        };

        data = respo;

        return respo;
      })
      .catch((e) => {
        throw e;
      });
  } catch (err) {
    const respo = {
      code: e.status,
      status: false,
      msg: err,
      data: [],
      resp: []
    };

    return respo;
  }

  return data;
}

export async function getMyIP() {
  var data = '';
  const res = await axios
    .get('https://geolocation-db.com/json/')
    .then((e) => {
      data = e.data.IPv4;

      return data.response;
    })
    .catch((e) => {
      // alert(e);
    });
  return data;
}

export async function GetData(param, url) {
  var data = {
    code: 0,
    status: true,
    msg: 'url: ' + url,
    data: [],
    resp: []
  };

  try {
    await axios
      .post(url, param, {
        headers: {
          'content-Type': 'application/json; charset=utf-8',
          'x-access-token': localStorage.getItem('token')
        }
      })
      .then((e) => {

        const respo = {
          code: e.status,
          status: true,
          msg: 'Urls: ' + url,
          data: e.data.response.data,
          resp: e
        };

        data = respo;

        return respo;
      })
      .catch((e) => {
        throw e;
      });
  } catch (err) {
    const respo = {
      code: e.status,
      status: false,
      msg: err,
      data: [],
      resp: []
    };

    return respo;
  }

  return data;
}
