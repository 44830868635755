import { SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomSelect from '../../components/elements/select/Select';
import { getCarCategory } from '../carRental/cars/carsAPI';
import { DropdownSelector, selectDropdown } from './dropdownSlice';

export default function CarCategoryDropdown () {
  const [carCategoryOptions, setCarCategoryOptions] = useState<any[]>([]);
  const dispatch = useDispatch();
  const { selectedCategoryId, selectedMakeId, selectedModelId } = useSelector(DropdownSelector);

  useEffect(() => {
    getCarCategoryOptions();
  }, []);

  const getCarCategoryOptions = async () => {
    const options = await getCarCategory();
    if (options && options.data) {
      setCarCategoryOptions(options.data);
    }
  };
  const handleCategoryChange = (event: SelectChangeEvent) => {
    const selectedCategoryId = event?.target.value;
    dispatch(selectDropdown({ selectedCategoryId, selectedMakeId, selectedModelId }));
  };

  return <CustomSelect
        value={selectedCategoryId || ''}
        label="Car Category"
        onChange={handleCategoryChange}
        list={carCategoryOptions || []} />
}
