import config from "../util/config";

const baseURL = config.BASE_URL;

export const ApiData = {
  getProile: baseURL + "profile/details",
  updateProile: baseURL + "profile/update",
  getBankInfo: baseURL + "profile/bank/details",
  updateBank: baseURL + "profile/bank/update",
  getTrans: baseURL + "profile/transactions",
  contact: baseURL + "user/contact-admin",
  carDetails: baseURL + "car-details",
  travellerCheckout: baseURL + "traveller/checkout",
  addCarDetails: baseURL + "addCarDetails",
  bookcar: baseURL + "traveller/bookcar",
  pages: baseURL + "pages/",
  mycar: baseURL + "mycar",
  listed_cars: baseURL + "listed-cars",
  tripPast: baseURL + "trips/past",
  tripUpcoming: baseURL + "trips/upcoming",
  tripDetails: baseURL + "traveller/trip_details",
  start_trip: baseURL + "trips/start_trip",
  end_trip: baseURL + "trips/end_trip",
  cancel_trip: baseURL + "trips/cancel_trip",
};
