import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBW-80LBXvL5-WpTrAJz1VQn1j8jsRgxNE",
    authDomain: "sharik-11055.firebaseapp.com",
    databaseURL: "https://sharik-11055.firebaseio.com",
    projectId: "sharik-11055",
    storageBucket: "sharik-11055.appspot.com",
    messagingSenderId: "496450805185",
    appId: "1:496450805185:web:0d4ff0636ba90d34",
    measurementId: "G-EST52RD9KV"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export const auth = getAuth(app);
export { app, db, storage };