import {
  Box,
  Card,
  CardMedia,
  TextField,
  Button,
  Typography,
  Grid,
  Rating,
  ListItem,
  ListItemIcon,
  ListItemText,
  Alert,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Avatar, Divider } from "@mui/material";
import { buttonRed } from "../../../mui-sx/button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useParams } from "react-router-dom";
import { usePlacesWidget } from "react-google-autocomplete";
import { getLatLng } from "../../../redux/locationSlice";
import { useSelector } from "react-redux";
import { ApiData } from "../../../Api/url";
import { userIdSelectorSelector } from "../../login/authSlice";
import { CustomModal } from "../../../components/elements/modal/modal";
import CarPayment from "./car-payment";
import { ResponseInterface } from "../../../util/types";
import EventIcon from "@mui/icons-material/Event";
import {
  ApiCarCheckoutdata,
  CarBook,
  CarCheckOutData,
  CarData,
  CarDetail,
  CarFeaturesEntity,
  CarImage,
} from "../../../util/types/car";
import { CheckoutPage } from "./checkout";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";

export default function CarBooking() {
  const [errorMessage, seterrorMessage] = useState("");
  const [bookingData, setBookingData] = useState<CarCheckOutData | undefined>();
  const [payment, SetPayment] = useState<CarBook | undefined>();
  const [paymentDailog, setPaymentDailog] = useState<boolean>(false);
  const [checkoutFetch, setCheckoutFetch] = useState<boolean>(false);
  const { latLng, formattedAddress } = useSelector(getLatLng);

  const params = useParams();
  const userID = useSelector(userIdSelectorSelector);

  const [from_date, setfrom_date] = useState(new Date().toString());
  const [end_date, setend_date] = useState(new Date().toString());
  const [pickup, setpickup] = useState("");
  const [pickup_latitude, setpickup_latitude] = useState("");
  const [pickup_longitude, setpickup_longitude] = useState("");
  const [destination, setdestination] = useState("");
  const [destination_latitude, setdestination_latitude] = useState("");
  const [destination_longitude, setdestination_longitude] = useState("");
  const [rating, setRating] = React.useState<any>(0);

  const [carData, setCarData] = React.useState<CarData | null>(null);
  const [carImage, setCarImage] = React.useState<CarImage[] | null>(null);
  const [carFeaturesEntity, setCarFeaturesEntity] = React.useState<
    CarFeaturesEntity[] | null
  >(null);

  const [Error, setError] = useState({
    From_date: false,
    From_time: false,
    End_Time: false,
    End_date: false,
    Pickup: false,
    Destination: false,
  });

  useEffect(() => {
    const from_date_temp = new Date();
    from_date_temp.setHours(from_date_temp.getHours() + 4);
    setfrom_date(from_date_temp.toString());
    const end_date_temp = new Date(from_date_temp);
    if (carData?.duration_type == "hour") {
      end_date_temp.setHours(end_date_temp.getHours() + 1);
    }
    if (carData?.duration_type == "day") {
      end_date_temp.setDate(end_date_temp.getDate() + 1);
    }
    if (carData?.duration_type == "month") {
      end_date_temp.setMonth(end_date_temp.getMonth() + 1);
    }
    setend_date(end_date_temp.toString());
  }, [carData]);

  useEffect(() => {
    userID && params?.id && carDetails();
  }, [userID]);

  const carDetails = () => {
    const data = {
      car_id: params.id,
      user_id: userID,
    };
    axios
      .post(ApiData.carDetails, data, {
        headers: {
          "x-access-token": localStorage.getItem("token") || "",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res: ResponseInterface<CarDetail>) => {
        if (
          res.data.response.status === 200 &&
          res.data.response.data.carData
        ) {
          setCarData(res.data.response.data.carData);
          setCarFeaturesEntity(res.data.response.data.carFeatures);
          setCarImage(res.data.response.data.carPhotos);
          setpickup_latitude(res.data.response.data.carData.location_lat);
          setpickup_longitude(res.data.response.data.carData.location_lng);
          setpickup(res.data.response.data.carData.location);
          setRating(res.data.response.data["rating"]);
        } else {
        }
      })
      .catch((e) => {});
  };

  const { ref: materialRef } = usePlacesWidget<HTMLInputElement>({
    apiKey: "AIzaSyB8svhnyEyYYRYu9phI3afRxNjodA0eiYE",
    options: {
      types: "geocode",
    },
    onPlaceSelected: (place) => {
      setdestination_latitude(place.geometry.location.lat());
      setdestination(place.formatted_address);
      setdestination_longitude(place.geometry.location.lng());
    },
  });

  useEffect(() => {
    if (materialRef.current) {
      setdestination_latitude(latLng.latitude.toString());
      setdestination(formattedAddress);
      setdestination_longitude(latLng.longitude.toString());
      materialRef.current.value = formattedAddress;
    }
  }, [formattedAddress]);

  const { ref: materialRef2 } = usePlacesWidget({
    apiKey: "AIzaSyB8svhnyEyYYRYu9phI3afRxNjodA0eiYE",
    options: {
      types: "geocode",
    },
    onPlaceSelected: (place) => {
      setpickup_latitude(place.geometry.location.lat());
      setpickup_longitude(place.geometry.location.lng());
      setpickup(place.formatted_address);
    },
  });

  const TravellerCheckout = () => {
    if (from_date === "") {
      setError({ ...Error, From_date: true });
    } else if (end_date === "") {
      setError({ ...Error, End_date: true });
    } else if (pickup === "") {
      setError({ ...Error, Pickup: true });
    } else if (destination === "") {
      setError({ ...Error, Destination: true });
    } else if (destination !== "") {
      setError({ ...Error, Destination: false });
    }
    setCheckoutFetch(true);
    axios
      .post(
        ApiData.travellerCheckout,
        {
          car_id: params.id,
          user_id: userID,
          from_date: from_date,
          end_date: end_date,
          pickup: pickup,
          pickup_latitude: pickup_latitude,
          pickup_longitude: pickup_longitude,
          destination: destination,
          destination_latitude: destination_latitude,
          destination_longitude: destination_longitude,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("token") || "",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res: ResponseInterface<CarCheckOutData>) => {
        setCheckoutFetch(false);
        if (res.data.response.status === 200) {
          setBookingData(res.data.response.data);
        } else {
          seterrorMessage(res.data["response"].userMessage);
        }
      })
      .catch((e) => {
        setCheckoutFetch(false);
        console.log(e);
      });
  };

  const bookCar = () => {
    const end_dateend_date = new Date();
    end_dateend_date.setHours(23);
    axios
      .post(
        ApiData.bookcar,
        {
          car_id: params.id,
          user_id: userID,
          from_date: from_date,
          end_date: end_date,
          pickup: pickup,
          pickup_latitude: pickup_latitude,
          pickup_longitude: pickup_longitude,
          destination: destination,
          destination_latitude: destination_latitude,
          destination_longitude: destination_longitude,
          platform: "web",
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("token") || "",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res: ResponseInterface<CarBook>) => {
        SetPayment(res.data.response.data);
        setPaymentDailog(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const settings = {
    effect: "coverflow",
    centeredSlides: true,
    slidesPerView: 2,
    coverflowEffect: {
      rotate: 0, // Slide rotate in degrees
      stretch: 40, // Stretch space between slides (in px)
      depth: 300, // Depth offset in px (slides translate in Z axis)
      modifier: 1, // Effect multipler
      slideShadows: true, // Enables slides shadows
    },
  };

  const editCheckout = () => {
    setBookingData(undefined);
  };

  if (bookingData) {
    return (
      <>
        <CarPayment
          bookingData={bookingData}
          bookCar={bookCar}
          editCheckout={editCheckout}
          carimage={carImage?.[0]?.path}
        />
        <CustomModal
          open={paymentDailog}
          toggleModal={setPaymentDailog}
          sx={{
            width: "32%",
          }}
        >
          <Box
            sx={{
              pt: 2,
            }}
            textAlign={"center"}
          >
            <Box display={"flex"} flexDirection={"row"} justifyContent="center">
              {payment?.checkout && (
                <CheckoutPage checkout={payment.checkout} />
              )}
            </Box>
          </Box>
        </CustomModal>
      </>
    );
  }

  return (
    <div>
      <Box
        mt={18}
        sx={{ position: "relative" }}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Box
          display={"flex"}
          sx={{ width: "97%" }}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexDirection={"row"}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"start"}
            flexDirection={"column"}
          >
            <Box>
              <Typography ml={2} variant="h5" sx={{ color: "black" }}>
                {carData?.car_make_value} {carData?.car_model_value}{" "}
                {carData?.car_category_value}
              </Typography>
            </Box>
            <Box>
              <Typography ml={2} variant="h6" sx={{ color: "black" }}>
                {carData?.min_charges && carData?.duration_parameter
                  ? "SAR " +
                    carData?.min_charges
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                    "/" +
                    carData?.duration_parameter
                  : ""}
              </Typography>
              {/* <Chip
                style={{
                  backgroundColor: 'rgb(35 213 23 / 35%',
                  marginLeft: '19px'
                }}
                label='save 30%'
                icon={<LocalOfferOutlinedIcon fontSize='small' />}
              /> */}
            </Box>
          </Box>

          <Box>
            <Rating
              name="read-only"
              value={rating ? Number(rating) : 5}
              readOnly
            />
            <Typography ml={2} variant="h6" sx={{ color: "black" }}>
              {/* 12 trips */}
              {carData ? `${carData.trip_type} trips` : ""}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ width: "95%" }} mt={3}>
          <Divider></Divider>
        </Box>
        <Box
          m={2}
          sx={{ width: "98%" }}
          display={"flex"}
          justifyContent={"space-around"}
          alignItems={"center"}
          flexDirection={"row"}
        >
          {carImage && carImage.length > 0 && (
            <Grid container>
              <Grid item xs={6}>
                <CardMedia
                  sx={{
                    marginLeft: "10px",
                    marginTop: "10px",
                    display: "flex",
                    borderTopLeftRadius: 20,
                    borderBottomLeftRadius: 20,
                    height: 540,
                    width: "98%",
                    border: "1px solid gray",
                  }}
                  component={"img"}
                  src={carImage[0].path}
                ></CardMedia>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Grid container spacing={2}>
                    {carImage.map((item, i) => {
                      return (
                        i != 0 &&
                        (i % 2 != 0 ? (
                          <Grid item xs={6}>
                            <CardMedia
                              sx={{
                                margin: "10px",
                                height: 230,
                                border: "1px solid gray",
                              }}
                              component={"img"}
                              src={item.path}
                            ></CardMedia>
                          </Grid>
                        ) : (
                          <Grid item xs={6}>
                            <CardMedia
                              sx={{
                                margin: "10px",
                                borderTopRightRadius: 15,
                                borderBottomRightRadius: 15,
                                height: 230,
                                border: "1px solid gray",
                              }}
                              component={"img"}
                              src={item.path}
                            ></CardMedia>
                            {/* <Button
                              sx={{
                                backgroundColor: "#fff !important",
                                borderRadius: "13px",
                                marginTop: "-11%",
                                marginLeft: "81%",
                                marginBottom: "2%",
                                padding: "11px",
                                width: "max-content",
                              }}
                              onClick={OpenSwiper}
                            >
                              Show all photos
                            </Button> */}
                          </Grid>
                        ))
                      );
                    })}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
        <Box
          sx={{ width: "99%" }}
          display={"flex"}
          justifyContent={"space-around"}
          alignItems={"start"}
          flexDirection={"row"}
          m={4}
        >
          <Box sx={{ width: "45%" }}>
            <Typography variant="h4" sx={{ color: "black" }}>
              Features
            </Typography>
            <Box
              sx={{ marginRight: 40, mt: 1 }}
              display={"flex"}
              alignItems={"center"}
              flexDirection={"row"}
            >
              {carFeaturesEntity?.map(
                (feature) =>
                  feature && (
                    <ListItem
                      sx={{
                        display: "inline-block",
                        width: "auto",
                      }}
                      key={feature.icon.path_prefix}
                    >
                      <ListItemIcon
                        sx={{
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            width: "30px",
                          }}
                          src={
                            feature.icon.path_prefix +
                            "" +
                            feature.icon?.types?.[0]?.value
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          textAlign: "center",
                        }}
                        primary={feature.car_feature}
                      />
                    </ListItem>
                  )
              )}
            </Box>
            <Box>
              <Typography
                variant="h4"
                sx={{ color: "black", fontSize: "24px" }}
              >
                Details
              </Typography>
              <Box
                mt={4}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"start"}
                flexDirection={"row"}
              >
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ color: "black", fontSize: "16px" }}
                  >
                    Trip Duration <br />
                    min-max
                  </Typography>
                </Box>
                <Box>
                  <b>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {carData?.duration_min_value} to{" "}
                      {carData?.duration_max_value} {carData?.duration_type}
                    </Typography>
                  </b>
                </Box>
              </Box>

              <Box sx={{ width: "100%" }} mt={3}>
                <Divider></Divider>
              </Box>

              <Box
                mt={4}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"start"}
                flexDirection={"row"}
              >
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ color: "black", fontSize: "16px" }}
                  >
                    Minimum Insurance Claim <br />
                    Deposit to owner
                  </Typography>
                </Box>
                <Box>
                  <b>
                    {" "}
                    <Typography
                      variant="body2"
                      sx={{
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {carData?.min_charges
                        ? "SR " +
                          carData?.min_charges
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "-"}
                    </Typography>
                  </b>
                </Box>
              </Box>

              <Box sx={{ width: "100%" }} mt={3}>
                <Divider></Divider>
              </Box>

              <Box
                mt={4}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"start"}
                flexDirection={"row"}
              >
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ color: "black", fontSize: "16px" }}
                  >
                    Rental Insurance By
                  </Typography>
                </Box>
                <Box>
                  <b>
                    {" "}
                    <Typography
                      variant="body2"
                      sx={{
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {carData?.rental_rate}
                    </Typography>{" "}
                  </b>
                </Box>
              </Box>

              <Box
                mt={4}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"start"}
                flexDirection={"row"}
              >
                <Box>
                  <Typography
                    variant="h5"
                    sx={{ color: "black", fontSize: "16px" }}
                  >
                    Owner
                  </Typography>

                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"start"}
                    flexDirection={"row"}
                  >
                    {/* {carData?.owner_image && (
                      <Avatar
                        alt={carData?.owner_name || ""}
                        src={carData.owner_image}
                      />
                    )} */}
                    {carData?.owner_image !== null ? (
                      <Avatar
                        alt={carData?.owner_name || ""}
                        src={carData?.owner_image}
                      />
                    ) : (
                      <AccountCircleIcon
                        sx={{
                          color: "#00000091",
                          fontSize: 50,
                          display: "block",
                          // margin: "auto",
                        }}
                      />
                    )}
                    <Typography mt={2} ml={2} sx={{ color: "black" }}>
                      {carData?.owner_name}
                    </Typography>
                  </Box>
                </Box>
                <Box></Box>
              </Box>
            </Box>
          </Box>
          {/* 
          <Box
            m={3}
            sx={{
              width: "27%",
              boxShadow:
                "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
              position: "relative",
              borderRadius: "10px",
            }}
          > */}
          <Box
            m={3}
            sx={{
              boxShadow: "none",
              width: "27%",
            }}
          >
            <Card
              sx={{
                padding: "20px",
                boxShadow:
                  "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
              }}
            >
              {errorMessage && (
                <Alert
                  color={"error"}
                  severity={"error"}
                  sx={{
                    mb: 2,
                  }}
                >
                  {errorMessage}
                </Alert>
              )}
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  ml={2}
                  variant="h6"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  {carData?.min_charges && carData?.duration_parameter
                    ? "SAR " +
                      carData?.min_charges
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                      "/" +
                      carData?.duration_parameter
                    : ""}
                </Typography>
                <Typography
                  ml={2}
                  variant="h6"
                  sx={{
                    fontSize: "20px",
                    color: "black",
                    alignItems: "center",
                    textDecorationLine: "line-through",
                  }}
                >
                  {carData?.rental_rate && carData?.duration_type
                    ? "SAR " +
                      carData?.rental_rate +
                      "/" +
                      carData?.duration_type
                    : ""}
                </Typography>
              </Box>
              <Box m={2}>
                <Typography
                  variant="h6"
                  sx={{ marginBottom: "10px !important", color: "black" }}
                >
                  Start Trip
                </Typography>
                <Box
                  display={"flex"}
                  sx={{
                    "& > :not(style)": { mr: 1, width: "100%" },
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDateTimePicker
                      value={from_date}
                      onChange={(newValue: any) => {
                        setfrom_date(newValue);
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          sx={{
                            "& > :not(style)": {
                              borderRadius: "10px",
                            },
                          }}
                          placeholder="Enter your Start Trip Date"
                          {...params}
                          InputProps={{
                            endAdornment: <EventIcon />,
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box m={2}>
                <Typography
                  variant="h6"
                  sx={{ marginBottom: "10px !important", color: "black" }}
                >
                  End Trip
                </Typography>
                <Box
                  sx={{
                    "& > :not(style)": { mr: 1, width: "100%" },
                  }}
                  display={"flex"}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDateTimePicker
                      value={end_date}
                      onChange={(newValue: any) => {
                        setend_date(newValue);
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          sx={{
                            "& > :not(style)": {
                              borderRadius: "10px",
                            },
                          }}
                          id="outlined-name"
                          placeholder="Enter your End Trip Date"
                          {...params}
                          InputProps={{
                            endAdornment: <EventIcon />,
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box m={2}>
                <Typography variant="h6" sx={{ color: "black" }}>
                  Pick and Drop
                </Typography>
                <Box sx={{ width: "98%" }}>
                  <TextField
                    id="inlined-name"
                    variant="outlined"
                    style={{ width: "100%", marginTop: "15px" }}
                    sx={{
                      "& > :not(style)": { borderRadius: "10px" },
                    }}
                    placeholder="Enter Location"
                    inputRef={materialRef2}
                    value={pickup}
                    error={Error.Pickup === true}
                    helperText={
                      Error.Pickup === true ? "pickup is require" : " "
                    }
                  ></TextField>
                </Box>
              </Box>
              <Box m={2}>
                <Typography variant="h6" sx={{ color: "black" }}>
                  Destination
                </Typography>
                <Box sx={{ width: "98%" }}>
                  <TextField
                    id="inlined-name"
                    variant="outlined"
                    inputRef={materialRef}
                    // value={destination}
                    style={{ width: "100%", marginTop: "15px" }}
                    sx={{
                      "& > :not(style)": { borderRadius: "10px" },
                    }}
                    placeholder="Enter Location"
                    error={Error.Destination === true}
                    helperText={
                      Error.Destination === true
                        ? "Destination is require"
                        : " "
                    }
                  ></TextField>
                </Box>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"row"}
              >
                <LoadingButton
                  onClick={TravellerCheckout}
                  loading={checkoutFetch}
                  loadingIndicator="Getting info..."
                  sx={{
                    ...buttonRed,
                    width: "90%",
                  }}
                >
                  Book Now
                </LoadingButton>
              </Box>
              <Box
                m={3}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"start"}
                flexDirection={"row"}
              >
                <Box>
                  <a
                    href="/terms-conditions"
                    style={{ textDecoration: "auto", color: "black" }}
                  >
                    Teams and Condition
                  </a>
                </Box>
                <Box>
                  <a
                    href="/cancellation-policy"
                    style={{ textDecoration: "auto", color: "black" }}
                  >
                    Cancellation & refund policy
                  </a>
                </Box>
              </Box>
              <Box></Box>
            </Card>
          </Box>
          {/* </Box> */}
        </Box>
      </Box>
    </div>
  );
}
