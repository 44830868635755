import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonProps,
  Card,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { usePlacesWidget } from "react-google-autocomplete";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import {
  getSearchCarsListAsync,
  setSearchDate,
} from "../../../features/carRental/cars/carsSlice";
import { getLatLng, setLatLng } from "../../../redux/locationSlice";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

export function SwitchBar(props: { showFeatureSelection?: boolean }) {
  const d = new Date();
  d.setDate(d.getDate() + 1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState<any>(new Date());
  const [toDate, setToDate] = useState<any>(d);
  const { latLng, formattedAddress } = useSelector(getLatLng);
  
  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "#fff",
    marginLeft: "20px",
    padding: "12px",
    backgroundColor: "#E85656",
    "&:hover": {
      backgroundColor: "#E85656",
    },
  }));

  const { ref: materialRef } = usePlacesWidget<HTMLInputElement>({
    apiKey: "AIzaSyB8svhnyEyYYRYu9phI3afRxNjodA0eiYE",
    options: {
      types: "geocode",
    },
    onPlaceSelected: (place) => {
      const latLng = {
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      };
      const formattedAddress = place.formatted_address;
      dispatch(setLatLng({ latLng, formattedAddress }));
      if (!props.showFeatureSelection) {
        onSearchHandle();
      }
    },
  });

  useEffect(() => {
    if (materialRef.current) {
      materialRef.current.value = formattedAddress;
    }
  }, [formattedAddress]);

  const onSearchHandle = () => {
    // TO DO remove
    const from_date = fromDate;
    const end_date = toDate;
    const user_id = "";
    const latitude = latLng.latitude;
    const longitude = latLng.longitude;
    dispatch(
      getSearchCarsListAsync({
        latitude,
        longitude,
        from_date,
        end_date,
        user_id,
      })
    );
    dispatch(setSearchDate({ fromDate, toDate }));
    navigate("/car-rental/list");
  };

  return (
    <>
      <Card
        sx={{
          p: 1.5,
          pt: props.showFeatureSelection ? 3.5 : 1.5,
          borderRadius: "10px",
          border: "1px solid #EAEAEA",
        }}
      >
        <Grid container>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2">Where you want to go?</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="Enter Location"
                InputProps={{
                  disableUnderline: true,
                }}
                inputRef={materialRef}
              ></TextField>
            </Grid>
            <Grid item xs={props.showFeatureSelection ? 2.5 : 3}>
              <Typography variant="body2">From</Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDateTimePicker
                  InputProps={{
                    disableUnderline: true,
                  }}
                  value={fromDate}
                  onChange={(newValue: any) => {
                    setFromDate(newValue);
                  }}
                  renderInput={(params: any) => (
                    <TextField variant="standard" {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={props.showFeatureSelection ? 2.5 : 3}>
              <Typography variant="body2">Until</Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDateTimePicker
                  InputProps={{
                    disableUnderline: true,
                  }}
                  value={toDate}
                  onChange={(newValue: any) => {
                    setToDate(newValue);
                  }}
                  renderInput={(params: any) => (
                    <TextField variant="standard" {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            {props.showFeatureSelection && (
              <Grid item xs={1}>
                <ColorButton variant="contained" onClick={onSearchHandle}>
                  <ArrowForwardRoundedIcon />
                </ColorButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Card>
      {props.showFeatureSelection && (
        <Card
          sx={{
            maxWidth: 500,
            mx: "auto",
            p: 1,
            mt: -18,
            borderRadius: "10px",
          }}
          style={{
            boxShadow: "0px 3px 6px #00000029",
          }}
        >
          <Grid container>
            <Grid container item spacing={2} textAlign={"center"}>
              <Grid
                item
                xs={3}
                sx={{
                  color: "#E85656",
                }}
              >
                <IconButton color="inherit">
                  <DirectionsCarFilledOutlinedIcon />
                </IconButton>
                <Typography noWrap variant="body2">
                  Car Rental
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <IconButton color="inherit">
                  <ShoppingBagOutlinedIcon />
                </IconButton>
                <Typography noWrap variant="body2">
                  Saied
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <IconButton color="inherit">
                  <KeyOutlinedIcon />
                </IconButton>
                <Typography noWrap variant="body2">
                  Buy/Sell
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <IconButton color="inherit">
                  <CottageOutlinedIcon />
                </IconButton>
                <Typography noWrap variant="body2">
                  Home Rental
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
}
