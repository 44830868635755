import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CarList } from '../features/corporate/add-car/carlist';
import AddBranch from '../features/corporate/branches/add-branch/add-branch';
import { Branches } from '../features/corporate/branches/list/branches';
import { Dashboard } from '../features/corporate/dashboard/dashboard';
import { userRoleSelectorSelector } from '../features/corporate/login/corporateAuthSlice';
import { CorporateLogin } from '../features/corporate/login/CorporateLogin';
import CarInfo from '../features/corporate/my-car-details/car-info';
import MyCars from '../features/corporate/my-cars/myCars';
import UpcomingTrips from '../features/corporate/trips/upcoming-trips';
import Trips from '../features/corporate/trips/trips';
import { CorporateForgot } from '../features/corporate/forgot-password/forgot';

export default function SharikCorporateRoutes({ isAuth }: { isAuth: boolean }) {
  const userRole = useSelector(userRoleSelectorSelector);

  return (
    <BrowserRouter>
      <Routes>
        {userRole && (
          <>
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='cars' element={<MyCars />} />
            <Route path='upcoming-trips' element={<UpcomingTrips />} />
            <Route path='trips' element={<Trips />} />
            <Route path='car/:car_id' element={<CarInfo />} />
            <Route path='car/:car_id/:user_id' element={<CarInfo />} />
            {userRole === 6 && <Route path='add-car' element={<CarList />} />}
            {userRole === 5 && <Route path='branches' element={<Branches />} />}
            {userRole === 5 && (
              <Route path='add-branch' element={<AddBranch />} />
            )}
          </>
        )}
        <Route path='forgot' element={<CorporateForgot />} />
        <Route
          index
          element={
            isAuth || localStorage.getItem('token') ? (
              <Dashboard />
            ) : (
              <CorporateLogin />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
