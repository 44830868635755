import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CardMedia,
  Divider,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { GetData } from "../../../Api/ApiMgr";
import { ApiData } from "../../../Api/url";
import { userIdSelectorSelector } from "../../login/authSlice";
import { useSelector } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Swiper, SwiperSlide } from "swiper/react";
import { CustomModal } from "../../../components/elements/modal/modal";
import { Autoplay, Pagination, Navigation } from "swiper";
import CloseIcon from "@mui/icons-material/Close";
import dummyCar from "./../../../assets/img/dummy.png";

function MyCars() {
  const idRef = React.useRef("");
  const [name, setName] = useState("VehicalSummary");
  const [type, setType] = useState("active");
  const [listedCars, setListedCars] = React.useState<any>([]);
  const [myCarData, setMyCarData] = React.useState<any>("");
  const [openCarSlider, setOpenCarSlider] = React.useState<boolean>(false);
  const userID = useSelector(userIdSelectorSelector);

  useEffect(() => {
    // userID !== undefined && listed_cars && Mycar();
    listed_cars(type);
  }, [userID]);

  const Mycar = (id: any) => {
    var myCarData = {
      car_id: id,
      user_id: userID,
    };

    GetData(myCarData, ApiData.mycar).then((res) => {
      setMyCarData(res.data);
      setName("VehicalSummary");
    });
  };

  const listed_cars = (type: any) => {
    setType(type);
    if (userID !== undefined) {
      var data = {
        user_id: userID,
        type: type,
      };
      GetData(data, ApiData.listed_cars).then((res) => {
        setListedCars(res.data);
        handleMyCars(res?.data?.[0]?.["car_id"]);
      });
    }
  };

  const handleMyCars = (id: any) => {
    Mycar(id);
    idRef.current = id;
  };

  const handleChange2 = (event: React.SyntheticEvent, newValue: string) => {
    setType(newValue);
  };

  const handleChange3 = (event: React.SyntheticEvent, newValue: string) => {
    setName(newValue);
  };

  return (
    <Box
      sx={{
        marginTop: "110px",
        width: "96%",
        marginLeft: "2%",
        marginRight: "2%",
      }}
    >
      <Grid
        container
        spacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        margin={"10px"}
        justifyItems={"center"}
        justifyContent={"center"}
      >
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Box
            sx={{
              border: "0.001rem solid #8080803d",
              borderRadius: "15px",
              height: "100%",
              position: "relative",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: "25px", margin: "15px" }}>
                My Cars
              </Typography>
            </Box>

            <TabContext value={type}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange2}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Active"
                    value="active"
                    onClick={() => listed_cars("active")}
                  />
                  <Tab
                    label="Inactive"
                    value="inactive"
                    onClick={() => listed_cars("inactive")}
                  />
                  <Tab
                    label="Favorite"
                    value="favorite"
                    onClick={() => listed_cars("favorite")}
                  />
                </TabList>
              </Box>
              <TabPanel value={type}>
                {type && (
                  <>
                    <Box
                      className="mostly-customized-scrollbar"
                      sx={{
                        borderRadius: "15px",
                        color: "black",
                      }}
                    >
                      <Grid container>
                        {listedCars.length > 0 ? (
                          listedCars.map((item: any, i: any) => {
                            return (
                              <Box
                                onClick={() => handleMyCars(item.car_id)}
                                sx={{
                                  width: "inherit",
                                  display: "flex",
                                  borderRadius: 2,
                                  cursor: "pointer",
                                }}
                                key={i}
                                className={`myCars${item.car_id}`}
                                style={{
                                  background: `${
                                    item?.car_id === idRef.current
                                      ? "#E85656"
                                      : "white"
                                  }`,
                                  color: `${
                                    item?.car_id === idRef.current
                                      ? "white"
                                      : "black"
                                  }`,
                                }}
                              >
                                <Grid item lg={4} md={6} sm={12} xs={12}>
                                  <CardMedia
                                    sx={{
                                      margin: "15px",
                                      objectFit: "cover",
                                      display: "flex",
                                      borderRadius: 2,
                                      height: "70px",
                                      maxWidth: "100px",
                                    }}
                                    component={"img"}
                                    src={item.path || dummyCar}
                                  />
                                </Grid>
                                <Grid item lg={8} md={6} sm={12} xs={12}>
                                  <Box sx={{ margin: "15px" }} key={i}>
                                    <Typography>
                                      {item.car_make} {item.car_model}
                                    </Typography>
                                    <Typography>{item.purchased_on}</Typography>
                                    <Typography>
                                      {item.code} {item.rental_rate}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Box>
                            );
                          })
                        ) : (
                          <Box sx={{ margin: "auto" }}>
                            <HighlightOffIcon
                              sx={{
                                color: "#00000091",
                                fontSize: 50,
                                display: "block",
                                margin: "auto",
                              }}
                            />
                            <Typography
                              sx={{
                                color: "#00000091",
                                fontSize: "22px",
                                textAlign: "center",
                              }}
                            >
                              No Active Cars
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                    </Box>
                  </>
                )}
              </TabPanel>
            </TabContext>
            <Box sx={{ position: "absolute", right: "20px", bottom: "15px" }}>
              <a href="/car-rental/add-car">
                <AddCircleIcon sx={{ color: "#E85656", fontSize: 50 }} />
              </a>
            </Box>
          </Box>
        </Grid>
        {myCarData && listedCars?.length > 0 ? (
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Box
              sx={{
                border: "0.001rem solid #8080803d",
                borderRadius: "18px",
                padding: 3,
                justifyContent: "space-around",
              }}
            >
              <Box>
                <Grid container>
                  {myCarData && (
                    <Grid item xs={4}>
                      <Typography sx={{ fontSize: "22px", margin: "20px" }}>
                        {myCarData["carSummary"].car_make}{" "}
                        {myCarData["carSummary"].car_model}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={8}>
                    <Box
                      sx={{
                        margin: "20px",
                        justifyContent: "end",
                        display: "flex",
                      }}
                    >
                      <Button
                        sx={{
                          fontSize: "14px",
                          borderRadius: "50px",
                          color: "black",
                          backgroundColor: "#93eb8d !important",
                        }}
                      >
                        Active
                      </Button>
                      {myCarData && myCarData["carPhotos"].length > 0 ? (
                        <Button
                          sx={{ fontSize: "18px", color: "#E85656" }}
                          onClick={() => setOpenCarSlider(true)}
                        >
                          View Photos
                        </Button>
                      ) : undefined}
                      <Button sx={{ fontSize: "18px", color: "#E85656" }}>
                        Edit Car
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {myCarData && (
                <TabContext value={name}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange3}
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        label="Vehical Summary"
                        value="VehicalSummary"
                        onClick={() => setName("VehicalSummary")}
                      />
                      <Tab
                        label="Key Specifications"
                        value="KeySpecifications"
                        onClick={() => setName("KeySpecifications")}
                      />
                      <Tab
                        label="Trip Details"
                        value="TripDetails"
                        onClick={() => setName("TripDetails")}
                      />
                      <Tab
                        label="Insurance Details"
                        value="InsuranceDetails"
                        onClick={() => setName("InsuranceDetails")}
                      />
                      <Tab
                        label="Features"
                        value="Features"
                        onClick={() => setName("Features")}
                      />
                    </TabList>
                  </Box>
                  <TabPanel value={name}>
                    {name && (
                      <>
                        <Box
                          sx={{
                            // display: "flex",
                            // backgroundColor: "#E85656",
                            borderRadius: "15px",
                            color: "black",
                            cursor: "context-menu",
                            // color: "white",
                          }}
                        >
                          <Grid container>
                            {name === "VehicalSummary" && (
                              <Box sx={{ width: "inherit" }}>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>Car Location</Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {myCarData["carSummary"].location}
                                  </Typography>
                                </Box>
                                <Divider sx={{ width: "100%" }}></Divider>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>Purchase Year</Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {myCarData["carSummary"].purchased_on}
                                  </Typography>
                                </Box>
                                <Divider sx={{ width: "100%" }}></Divider>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>Car Category</Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {myCarData["carSummary"].car_category}
                                  </Typography>
                                </Box>
                                <Divider sx={{ width: "100%" }}></Divider>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>Car Make</Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {myCarData["carSummary"].car_make}
                                  </Typography>
                                </Box>
                                <Divider sx={{ width: "100%" }}></Divider>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>Car Modal</Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {myCarData["carSummary"].car_model}
                                  </Typography>
                                </Box>
                                <Divider sx={{ width: "100%" }}></Divider>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>Odometer Reading</Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {myCarData["carSummary"].odometer}
                                  </Typography>
                                </Box>
                                <Divider sx={{ width: "100%" }}></Divider>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>Transmission</Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {myCarData["carSummary"].transmission}
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                            {name === "KeySpecifications" && (
                              <Box sx={{ width: "inherit" }}>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>
                                    Owner's National Identity Number
                                  </Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {
                                      myCarData["keySpecification"]
                                        .identity_number
                                    }
                                  </Typography>
                                </Box>
                                <Divider sx={{ width: "100%" }}></Divider>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>
                                    Date Of Birth In Hijri
                                  </Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {myCarData["keySpecification"].dob}
                                  </Typography>
                                </Box>
                                <Divider sx={{ width: "100%" }}></Divider>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>Vehicale Plate Number</Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {myCarData["keySpecification"].plate_number}
                                  </Typography>
                                </Box>
                                <Divider sx={{ width: "100%" }}></Divider>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>Plate Type</Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {myCarData["keySpecification"].plate_type}
                                  </Typography>
                                </Box>
                                <Divider sx={{ width: "100%" }}></Divider>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>
                                    Vehical Sequence Number
                                  </Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {
                                      myCarData["keySpecification"]
                                        .sequence_number
                                    }
                                  </Typography>
                                </Box>
                                <Divider sx={{ width: "100%" }}></Divider>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>
                                    Vehicale Plate Left Number
                                  </Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {
                                      myCarData["keySpecification"]
                                        .plate_letter_left
                                    }
                                  </Typography>
                                </Box>
                                <Divider sx={{ width: "100%" }}></Divider>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>
                                    Vehicale Plate Middle Number
                                  </Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {
                                      myCarData["keySpecification"]
                                        .plate_letter_middle
                                    }
                                  </Typography>
                                </Box>
                                <Divider sx={{ width: "100%" }}></Divider>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>
                                    Vehicale Plate Middle Number
                                  </Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {
                                      myCarData["keySpecification"]
                                        .plate_letter_right
                                    }
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                            {name === "TripDetails" && (
                              <Box sx={{ width: "inherit" }}>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>Tripe Type</Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {myCarData["rentalInfo"].trip_type}
                                  </Typography>
                                </Box>
                                {/* <Divider sx={{ width: "100%" }}></Divider>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>
                                    Avaiblity for instant Booking
                                  </Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    15/05/2021
                                  </Typography>
                                </Box> */}
                                <Divider sx={{ width: "100%" }}></Divider>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>Price / day</Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {myCarData["rentalInfo"].currency_code}{" "}
                                    {myCarData["rentalInfo"].rental_rate} /{" "}
                                    {myCarData["rentalInfo"].duration_parameter}
                                  </Typography>
                                </Box>
                                <Divider sx={{ width: "100%" }}></Divider>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>Tripe Duration</Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {myCarData["rentalInfo"].duration_min_value}{" "}
                                    to{" "}
                                    {myCarData["rentalInfo"].duration_max_value}{" "}
                                    {myCarData["rentalInfo"].duration_parameter}
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                            {name === "InsuranceDetails" && (
                              <Box sx={{ width: "inherit" }}>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>Insuarance Provider</Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {myCarData["insuranceInfo"]?.provider}
                                  </Typography>
                                </Box>
                                <Divider sx={{ width: "100%" }}></Divider>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>Policy Number</Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {myCarData["insuranceInfo"]?.policy_number}
                                  </Typography>
                                </Box>
                                <Divider sx={{ width: "100%" }}></Divider>
                                <Box sx={{ margin: "20px" }}>
                                  <Typography>
                                    Minimum Charges for Any Claim
                                  </Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {myCarData["insuranceInfo"]?.currency_code}{" "}
                                    {myCarData["insuranceInfo"]?.min_charges}
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                            {name === "Features" && (
                              <Box>
                                <Box sx={{ margin: "20px" }}>
                                  {myCarData["carFeatures"]?.map(
                                    (feature: any) =>
                                      feature && (
                                        <ListItem
                                          sx={{
                                            display: "inline-block",
                                            width: "auto",
                                          }}
                                          key={feature.icon.path_prefix}
                                        >
                                          <ListItemIcon
                                            sx={{
                                              justifyContent: "center",
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "30px",
                                              }}
                                              src={
                                                feature.icon.path_prefix +
                                                "" +
                                                feature.icon?.types?.[0]?.value
                                              }
                                            />
                                          </ListItemIcon>
                                          <ListItemText
                                            sx={{
                                              textAlign: "center",
                                            }}
                                            primary={feature.car_feature}
                                          />
                                        </ListItem>
                                      )
                                  )}
                                </Box>
                              </Box>
                            )}
                          </Grid>
                        </Box>
                      </>
                    )}
                  </TabPanel>
                </TabContext>
              )}
            </Box>
          </Grid>
        ) : (
          <Box sx={{ margin: "auto" }}>
            <HighlightOffIcon
              sx={{
                color: "#00000091",
                fontSize: 50,
                display: "block",
                margin: "auto",
              }}
            />
            <Typography
              sx={{
                color: "#00000091",
                fontSize: "22px",
                textAlign: "center",
              }}
            >
              Please select Car
            </Typography>
          </Box>
        )}
      </Grid>

      {myCarData && myCarData["carPhotos"].length > 0 && (
        <CustomModal open={openCarSlider} toggleModal={setOpenCarSlider}>
          <Grid container sx={{ position: "relative" }}>
            <CloseIcon
              sx={{
                // color: "#00000091",
                // backgroundColor:"gray",
                fontSize: 50,
                display: "block",
                width: "0.51em",
                top: "0px",
                position: "right",
                marginTop: "0px",
                marginRight: "0px",
                marginLeft: "94%",
              }}
              onClick={() => setOpenCarSlider(false)}
            />
            <Grid item xs={12}>
              <Box>
                <Swiper
                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                >
                  {myCarData["carPhotos"].length > 0 ? (
                    myCarData["carPhotos"]?.map((item: any, i: any) => (
                      <SwiperSlide key={i}>
                        <img src={item.path ? item.path : ""} />
                      </SwiperSlide>
                    ))
                  ) : (
                    <SwiperSlide>
                      <img src={"Not Found Images ..."} />
                    </SwiperSlide>
                  )}
                </Swiper>
              </Box>
            </Grid>
          </Grid>
        </CustomModal>
      )}
    </Box>
  );
}

export default MyCars;
