import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useEffect, useState } from 'react';
import CarBox from './car-card';
import { useDispatch, useSelector } from 'react-redux';
import {
  carsSelector,
  getCarsList,
  getSearchCarsListAsync
} from '../cars/carsSlice';
import CircularProgress from '@mui/material/CircularProgress';
import {
  CarListMap,
  CarListMapPointer
} from '../../../components/elements/map/carListMap';
import { getCarCategory, getCarMake } from './carsAPI';
import { getLatLng } from '../../../redux/locationSlice';
// import CustomSelect from '../../../components/elements/select/Select';
import CarCategoryDropdown from '../../dropdowns/carCategoryDropdown';
import CarMakeDropdown from '../../dropdowns/carMakeDropdown';
import CarModelDropdown from '../../dropdowns/carModelDropdown';
import TripDurationDropdown from '../../dropdowns/tripDurationDropdown';
import { DropdownSelector } from '../../dropdowns/dropdownSlice';

export function Cars () {
  const dispatch = useDispatch();
  const [pointers, setpointers] = useState<CarListMapPointer[]>([]);
  const { selectedCategoryId, selectedMakeId, selectedModelId, selectedTripDuration } = useSelector(DropdownSelector);
  const [selectedTripType, setSelectedTripType] = useState<string>('');
  // const [selectedTripDuration, setSelectedTripDuration] = useState<string>('');
  // const [setCarMakeOptions] = useState<any[]>([]);
  // const [carModelOptions, setCarModelOptions] = useState<any[]>([]);
  const carList = useSelector(getCarsList);
  const { isFetching, fromDate, endDate } = useSelector(carsSelector);
  const { latLng } = useSelector(getLatLng);

  useEffect(() => {
    const getpointers: CarListMapPointer[] = carList.map((car) => ({
      text: `SR ${car.rental_rate}/${car.duration_parameter}`,
      lat: Number(car.latitude),
      lng: Number(car.longitude),
      carId: car.car_id
    }));
    setpointers(getpointers);
  }, [carList]);

  useEffect(() => {
    getCarCategoryOptions();
    getCarMakeOptions();
  }, []);

  useEffect(() => {
    if (fromDate && endDate && latLng.latitude && latLng.longitude) {
      // TO DO remove variables
      const from_date = fromDate;
      const end_date = endDate;
      const latitude = latLng.latitude;
      const longitude = latLng.longitude;
      const user_id = '';
      const car_category = selectedCategoryId;
      const car_make = selectedMakeId;
      const car_model = selectedModelId;
      const trip_type = selectedTripType;
      const trip_duration = selectedTripDuration;
      dispatch(getSearchCarsListAsync({
        latitude, longitude, from_date, end_date, user_id, car_category, car_make, car_model, trip_type, trip_duration
      }));
    }
  }, [selectedCategoryId, selectedMakeId, selectedModelId, selectedTripType, selectedTripDuration]);

  const getCarCategoryOptions = async () => {
    const options = await getCarCategory();
    if (options && options.data) {
      // setCarCategoryOptions(options.data);
    }
  };

  const getCarMakeOptions = async () => {
    const options = await getCarMake();
    if (options && options.data) {
      // setCarMakeOptions(options.data);
    };
  };

  // const getCarModelOptions = async () => {
  //   // if (selectedMakeId) {
  //   const options = await getCarModels('');
  //   if (options && options.data) {
  //     setCarModelOptions(options.data);
  //   }
  //   // };
  // };

  // const handleCategoryChange = (event: SelectChangeEvent) => {
  //   setSelectedCategoryId(event.target.value);
  // };

  // const handleCarMakeChange = (event: SelectChangeEvent) => {
  //   setSelectedMakeId(event.target.value);
  // };

  // const handleCarModelChange = (event: SelectChangeEvent) => {
  //   setSelectedModelId(event.target.value);
  // };

  const handleTripTypeChange = (event: SelectChangeEvent) => {
    setSelectedTripType(event.target.value);
  };

  // const handleTripDurationChange = (event: SelectChangeEvent) => {
  //   setSelectedTripDuration(event.target.value);
  // };

  return (
    <>
      <Container maxWidth={'xl'} className="main-container">
        <Box>
          <FormControl sx={{ m: 1, minWidth: 141 }}>
            <InputLabel>Car Category</InputLabel>
           <CarCategoryDropdown/>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 141 }}>
            <InputLabel>Car Make</InputLabel>
            <CarMakeDropdown/>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 141 }}>
            <InputLabel>Car Model</InputLabel>
           <CarModelDropdown/>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 141 }}>
            <InputLabel>Trip Type</InputLabel>
            <Select
              value={selectedTripType}
              label="Age"
              onChange={handleTripTypeChange}
            >
              <MenuItem value="">
                <em>Any</em>
              </MenuItem>
              <MenuItem value={'long'}>Long</MenuItem>
              <MenuItem value={'short'}>Short</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 141 }}>
            <InputLabel>Trip Duration</InputLabel>
            <TripDurationDropdown/>
          </FormControl>
        </Box>
        <Typography
          sx={{
            m: '10px'
          }}
        >
          {carList.length}+ Cars in map area
        </Typography>
        <Divider></Divider>
      </Container>
      <Container
        maxWidth={'xl'}
        sx={{
          pt: 3
        }}
      >
        {isFetching
          ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
            )
          : (
            <Grid
              container
              spacing={1}
              sx={{
                m: 1
              }}
            >
              <Grid
                container
                item
                xs={6}
                spacing={2}
                sx={{
                  maxHeight: '100vh',
                  overflowY: 'scroll'
                }}
              >
                {carList.map((car) => (
                  <Grid container item xs={6} key={`car-${car.car_id}`}>
                    <CarBox carData={car} />
                  </Grid>
                ))}
              </Grid>
              <Grid container item xs={6}>
                <CarListMap pointers={pointers} />
              </Grid>
            </Grid>
            )}
      </Container>
    </>
  );
}
