import {
  Box,
  Divider,
  ListItem,
  Fab,
  List,
  Grid,
  ListItemText,
  TextField,
  Typography,
  Avatar,
  ListItemIcon,
  Paper
} from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SendIcon from '@mui/icons-material/Send';
import { makeStyles } from '@material-ui/core/styles';
import { userIdSelectorSelector } from '../../login/authSlice';
import 'firebase/firestore';
import { useSelector } from 'react-redux';
import {
  Messages,
  ChatUser,
  ChatUserInfo,
  UserChatListRef,
  UserChatList
} from '../../../util/types';
import { db } from './firebase';
import {
  collection,
  DocumentData,
  DocumentReference,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  orderBy,
  where,
  documentId,
  doc,
  updateDoc,
  arrayUnion,
  Timestamp,
  Unsubscribe
} from 'firebase/firestore';
import { dateOptions, getFullName } from '../../../util/util';

const useStyles = makeStyles({
  table: { minWidth: 650 },
  chatSection: { width: '100%', height: '80vh' },
  headBG: { backgroundColor: '#e0e0e0' },
  borderRight500: { borderRight: '1px solid #e0e0e0' },
  messageArea: { height: '60vh', overflowY: 'auto' }
});
let currMessageSub: undefined | Unsubscribe = undefined;

const Message = () => {
  const userID = useSelector(userIdSelectorSelector);
  const [currUserNode, setCurrUserNode] = useState<ChatUser | undefined>();
  const [chatUserRefs, setChatUserRefs] = useState<
    DocumentReference<DocumentData>[]
  >([]);
  const [tempChatUserNodes, setTempChatUserNodes] = useState<UserChatList[]>(
    []
  );
  const [chatUserNodes, setChatUserNodes] = useState<UserChatList[]>([]);
  const [chatUserNode, setChatUserNode] = useState<UserChatList | undefined>();

  const classes = useStyles();
  const [messages, setMessages] = useState<Messages[]>([]);
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    if (userID) {
      getUser();
    }
  }, [userID]);

  useEffect(() => {
    getUserRefs();
  }, [currUserNode]);

  useEffect(() => {
    getUsers();
  }, [chatUserRefs]);

  useEffect(() => {
    if (currMessageSub) {
      currMessageSub();
    }
    getMessages();
  }, [chatUserNode?.threadRef]);

  const getUser = async () => {
    const userRef = collection(db, 'Users');
    const q = query(userRef, where('userID', '==', userID));
    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setCurrUserNode({
          ref: doc.ref,
          info: doc.data() as ChatUserInfo
        });
      });
    });
  };

  const getUserRefs = async () => {
    if (currUserNode && currUserNode.ref) {
      const messageRef = collection(db, 'Messages');
      const q = query(
        messageRef,
        where('users', 'array-contains', currUserNode.ref)
        // orderBy('las_message', 'desc')
      );
      onSnapshot(q, (querySnapshot) => {
        const list: DocumentReference<DocumentData>[] = [];
        const userList: UserChatList[] = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data() as UserChatListRef;
          const users = data.users;
          const index = users.findIndex((ele) => ele.id != currUserNode.ref.id);
          const user = users.at(index);
          if (user) {
            list.push(user);
            userList.push({
              ref: user,
              last_message: data.last_message,
              messageRef: doc.ref,
              threadRef: data.thread
            });
          }
        });
        setTempChatUserNodes(userList);
        setChatUserRefs(list);
      });
    }
  };

  const getUsers = async () => {
    const ids = chatUserRefs?.map((ele) => ele.id);
    if (ids && Array.isArray(ids) && ids.length > 0) {
      const userRef = collection(db, 'Users');
      const q = query(userRef, where(documentId(), 'in', ids));
      onSnapshot(q, (querySnapshot) => {
        const list: ChatUser[] = [];
        querySnapshot.forEach((doc) => {
          const info = tempChatUserNodes.find(
            (ele) => ele.ref.id === doc.ref.id
          );

          list.push({
            ...info,
            ref: doc.ref,
            info: doc.data() as ChatUserInfo
          });
        });
        setChatUserNodes(list);
        if (!chatUserNode) {
          setChatUserNode(list?.[0]);
        }
      });
    }
  };

  const getMessages = async () => {
    const threadRef = chatUserNode?.threadRef;
    if (threadRef?.id) {
      const threadDb = collection(db, 'Threads');
      const q = query(threadDb, where(documentId(), '==', threadRef.id));
      currMessageSub = onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.size === 0) {
          setMessages([]);
        }
        querySnapshot.forEach((doc) => {
          if (doc.id === threadRef.id) {
            setMessages((doc.data() as { thread: Messages[] }).thread);
          }
        });
        const el = document.getElementById('chat');
        if (el) {
          el.scrollTop = el.scrollHeight;
        }
      });
    } else {
      setMessages([]);
    }
  };

  const sendMessage = async () => {
    if (newMessage) {
      const threadId = chatUserNode?.threadRef?.id;
      const msgId = chatUserNode?.messageRef?.id;
      const time = Timestamp.fromDate(new Date());
      if (threadId && msgId) {
        const threadDoc = doc(db, 'Threads', threadId);
        updateDoc(threadDoc, {
          thread: arrayUnion({
            message: newMessage,
            senderId: userID,
            status: 'sent',
            createdAt: time
          } as Messages)
        });

        const msgDoc = doc(db, 'Messages', msgId);
        updateDoc(msgDoc, {
          'last_message.createdAt': time,
          'last_message.message': newMessage
        });

        setNewMessage('');
      }
    }
  };

  return (
    <Box
      sx={{
        marginTop: '110px',
        width: '96%',
        marginLeft: '2%',
        marginRight: '2%'
      }}
    >
      <Grid
        container
        spacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        margin={'100px'}
        justifyItems={'center'}
        justifyContent={'center'}
      >
        <Grid item xs={3}>
          <Box
            sx={{
              border: '0.001rem solid #8080803d',
              borderRadius: '15px',
              height: '100%',
              position: 'relative'
            }}
          >
            <Box>
              <Typography sx={{ fontSize: '22px', margin: '15px' }}>
                Message
              </Typography>
            </Box>

            <Grid container>
              <Grid item xs={12}>
                <List>
                  {chatUserNodes &&
                    chatUserNodes.map((ele) => {
                      const name = getFullName(
                        ele.info?.firstName,
                        ele.info?.lastName
                      );
                      return (
                        <ListItem
                          key={ele.ref.id}
                          onClick={() => {
                            setChatUserNode(ele);
                          }}
                          sx={
                            ele.ref.id === chatUserNode?.ref?.id
                              ? {
                                  background:
                                    '#E85656 0% 0% no-repeat padding-box'
                                }
                              : {}
                          }
                        >
                          <ListItemIcon>
                            {ele.info?.profileUrl ? (
                              <Avatar alt={name} src={ele.info?.profileUrl} />
                            ) : (
                              <Avatar>{name}</Avatar>
                            )}
                          </ListItemIcon>
                          <Grid item xs={10}>
                            <Box sx={{ margin: '2px', display: 'flex' }}>
                              <Grid container>
                                <Grid item xs={6}>
                                  <ListItemText sx={{ fontWeight: 'bold' }}>
                                    {name}
                                  </ListItemText>
                                </Grid>
                                <Grid item xs={6}>
                                  <ListItemText
                                    sx={{
                                      fontSize: '13px',
                                      textAlign: 'end',
                                      marginLeft: 'auto'
                                    }}
                                  >
                                    {ele.last_message?.createdAt?.seconds
                                      ? new Date(
                                          ele.last_message.createdAt.seconds
                                        ).toLocaleDateString(
                                          'en-US',
                                          dateOptions as any
                                        )
                                      : ''}
                                  </ListItemText>
                                </Grid>
                              </Grid>
                            </Box>
                            <Box sx={{ margin: 'auto' }}>
                              <ListItemText>
                                {ele.last_message?.message || ''}
                              </ListItemText>
                            </Box>
                          </Grid>
                        </ListItem>
                      );
                    })}
                </List>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={9}>
          <Box
            sx={{
              border: '0.001rem solid #8080803d',
              borderRadius: '18px',
              padding: 3,
              justifyContent: 'space-around'
            }}
          >
            <Box sx={{ display: 'flex', marginBottom: '10px' }}>
              <Grid container>
                <Grid item xs={1} sx={{ margin: 'auto' }}>
                  {chatUserNode?.info?.profileUrl ? (
                    <Avatar
                      alt={getFullName(
                        chatUserNode?.info?.firstName,
                        chatUserNode?.info?.lastName
                      )}
                      src={chatUserNode?.info?.profileUrl}
                    />
                  ) : (
                    <Avatar>
                      {getFullName(
                        chatUserNode?.info?.firstName,
                        chatUserNode?.info?.lastName
                      )}
                    </Avatar>
                  )}
                </Grid>
                <Grid item xs={11} sx={{ margin: 'auto' }}>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontWeight: 'bold',
                      margin: 'auto'
                    }}
                  >
                    {getFullName(
                      chatUserNode?.info?.firstName,
                      chatUserNode?.info?.lastName
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ width: '100%', alignItems: 'center' }}></Divider>
            <Typography sx={{ textAlign: 'center', margin: '10px' }}>
              Today
            </Typography>
            <Box>
              <Grid item xs={12}>
                <List className={classes.messageArea} id='chat'>
                  {messages.map((item, i) =>
                    item.senderId == userID ? (
                      <ListItem key={i}>
                        <Grid container>
                          <Grid
                            item
                            xs={4}
                            sx={{
                              backgroundColor: '#937c7c',
                              color: '#ffffff',
                              borderRadius: '13px',
                              padding: '6px',
                              display: 'flex',
                              marginLeft: 'auto'
                            }}
                          >
                            <ListItemText
                              sx={{ textAlign: 'right' }}
                              primary={item.message}
                            ></ListItemText>
                          </Grid>
                          <Grid item xs={12}>
                            <ListItemText
                              sx={{ textAlign: 'right' }}
                              secondary='09:30'
                            ></ListItemText>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ) : (
                      <ListItem key={i}>
                        <Grid container>
                          <Grid
                            item
                            xs={4}
                            sx={{
                              backgroundColor: '#fbe2e2',
                              borderRadius: '13px',
                              padding: '6px',
                              marginRight: 'auto'
                            }}
                          >
                            <ListItemText
                              sx={{ textAlign: 'left' }}
                              primary={item.message}
                              key={item?.senderId}
                            >
                              {item.message}
                            </ListItemText>
                          </Grid>
                          <Grid item xs={12}>
                            <ListItemText
                              sx={{ textAlign: 'left' }}
                              secondary='09:31'
                            ></ListItemText>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )
                  )}
                </List>

                <Divider />

                <Grid container style={{ padding: '20px' }}>
                  <Grid item xs={11}>
                    <TextField
                      placeholder='type somthing...'
                      type='text'
                      sx={{
                        borderRadius: '13px',
                        backgroundColor: '#a9a9a93d'
                      }}
                      onChange={(e) => setNewMessage(e.target.value)}
                      value={newMessage}
                      fullWidth
                      onKeyPress={(e) => {
                        if (e.charCode === 13) {
                          sendMessage();
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <Box>
                      <Fab
                        sx={{
                          backgroundColor: '#E85656 !important',
                          margin: 'auto'
                        }}
                        aria-label='add'
                        onClick={sendMessage}
                      >
                        <SendIcon
                          sx={{
                            color: 'white',
                            fontSize: 25,
                            display: 'block',
                            margin: 'auto'
                          }}
                        />
                      </Fab>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Message;
