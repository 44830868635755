import jwt_decode, { JwtDecodeOptions } from 'jwt-decode';

export const getJsonParse = (stringify: string) => {
  try {
    return JSON.parse(stringify);
  } catch (e) {
    return null;
  }
};

export const replaceTextUsingRegx = (
  text: string,
  regx: RegExp = /<br\/>/g,
  replceText: string = '\n'
) => {
  try {
    return text.replace(regx, replceText);
  } catch (e) {
    return text;
  }
};

export const getTimestamp = (date: Date) => {
  if (date) {
    return new Date(date).getTime();
  } else {
    return new Date().getTime();
  }
};

export const dateOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: 'numeric'
};

// export const desktopNotification = (
//   title = "New Title",
//   body = "New Body",
//   icon = "https://lxp-assets.s3.ap-south-1.amazonaws.com/icons/logo_square_transparent_bg.png",
//   dir = "ltr"
// ) => {
//   if (!("Notification" in window)) {
//     alert("This browser does not support desktop notification");
//   } else if (Notification.permission === "granted") {
//     var options = {
//       body: body,
//       icon: icon,
//       dir: dir,
//     };
//     new Notification(title, options);
//   } else if (Notification.permission !== "denied") {
//     Notification.requestPermission(function (permission) {
//       if (!("permission" in Notification)) {
//         Notification.permission = permission;
//       }

//       if (permission === "granted") {
//         var options = {
//           body: body,
//           icon: icon,
//           dir: dir,
//         };
//         new Notification(title, options);
//       }
//     });
//   }
// };

export const getjwtDecode = (
  token?: string,
  options?: JwtDecodeOptions | undefined
) => {
  try {
    const jwtString = token || localStorage.getItem('token');
    if (jwtString) {
      const payload = jwt_decode(jwtString, options);
      return payload;
    }

    return {};
  } catch (e) {
    return {};
  }
};

export const trimRight = (str: string, trimStr: string): string => {
  return str.endsWith(trimStr)
    ? str.substring(0, str.length - trimStr.length)
    : str;
};

export function daysInThisMonth(): number {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
}

export function getCurrentMonth() {
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const d = new Date();
  return month[d.getMonth()];
}

export function distance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: string
) {
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == 'K') {
      dist = dist * 1.609344;
    }
    if (unit == 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }
}

export function capitalize(s: string) {
  return s && s[0].toUpperCase() + s.slice(1);
}

export function getFullName(firstName?: string, lastName?: string): string {
  if (!firstName) {
    return '';
  }

  if (!lastName) {
    return firstName;
  }

  return [firstName, lastName].join(' ');
}
