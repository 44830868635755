import {
  Box,
  Card,
  CardMedia,
  Button,
  Typography,
  Rating,
  Grid,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Divider } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { CarCheckOutData } from "../../../util/types/car";

export interface PaymentProps {
  bookCar: Function;
  bookingData: CarCheckOutData;
  editCheckout: Function;
  carimage?: string;
}

export default function CarPayment(props: PaymentProps) {
  return (
    <div>
      <Box
        sx={{ width: "98%", position: "relative" }}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"start"}
        flexDirection={"column"}
      >
        <Box
          mt={13}
          display={"flex"}
          sx={{ width: "100%" }}
          alignItems={"center"}
          flexDirection={"row"}
        >
          <Box
            mt={1}
            display={"flex"}
            justifyContent={"start"}
            alignItems={"start"}
            flexDirection={"row"}
            sx={{ width: "100%" }}
          >
            <Box display={"flex"} flexDirection={"row"} sx={{ width: "60%" }}>
              <Button
                onClick={() => {
                  props.editCheckout();
                }}
              >
                <ArrowBackIcon />
              </Button>
              <Typography display={"flex"} variant="h5" sx={{ color: "black" }}>
                Confirm and Make Payment
              </Typography>
            </Box>

            {/* <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"end"}
              sx={{ width: "40%" }}
            >
              <CheckCircleIcon sx={{ color: "green" }} />
              <Typography ml={2}>
                Hi John, your account is created successfully.
              </Typography>
            </Box> */}
          </Box>
        </Box>

        <Box sx={{ width: "100%" }} mt={3}>
          <Divider></Divider>
        </Box>

        <Box
          m={3}
          sx={{ width: "100%" }}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"row"}
        >
          <Box sx={{ width: "60%" }} margin={"auto"}>
            <Box>
              <Box>
                <Grid container sx={{ display: "flex" }}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontSize: "1.125rem",
                        color: "black",
                        alignItems: "center",
                      }}
                    >
                      Please review your booking details to continue
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      sx={{
                        color: "red",
                      }}
                      onClick={() => {
                        props.editCheckout();
                      }}
                    >
                      Edit
                    </Button>
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Box
                  mt={4}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"start"}
                  flexDirection={"row"}
                >
                  <Typography
                    variant="h4"
                    sx={{ color: "black", fontSize: "24px" }}
                  >
                    Dates
                  </Typography>
                  {/* <Button
                    sx={{
                      color: 'red',
                      float: 'center'
                    }}
                  >
                    Edit
                  </Button> */}
                </Box>
                <Grid container sx={{ display: "flex" }}>
                  <Grid item xs={6}>
                    <Typography sx={{ color: "black" }}>Trip Start</Typography>
                    <Typography>{props.bookingData.from_date}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ color: "black" }}>Trip End</Typography>
                    <Typography>{props.bookingData.end_date}</Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ width: "100%" }} mt={3}>
                <Divider></Divider>
              </Box>
              <Box
                mt={4}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"start"}
                flexDirection={"row"}
              >
                <Box>
                  <Typography
                    variant="h4"
                    sx={{ color: "black", fontSize: "24px" }}
                  >
                    Pickup
                  </Typography>
                  <Typography variant="body2" sx={{ color: "black" }}>
                    {props.bookingData.pickup}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ width: "100%" }} mt={3}>
                <Divider></Divider>
              </Box>
              <Box
                mt={4}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"start"}
                flexDirection={"row"}
              >
                <Box>
                  <Typography
                    variant="h4"
                    sx={{ color: "black", fontSize: "24px" }}
                  >
                    Destination
                  </Typography>
                  <Typography variant="body2" sx={{ color: "black" }}>
                    {props.bookingData.destination}
                  </Typography>
                </Box>
                {/* <Box>
                  <Button
                    sx={{
                      color: 'red',
                      float: 'center'
                    }}
                  >
                    Edit
                  </Button>
                </Box> */}
              </Box>
              <Box sx={{ width: "100%" }} mt={3}>
                <Divider></Divider>
              </Box>
              <Box
                mt={4}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"start"}
                flexDirection={"row"}
              >
                <Box>
                  <Typography
                    variant="h4"
                    sx={{ color: "black", fontSize: "24px" }}
                  >
                    Driving License
                  </Typography>
                  <Typography variant="body2" sx={{ color: "black" }}>
                    {props.bookingData.licence ? props.bookingData.licence : ""}
                  </Typography>
                </Box>
                {/* <Box>
                  <Button
                    sx={{
                      color: 'red',
                      float: 'center'
                    }}
                  >
                    Edit
                  </Button>
                </Box> */}
              </Box>
            </Box>
            <Box
              mt={3}
              display={"flex"}
              alignItems={"end"}
              sx={{
                justifyContent: "end",
              }}
            >
              <Button
                onClick={() => {
                  props.bookCar();
                }}
                sx={{
                  float: "center",
                  width: "14%",
                  height: "48px",
                  borderRadius: "10px",
                  color: "#fff",
                  font: "normal bold 16px/22px Work Sans",
                  backgroundColor: "#E85656 !important",
                }}
              >
                Make Payment
              </Button>
            </Box>
          </Box>
          <Box
            margin={"auto"}
            sx={{
              boxShadow: "none",
              width: "25%",
            }}
            alignItems={"center"}
          >
            <Card
              sx={{
                padding: "20px",
                boxShadow:
                  "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
              }}
            >
              <Box sx={{ width: "100%", margin: 1 }}>
                <Box sx={{ marginTop: 1 }} display={"flex"}>
                  {props.carimage ? (
                    <CardMedia
                      sx={{
                        margin: "0",
                        objectFit: "cover",
                        display: "flex",
                        borderRadius: 2,
                        height: "100px",
                        width: "157px",
                      }}
                      component={"img"}
                      src={props.carimage}
                    />
                  ) : (
                    <CardMedia
                      sx={{
                        margin: "0",
                        objectFit: "cover",
                        display: "flex",
                        borderRadius: 2,
                        height: "100px",
                        width: "157px",
                      }}
                      component={"img"}
                      src={
                        "https://media.wired.com/photos/5d09594a62bcb0c9752779d9/1:1/w_1500,h_1500,c_limit/Transpo_G70_TA-518126.jpg"
                      }
                    />
                  )}
                  <Box ml={3} alignItems={"center"}>
                    <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                      {props.bookingData.car_make} {props.bookingData.car_model}
                    </Typography>
                    {/* <Rating name="read-only" value={3} readOnly /> */}
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                      }}
                    >
                      {props.bookingData.tripType} Trip
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ width: "100%" }} mt={3}>
                  <Divider></Divider>
                </Box>
                <Typography
                  sx={{ margin: 2, fontSize: "19px", fontWeight: "bold" }}
                  display={"flex"}
                >
                  Invoice Summary
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <Box
                    m={2}
                    sx={{ width: "100%" }}
                    display={"flex"}
                    flexDirection={"row"}
                  >
                    <Typography sx={{ width: "50%", borderRadius: "10px" }}>
                      Booked Hours
                    </Typography>
                    <Typography sx={{ width: "50%", borderRadius: "10px" }}>
                      {props.bookingData.tripDuration}
                    </Typography>
                  </Box>
                  <Box
                    m={2}
                    sx={{ width: "100%" }}
                    display={"flex"}
                    flexDirection={"row"}
                  >
                    <Typography sx={{ width: "50%", borderRadius: "10px" }}>
                      Base Price
                    </Typography>
                    <Typography
                      sx={{
                        width: "50%",
                        borderRadius: "10px",
                        textTransform: "capitalize",
                      }}
                    >
                      SAR {props.bookingData.trip_rate}/
                      {props.bookingData.rateType}
                    </Typography>
                  </Box>
                  <Box
                    m={2}
                    sx={{ width: "100%" }}
                    display={"flex"}
                    flexDirection={"row"}
                  >
                    <Typography sx={{ width: "50%" }}>Trip Fee</Typography>
                    <Typography sx={{ width: "50%" }}>
                      SAR {props.bookingData.trip_fee.toFixed(2)}
                    </Typography>
                  </Box>
                  {/* <Box
                    m={2}
                    sx={{ width: "100%" }}
                    display={"flex"}
                    flexDirection={"row"}
                  >
                    <Typography sx={{ width: "50%" }}>Additional km</Typography>
                    <Typography sx={{ width: "50%" }}>SAR 160.00</Typography>
                  </Box> */}
                  <Box
                    m={2}
                    sx={{ width: "100%" }}
                    display={"flex"}
                    flexDirection={"row"}
                  >
                    <Typography sx={{ width: "50%" }}>VAT</Typography>
                    <Typography sx={{ width: "50%" }}>
                      SAR {props.bookingData.tax.toFixed(2)}
                    </Typography>
                  </Box>
                  <Box
                    m={2}
                    display={"flex"}
                    flexDirection={"row"}
                    sx={{
                      width: "100%",
                      marginTop: 1,
                    }}
                  >
                    <Typography
                      fontWeight={"bold"}
                      sx={{ width: "50%", fontSize: "18px" }}
                    >
                      Total
                    </Typography>
                    <Typography
                      fontWeight={"bold"}
                      sx={{ width: "50%", fontSize: "18px" }}
                    >
                      SAR {props.bookingData.payable_amount.toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box m={2} display={"flex"} flexDirection={"row"}>
                <Button
                  sx={{
                    border: 1,
                    width: "95%",
                    textAlign: "start",
                    height: "47px",
                    color: "black",
                    borderColor: "gray",
                    borderRadius: "12px",
                    alignItems: "left",
                  }}
                >
                  <Typography ml={0}>Apply Coupon</Typography>
                  <ArrowForwardIcon
                    sx={{ alignItems: "right" }}
                    color="action"
                  />
                </Button>
              </Box>
            </Card>
          </Box>
        </Box>
      </Box>
      {/* 

      <CustomModal
        sx={{
          width: "32%",
        }}
      >
        <Grid
          container
          spacing={3}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={8}>
            <Box alignItems={"center"} display={"flex"}>
              <CheckCircleOutlineOutlinedIcon
                sx={{
                  color: "green",
                  width: "20%",
                  height: "20%",
                  margin: "auto",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Typography
              sx={{ textAlign: "center", fontWeight: "400", fontSize: "22px" }}
            >
              Success!
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              sx={{ textAlign: "center", fontWeight: "400", fontSize: "22px" }}
            >
              SAR 3584.00 Paid Successfully to Ahmed Alsade
            </Typography>
          </Grid>
          <Grid item xs={8} sx={{ textAlign: "center", alignItems: "center" }}>
            <Button sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "400",
                  fontSize: "22px",
                }}
              >
                Done
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </CustomModal> */}
    </div>
  );
}
