import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { addFeaturesApi } from '../addCarAPI';
import { AddFeaturesRequest } from '../interface/addCarInterface';

export interface addFeatureState {
  isFeaturesSuccess: boolean;
  isFeaturesError: boolean;
  isFeaturesFetching: boolean;
  errorFeaturesMessage: string;
  featuresData: AddFeaturesRequest;
}

export const addFeaturesAsync = createAsyncThunk(
  'car/addFeature',
  async (req: AddFeaturesRequest, thunkAPI) => {
    try {
      const response = await addFeaturesApi(req);
      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log('Error', e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState: addFeatureState = {
  isFeaturesSuccess: false,
  isFeaturesError: false,
  isFeaturesFetching: false,
  errorFeaturesMessage: '',
  featuresData: {
    car_id: 0,
    features: ''
  }
};

export const addFeatureSlice = createSlice({
  name: 'addFeatures',
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.isFeaturesSuccess = false;
      state.isFeaturesError = false;
      state.isFeaturesFetching = false;
      state.errorFeaturesMessage = '';
      state.featuresData.car_id = 0;
      state.featuresData.features = '';
      return state;
    },
    setFeaturesFormState: (
      state,
      action: PayloadAction<AddFeaturesRequest>
    ) => {
      console.log(action.payload);
      state.featuresData.car_id = action.payload.car_id;
      state.featuresData.features = action.payload.features;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addFeaturesAsync.fulfilled, (state, action: any) => {
        state.isFeaturesFetching = false;
        state.isFeaturesSuccess = true;
        return state;
      })
      .addCase(addFeaturesAsync.rejected, (state, action: any) => {
        state.isFeaturesFetching = false;
        state.isFeaturesError = true;
        state.errorFeaturesMessage = action.payload.userMessage;
      })
      .addCase(addFeaturesAsync.pending, (state) => {
        state.isFeaturesFetching = true;
      });
  }
});

export default addFeatureSlice.reducer;

export const { clearState, setFeaturesFormState } = addFeatureSlice.actions;

export const addFeatureSelector = (state: RootState) => state.addFeatures;
