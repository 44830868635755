import axios from 'axios';
import config from '../../../util/config.js';

export interface loginReq {
  email: string;
  password: string;
};

export async function login (req: loginReq) {
  const data = {
    email: req.email,
    password: req.password
  };
  try {
    const response = await axios.post(config.BASE_URL + 'corporate-login', data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.data.response;
  } catch (error: any) {
    throw new Error(error);
  }
};
