import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid, IconButton, Stack, SxProps } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { uploadFile } from '../../../util/s3';
import { addCarInfoSelector } from './slice/addCarInfoSlice';
import { useDispatch, useSelector } from 'react-redux';
import { addCarPhotoSelector, setCarPhotosFormState } from './slice/addCarPhotosSlice';
import { AddCarPhotosRequest } from './interface/addCarInterface';

const Input = styled('input')({
  display: 'none'
});

const BoxUploadFile: SxProps | undefined = {
  width: '200px',
  height: '200px',
  border: '1px dashed grey',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export default function AddPhotos () {
  const dispatch = useDispatch();
  const { photosData } = useSelector(addCarPhotoSelector);
  const [imageList, setImageList] = React.useState<string[]>([]);
  const { car_id } = useSelector(addCarInfoSelector);
  const [formValues, setFormValues] = React.useState<AddCarPhotosRequest>({ ...photosData });

  React.useEffect(() => {
    dispatch(setCarPhotosFormState(formValues));
  }, [formValues]);

  const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event && event.target && event.target.files && event.target.files[0]) {
      const data = uploadFile(event.target.files[0].name, event.target.files[0], car_id);
      // data.on('httpUploadProgress', (progress: ManagedUpload.Progress) => {
      //   console.log(progress)
      // })
      data.promise().then(res => {
        setImageList([...imageList, res.Location]);
      }).catch(err => console.log(err))
    }
  };

  React.useEffect(() => setFormValues({ ...formValues, car_id: car_id, file_paths: imageList }), [imageList]);

  return (
    <Box
      component='form'
      sx={{
        width: '100%',
        m: 3
      }}
      noValidate
      autoComplete='off'
    >
      <Grid container>
        <Stack direction='row' alignItems='center' spacing={2}>
          {imageList.length > 0 &&
            imageList.map((val: string, index: number) => (
              <Box sx={BoxUploadFile} key={index}>
                <img src={val} style={{ height: '100%', width: '100%' }} />
              </Box>
            ))}
          <Box sx={BoxUploadFile}>
            <label htmlFor='icon-button-file'>
              <Input
                accept='image/*'
                id='icon-button-file'
                type='file'
                onChange={onUpload}
              />
              <IconButton
                color='primary'
                aria-label='upload picture'
                component='span'
              >
                <PhotoCamera />
              </IconButton>
            </label>
          </Box>
        </Stack>
      </Grid>
    </Box>
  );
}
