import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControl, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addInsuranceInfoSelector, setInsuranceInfoFormState } from './slice/addInsuranceInfoSlice';
import { AddInsuranceInfoRequest } from './interface/addCarInterface';
import { addCarInfoSelector } from './slice/addCarInfoSlice';

export default function AddInsuranceDetails () {
  const dispatch = useDispatch();
  const { insuranceInfoData } = useSelector(addInsuranceInfoSelector);
  const { car_id } = useSelector(addCarInfoSelector);
  const [formValues, setFormValues] = React.useState<AddInsuranceInfoRequest>({ ...insuranceInfoData });

  React.useEffect(() => {
    setFormValues({ ...formValues, car_id: car_id });
  }, [car_id]);

  React.useEffect(() => {
    dispatch(setInsuranceInfoFormState(formValues));
  }, [formValues]);

  const onProviderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const provider = event?.target.value;
    setFormValues({ ...formValues, provider: provider });
  };

  const onPolicyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const policy = event?.target.value;
    setFormValues({ ...formValues, policy_number: policy });
  };

  const onPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rate = Number(event?.target.value);
    setFormValues({ ...formValues, min_charges: rate });
  };

  return (
        <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 3, width: '45%' },
              width: '100%'
            }}
            noValidate
            autoComplete="off"
        >
            <Typography> Optional </Typography>
            <TextField
                label="Insurance Provider"
                id="insurance provider"
                value={insuranceInfoData.provider || ''}
                onChange={onProviderChange}
            />
             <TextField
                label="Policy Number"
                id="policy number"
                value={insuranceInfoData.policy_number || ''}
                onChange={onPolicyChange}
            />
            <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="price">Minimum Charges For Claim</InputLabel>
                <OutlinedInput
                    id="price"
                    type='number'
                    value={insuranceInfoData.min_charges || ''}
                    onChange={onPriceChange}
                    endAdornment={<InputAdornment position="end">SAR</InputAdornment>}
                    label="Minimum Charges For Claim"
                />
            </FormControl>
        </Box >
  );
}
