import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Button } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import whiteLogo from "./../../../assets/img/logo-white.png";
import redLogo from "./../../../assets/img/Sharik-logo/logoin-appp.png";
import "./header.css";
import { Login } from "./../../../features/login/login";
import { useNavigate } from "react-router-dom";
import {
  checkIsAuth,
  logout,
  userSelector,
} from "./../../../features/login/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { trimRight } from "../../../util/util";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { SwitchBar } from "../SwitchBar/SwitchBar";
import { NavLink, useLocation } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  const isAuth = useSelector(checkIsAuth);
  const dispatch = useDispatch();
  const { userData } = useSelector(userSelector);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [isHomeHeader, setIsHomeHeader] = React.useState(true);
  // const [isHideSearchBar, setIsHideSearchBar] = React.useState(false);
  const location = useLocation();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [anchorE2, setAnchorE2] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorE2);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE2(null);
  };

  const [anchorE3, setAnchorE3] = React.useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorE3);
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE3(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorE3(null);
  };

  const logoutUser = () => {
    handleMenuClose();
    dispatch(logout());
    window.location.href = "/car-rental";
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    const href = trimRight(location.pathname, "/");
    const homeHeader = !!(!href || href === "/car-rental");
    setIsHomeHeader(homeHeader);
    // setIsHideSearchBar(!homeHeader && href.endsWith("list"));
  }, [location]);

  const btnSx = {
    my: 2,
    color: isHomeHeader ? "white" : "#1A281F",
  };

  const onchange = (value: any) => {
    if(value === "notification"){
      navigate("/notification");
      handleClose();
      handleMenuClose();
    }
    else if (value === "add-car") {
      navigate("/car-rental/add-car");
      handleClose();
      handleMenuClose();
    } else if (value === "my-cars") {
      navigate("/car-rental/my-cars");
      handleClose();
      handleMenuClose();
    } else if (value === "chat") {
      navigate("/car-rental/chat");
      handleClose();
      handleMenuClose();
    } else if (value === "trips") {
      navigate("/car-rental/trips");
      handleClose();
      handleMenuClose();
    } else if (value === "user-profile") {
      navigate("/user-profile");
      handleClose();
      handleMenuClose();
    }
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{ width: "165px" }}
    >
      {/* <MenuItem onClick={handleMenuClose}> */}
      <MenuItem onClick={() => onchange("notification")}>
        Notification
      </MenuItem>
      <MenuItem sx={{ width: "100px", height: "50px" }}>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{ color: "red" }}
        >
          Car Rental
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorE2}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => onchange("add-car")}>Add Car</MenuItem>
          <MenuItem onClick={() => onchange("my-cars")}>My Cars</MenuItem>
          <MenuItem onClick={() => onchange("chat")}>Message</MenuItem>
          <MenuItem onClick={() => onchange("trips")}>Trips</MenuItem>
        </Menu>
      </MenuItem>
      <MenuItem sx={{ width: "100px", height: "50px" }}>
        <Button
          id="basic-button"
          aria-controls={open2 ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open2 ? "true" : undefined}
          onClick={handleClick2}
          sx={{ color: "red" }}
        >
          Saied
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorE3}
          open={open2}
          onClose={handleClose2}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem>My Orders</MenuItem>
          <MenuItem>My Message</MenuItem>
          <MenuItem>Delivery Order</MenuItem>
          <MenuItem>Delivery Message</MenuItem>
        </Menu>
      </MenuItem>
      <MenuItem onClick={() => onchange("user-profile")}>My Profile</MenuItem>
      <MenuItem onClick={logoutUser}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <LanguageIcon />
        </IconButton>
        <p>Search</p>
      </MenuItem>
      <MenuItem
        onClick={() => {
          window.location.href = !window.location.hostname.includes(
            "sharik.app"
          )
            ? "https://corporate.wasl.app/"
            : "https://corporate.sharik.app/";
        }}
      >
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <PersonOutlineOutlinedIcon />
        </IconButton>
        <p>Corporate Login</p>
      </MenuItem>
      {isAuth ? (
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircleOutlinedIcon />
          </IconButton>
        </MenuItem>
      ) : (
        <Login>
          <Button size="large" aria-label="search">
            <PersonOutlineOutlinedIcon />
            <p>Sign in/Register</p>
          </Button>
        </Login>
      )}
    </Menu>
  );

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="fixed"
          elevation={0}
          color={isHomeHeader ? "transparent" : "inherit"}
          className="header-nav-var"
          sx={{
            borderBottom: !isHomeHeader ? "1px solid #EAEAEA" : "none",
          }}
        >
          <Toolbar>
            <NavLink to={"/car-rental"}>
              <img src={isHomeHeader ? whiteLogo : redLogo} />
            </NavLink>
            <Box sx={{ flexGrow: 1, padding: "10px 10em" }}>
              {isHomeHeader ? null : <SwitchBar />}
            </Box>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Button size="large" aria-label="search" sx={btnSx}>
                <LanguageIcon />
              </Button>
              <Button
                size="large"
                aria-label="search"
                sx={btnSx}
                onClick={() => {
                  window.location.href = !window.location.hostname.includes(
                    "sharik.app"
                  )
                    ? "https://corporate.wasl.app/"
                    : "https://corporate.sharik.app/";
                }}
              >
                Corporate Login
              </Button>
              {isAuth ? (
                <MenuItem onClick={handleProfileMenuOpen}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                  >
                    <AccountCircleOutlinedIcon sx={btnSx} />
                  </IconButton>
                </MenuItem>
              ) : (
                <Login>
                  <Button size="large" aria-label="search" sx={btnSx}>
                    <PersonOutlineOutlinedIcon />
                    <p>Sign in/Register</p>
                  </Button>
                </Login>
              )}
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
    </>
  );
}
