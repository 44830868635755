import axios from 'axios';
import { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import config from '../../../util/config.js';
import { TripsType, ResponseInterface } from './../../../util/types';
import { Card } from '@mui/material';
import { SideBar } from '../sidebar/sidebar';
import { useSelector } from 'react-redux';
import { userAccessInfoSelector } from '../login/corporateAuthSlice';

export default function Trips() {
  const [carList, setCarList] = useState<TripsType[]>([]);
  const userData = useSelector(userAccessInfoSelector);

  const columns: GridColDef<TripsType>[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'car_make',
      headerName: 'Make Car',
      width: 120
    },
    {
      field: 'car_model',
      headerName: 'Car Model',
      width: 120
    },
    {
      field: 'car_category',
      headerName: 'Car Category',
      width: 120
    },
    {
      field: 'duration_parameter',
      headerName: 'Duration Parameter',
      width: 180
    },
    {
      field: 'rental_rate',
      headerName: 'Rental Rate',
      width: 120
    },
    {
      field: 'trip_type',
      headerName: 'Car Type',
      width: 120
    }
  ];

  useEffect(() => {
    getTrips();
  }, [userData]);

  const getTrips = () => {
    if (userData?.userID) {
      axios
        .post(
          config.BASE_URL + 'trips/past',
          {
            user_id: userData.userID
          },
          {
            headers: {
              'x-access-token': localStorage.getItem('token') || '',
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )
        .then((res: ResponseInterface<TripsType[]>) => {
          const data =
            res.data?.response?.data?.map((ele) => {
              return {
                ...ele,
                id: ele.trip_id
              };
            }) || [];

          setCarList(data);
        });
    }
  };
  if (userData?.roleId === 5) {
    columns.splice(1, 0, {
      field: 'title',
      headerName: 'Branch',
      width: 150
    });
  }
  return (
    <SideBar>
      <Card
        sx={{
          height: '650px',
          p: 2
        }}
      >
        <DataGrid
          rows={carList}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          // checkboxSelection
          showCellRightBorder
          disableSelectionOnClick
        />
      </Card>
    </SideBar>
  );
}
