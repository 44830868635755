import { SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomSelect from '../../components/elements/select/Select';
import { getCarModels } from '../carRental/cars/carsAPI';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownSelector, selectDropdown } from './dropdownSlice';

export default function CarModelDropdown () {
  const [carModelOptions, setCarModelOptions] = useState<any[]>([]);
  const dispatch = useDispatch();
  const { selectedCategoryId, selectedMakeId, selectedModelId } = useSelector(DropdownSelector);

  useEffect(() => {
    getCarModelOptions();
  }, [selectedMakeId]);

  const getCarModelOptions = async () => {
    if (selectedMakeId !== undefined) {
      const options = await getCarModels(selectedMakeId);
      if (options && options.data) {
        setCarModelOptions(options.data);
      } else {
        setCarModelOptions([]);
      }
    } else {
      setCarModelOptions([]);
    }
  };

  const handleModelChange = (event: SelectChangeEvent) => {
    const selectedModelId = event?.target.value;
    dispatch(selectDropdown({ selectedCategoryId, selectedMakeId, selectedModelId }));
  };

  return <CustomSelect
    value={selectedModelId || ''}
    label="Car Model"
    onChange={handleModelChange}
    list={carModelOptions || []} />
}
