import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  Card,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Switch,
  Typography
} from '@mui/material';
import { SideBar } from '../sidebar/sidebar';
import { useSelector } from 'react-redux';
import { userAccessInfoSelector } from '../login/corporateAuthSlice';
import { useParams } from 'react-router-dom';
import config from '../../../util/config.js';
import { ResponseInterface } from '../../../util/types';
import { CarInfoType } from '../../../util/types/car';
import CarImages from './car-images';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import SpeedIcon from '@mui/icons-material/Speed';
import { Box } from '@mui/system';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from 'react-router-dom';

export default function CarInfo() {
  const navigate = useNavigate();
  const [carInfo, setCarInfo] = useState<CarInfoType | null>(null);
  const userData = useSelector(userAccessInfoSelector);
  const { car_id, user_id } = useParams();

  useEffect(() => {
    getMyCar();
  }, [userData]);

  const getMyCar = () => {
    if (userData?.userID) {
      axios
        .post(
          config.BASE_URL + 'mycar',
          {
            user_id: userData.roleId === 5 ? user_id : userData.userID,
            car_id: car_id
          },
          {
            headers: {
              'x-access-token': localStorage.getItem('token') || '',
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )
        .then((res: ResponseInterface<CarInfoType>) => {
          const data = res.data.response.data;
          if (data) {
            setCarInfo(data);
          } else {
            navigate('/cars');
          }
        })
        .catch(() => {
          navigate('/cars');
        });
    }
  };

  return (
    <SideBar>
      {carInfo && (
        <Card
          sx={{
            p: 2
          }}
        >
          <Grid container>
            <Grid container item spacing={2}>
              <Grid item xs={6}>
                <Typography gutterBottom variant='h5' fontWeight={'600'}>
                  {carInfo.carSummary.car_make +
                    ' ' +
                    carInfo.carSummary.car_model +
                    ' (' +
                    carInfo.carSummary.car_category +
                    ')'}
                </Typography>
                <Typography
                  variant='subtitle2'
                  display='flex'
                  alignItems={'center'}
                >
                  <LocationOnIcon
                    fontSize='small'
                    sx={{
                      mr: 1
                    }}
                  />
                  {carInfo.carSummary.location}
                </Typography>
                <Box display='none' alignItems={'center'}>
                  <Typography
                    gutterBottom
                    variant='subtitle1'
                    title='purchased on'
                    display='flex'
                    alignItems={'center'}
                    fontSize='20px'
                    sx={{
                      mr: 2
                    }}
                  >
                    <WatchLaterIcon
                      sx={{
                        mr: 1
                      }}
                    />{' '}
                    {' ' + carInfo.carSummary.purchased_on}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant='subtitle1'
                    title='odometer'
                    display='flex'
                    alignItems={'center'}
                    fontSize='20px'
                  >
                    <SpeedIcon
                      sx={{
                        mr: 1
                      }}
                    />{' '}
                    {' ' + carInfo.carSummary.odometer}
                  </Typography>
                </Box>
                <Grid container>
                  <Grid container item spacing={2}>
                    <Grid item xs={6}>
                      <List
                        sx={{
                          bgcolor: 'background.paper'
                        }}
                        subheader={<ListSubheader>Car Info</ListSubheader>}
                      >
                        <ListItem>
                          <ListItemText primary={'Purchased'} />
                          <ListItemText
                            primary={carInfo.carSummary.purchased_on}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={'Odometer'} />
                          <ListItemText primary={carInfo.carSummary.odometer} />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={'Transmission'} />
                          <ListItemText
                            primary={carInfo.carSummary.transmission}
                          />
                        </ListItem>
                      </List>
                      <List
                        sx={{
                          bgcolor: 'background.paper'
                        }}
                        subheader={<ListSubheader>Rental Info</ListSubheader>}
                      >
                        <ListItem>
                          <ListItemText primary={'Trip Type'} />
                          <ListItemText
                            primary={carInfo.rentalInfo.trip_type}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={'Rental Rate'} />
                          <ListItemText
                            primary={
                              carInfo.rentalInfo.rental_rate +
                              '/' +
                              carInfo.rentalInfo.currency_code
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={'Duration'} />
                          <ListItemText
                            primary={
                              carInfo.rentalInfo.duration_min_value +
                              ' - ' +
                              carInfo.rentalInfo.duration_min_value +
                              ' ' +
                              carInfo.rentalInfo.duration_parameter
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    {carInfo.insuranceInfo && (
                      <Grid item xs={6}>
                        <List
                          sx={{
                            bgcolor: 'background.paper'
                          }}
                          subheader={
                            <ListSubheader>Insurance Info</ListSubheader>
                          }
                        >
                          <ListItem>
                            <ListItemText primary={'Provider'} />
                            <ListItemText
                              primary={carInfo.insuranceInfo.provider}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary={'Policy No'} />
                            <ListItemText
                              primary={carInfo.insuranceInfo.policy_number}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary={'Min Charges'} />
                            <ListItemText
                              primary={
                                carInfo.insuranceInfo.min_charges +
                                '/' +
                                carInfo.insuranceInfo.currency_code
                              }
                            />
                          </ListItem>
                        </List>
                      </Grid>
                    )}
                    <Grid item xs={8}>
                      <ListSubheader>Features</ListSubheader>
                      <List
                        sx={{
                          bgcolor: 'background.paper',
                          display: 'flex'
                        }}
                      >
                        {carInfo.carFeatures?.map(
                          (feature) =>
                            feature && (
                              <ListItem
                                sx={{
                                  display: 'inline-block'
                                }}
                              >
                                <ListItemIcon>
                                  <img
                                    style={{
                                      width: '30px'
                                    }}
                                    src={
                                      feature.icon.path_prefix +
                                      feature.icon?.types?.[0]?.value
                                    }
                                  />
                                </ListItemIcon>
                                <ListItemText primary={feature.car_feature} />
                              </ListItem>
                            )
                        )}
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} display='flex' alignItems={'center'}>
                <CarImages images={carInfo?.carPhotos || []} />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      )}
    </SideBar>
  );
}
