import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { addKeySpecificationsApi } from '../addCarAPI';
import { AddKeySpecificationsRequest } from '../interface/addCarInterface';

export interface AddKeySpecificationsState {
  isKeySpecificationsSuccess: boolean;
  isKeySpecificationsError: boolean;
  isKeySpecificationsFetching: boolean;
  errorKeySpecificationsMessage: string;
  keySpecificationsData: AddKeySpecificationsRequest;
}

export const addKeySpecificationsAsync = createAsyncThunk(
  'car/addKeySpecifications',
  async (req: AddKeySpecificationsRequest, thunkAPI) => {
    try {
      const response = await addKeySpecificationsApi(req);
      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log('Error', e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState: AddKeySpecificationsState = {
  isKeySpecificationsSuccess: false,
  isKeySpecificationsError: false,
  isKeySpecificationsFetching: false,
  errorKeySpecificationsMessage: '',
  keySpecificationsData: {
    car_id: 0,
    plate_letter_left: 'ه',
    plate_letter_middle: 'ط',
    plate_letter_right: 'د',
    plate_type: '1',
    plate_number: '8279',
    sequence_number: '730763710',
    ownerIdentityNumber: '1088940596',
    ownerDateOfBirthHijri: '17/06/1416'
  }
  // keySpecificationsData: {
  //   car_id: 0,
  //   plate_letter_left: "",
  //   plate_letter_middle: "",
  //   plate_letter_right: "",
  //   plate_type: "",
  //   sequence_number: "",
  //   ownerIdentityNumber: "",
  //   ownerDateOfBirthHijri: "",
  //   plate_number: "",
  // },
};

export const AddKeySpecificationsSlice = createSlice({
  name: 'addKeySpecifications',
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.isKeySpecificationsSuccess = false;
      state.isKeySpecificationsError = false;
      state.isKeySpecificationsFetching = false;
      state.errorKeySpecificationsMessage = '';
      state.keySpecificationsData.car_id = 0;
      state.keySpecificationsData.plate_letter_left = '';
      state.keySpecificationsData.plate_letter_middle = '';
      state.keySpecificationsData.plate_letter_right = '';
      state.keySpecificationsData.plate_type = '';
      state.keySpecificationsData.sequence_number = '';
      state.keySpecificationsData.ownerIdentityNumber = '';
      state.keySpecificationsData.ownerDateOfBirthHijri = '';
      state.keySpecificationsData.plate_number = '';
      return state;
    },
    setKeySpecificationsFormState: (
      state,
      action: PayloadAction<AddKeySpecificationsRequest>
    ) => {
      state.keySpecificationsData.car_id = action.payload.car_id;
      state.keySpecificationsData.plate_letter_left =
        action.payload.plate_letter_left;
      state.keySpecificationsData.plate_letter_middle =
        action.payload.plate_letter_middle;
      state.keySpecificationsData.plate_letter_right =
        action.payload.plate_letter_right;
      state.keySpecificationsData.plate_type = action.payload.plate_type;
      state.keySpecificationsData.sequence_number =
        action.payload.sequence_number;
      state.keySpecificationsData.ownerIdentityNumber =
        action.payload.ownerIdentityNumber;
      state.keySpecificationsData.ownerDateOfBirthHijri =
        action.payload.ownerDateOfBirthHijri;
      state.keySpecificationsData.plate_number = action.payload.plate_number;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addKeySpecificationsAsync.fulfilled, (state, action: any) => {
        state.isKeySpecificationsFetching = false;
        state.isKeySpecificationsSuccess = true;
        return state;
      })
      .addCase(addKeySpecificationsAsync.rejected, (state, action: any) => {
        state.isKeySpecificationsFetching = false;
        state.isKeySpecificationsError = true;
        state.errorKeySpecificationsMessage = action.payload.userMessage;
      })
      .addCase(addKeySpecificationsAsync.pending, (state) => {
        state.isKeySpecificationsFetching = true;
      });
  }
});

export default AddKeySpecificationsSlice.reducer;

export const { clearState, setKeySpecificationsFormState } =
  AddKeySpecificationsSlice.actions;

export const addKeySpecificationsSelector = (state: RootState) =>
  state.addKeySpecifications;
