import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { RootState } from '../../app/store';
import { getJsonParse } from '../../util/util';
import { loginGetOtp, loginReq, verifyOtp, VerifyOTPRequest } from './loginAPI';

export const loginAsync = createAsyncThunk(
  'login/start',
  async (req: loginReq, thunkAPI) => {
    try {
      const response = await loginGetOtp(req);
      if (response.status === 200) {
        return {
          status: response.status,
          phonecode: req.phonecode,
          phonenumber: req.phonenumber
        };
        // return thunkAPI.fulfillWithValue(response.status);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log('Error', e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const verifyLoginAsync = createAsyncThunk(
  'login/verify',
  async (req: VerifyOTPRequest, thunkAPI) => {
    try {
      const response = await verifyOtp(req);
      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log('Error', e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: 'AuthUser',
  initialState: {
    isSuccess: false,
    isError: false,
    isFetching: false,
    errorMessage: '',
    phonecode: '',
    phonenumber: '',
    isVerified: false,
    userData: null as any,
    token: '',
    isAuth: false,
    language: 'ar',
    userAccessInfo: null as {
      userID: number;
      roleId: number;
      data: {
        info_id: number;
        firstname: string;
        lastname: string;
        email: string;
        country_code: number;
        phonenumber: string;
        dob: string;
        image_path: string;
        location: string;
        latitude: string;
        longitude: string;
        license_number: string;
        file_path: string;
      };
    } | null
  },
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      const language = action.payload === 'en' ? 'en' : 'ar';
      localStorage.setItem('language', language);
      state.language = action.payload;
      return state;
    },
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      return state;
    },
    logout: (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('userData');
      state.isAuth = false;
      state.token = '';
      return state;
    },
    authStateUpdate: (state) => {
      const userDataStr = localStorage.getItem('userData');
      const token = localStorage.getItem('token');
      const language = localStorage.getItem('language') === 'en' ? 'en' : 'ar';
      if (token) {
        if (userDataStr) {
          state.userData = getJsonParse(userDataStr);
        }
        state.userAccessInfo = jwtDecode(token);
        state.token = token;
        state.isAuth = true;
      } else {
        state.token = '';
        state.isAuth = false;
      }
      state.language = language;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.fulfilled, (state, action: any) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.phonecode = action.payload.phonecode;
        state.phonenumber = action.payload.phonenumber;
        return state;
      })
      .addCase(loginAsync.rejected, (state, action: any) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload.userMessage;
      })
      .addCase(loginAsync.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(verifyLoginAsync.fulfilled, (state, action: any) => {
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('userData', getJsonParse(action.payload.data));

        state.userAccessInfo = jwtDecode(action.payload.token);
        state.token = action.payload.token;
        state.userData = action.payload.data;
        state.isFetching = false;
        state.isVerified = true;
        state.isAuth = true;
        return state;
      })
      .addCase(verifyLoginAsync.rejected, (state, action: any) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload.userMessage;
      })
      .addCase(verifyLoginAsync.pending, (state) => {
        state.isFetching = true;
      });
  }
});

export default authSlice.reducer;

export const { clearState, authStateUpdate, logout } = authSlice.actions;

export const userSelector = (state: RootState) => state.AuthUser;

export const checkIsAuth = (state: RootState) => state.AuthUser.isAuth;

export const userAccessInfoSelector = (state: RootState) =>
  state.AuthUser.userAccessInfo;

export const userDataSelector = (state: RootState) => state.AuthUser.userData;

export const userRoleSelectorSelector = (state: RootState) =>
  state.AuthUser.userAccessInfo?.roleId;

export const userIdSelectorSelector = (state: RootState) =>
  state.AuthUser.userAccessInfo?.userID;

export const languageSelectorSelector = (state: RootState) =>
  state.AuthUser.language;
