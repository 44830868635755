import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';
import { CarImage } from '../../../util/types/car';
import dummyCar from './../../../assets/img/dummy.png';

export default function CarImages(props: { images: CarImage[] }) {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false
        }}
        pagination={{
          clickable: true
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className='mySwiper'
      >
        {props?.images?.length > 0 ? (
          props.images?.map((img) => (
            <SwiperSlide>
              <img src={img.path} />
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide>
            <img src={dummyCar} />
          </SwiperSlide>
        )}
      </Swiper>
    </>
  );
}
