import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { GetData } from "../../../Api/ApiMgr";
import { ApiData } from "../../../Api/url";
import { userIdSelectorSelector } from "../../login/authSlice";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  CardMedia,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { fullButtonRed } from "../../../mui-sx/button";
import { CustomModal } from "../../../components/elements/modal/modal";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";

function Trips() {
  const idRef = React.useRef("");
  const params = useParams();
  const userID = useSelector(userIdSelectorSelector);
  const [TripDetails, setTripDetails] = React.useState<any>("");
  const [CarData, setCarData] = React.useState<any>([]);
  const [Type, setType] = useState("upcomming");
  const [TripId, setTripId] = useState(0);
  const [OpenEndTripModal, setOpenEndTripModal] =
    React.useState<boolean>(false);

  useEffect(() => {
    tripUpcoming(userID);
  }, [userID]);

  useEffect(() => {
    if (params.id && userID) {
      try {
        const info = JSON.parse(window.atob(params.id));
        console.log(info);
        handleMyCars(info);
      } catch (e) {}
    }
  }, [params.id, userID]);

  const handleChange3 = (event: React.SyntheticEvent, newValue: string) => {
    setType(newValue);
    if (newValue === "upcomming") {
      tripUpcoming(userID);
    } else if (newValue === "past") {
      tripPast(userID);
    }
  };

  const tripDetails = (item: any) => {
    if (userID !== undefined && item.trip_id && item.car_id) {
      var data = {
        user_id: userID,
        trip_id: item.trip_id,
        car_id: item.car_id,
      };
      GetData(data, ApiData.tripDetails).then((res) => {
        setTripDetails(res.data);
        setTripId(res.data["trip_id"]);
      });
    }
  };

  const tripUpcoming = (userID: any) => {
    if (userID !== undefined) {
      var data = {
        user_id: userID,
      };
      GetData(data, ApiData.tripUpcoming).then((res) => {
        // handleMyCars(res.data[0]["car_id"]);
        setCarData(res.data);
      });
    }
  };

  const handleMyCars = (item: any) => {
    idRef.current = item.trip_id;
    tripDetails(item);
  };

  const tripPast = (userID: any) => {
    if (userID !== undefined) {
      var data = {
        user_id: userID,
      };
      GetData(data, ApiData.tripPast).then((res) => {
        setCarData(res.data);
      });
    }
  };

  const startTrip = () => {
    if (userID !== undefined) {
      var data = {
        user_id: 8,
        trip_id: TripId,
      };
      GetData(data, ApiData.start_trip).then((res) => {
        alert(res.resp["data"].response.devMessage);
        tripUpcoming(userID);
        setOpenEndTripModal(false);
      });
    }
  };
  const cancelTrip = (item: any) => {
    if (userID !== undefined && TripDetails) {
      var data = {
        user_id: 8,
        trip_id: TripId,
        paid_from: item.paid_from,
        paid_ip: item.paid_ip,
        car_id: TripDetails.car_id,
      };
      GetData(data, ApiData.cancel_trip).then((res) => {
        alert(res.resp["data"].response.devMessage);
        tripUpcoming(userID);
        setOpenEndTripModal(false);
      });
    }
  };
  const endTrip = () => {
    if (userID !== undefined) {
      var data = {
        user_id: 8,
        trip_id: TripId,
      };
      GetData(data, ApiData.end_trip).then((res) => {
        alert(res.resp["data"].response.devMessage);

        tripUpcoming(userID);
        setOpenEndTripModal(false);
      });
    }
  };

  const handleClose = () => {
    tripUpcoming(userID);
    setOpenEndTripModal(false);
  };

  return (
    <Box
      sx={{
        marginTop: "110px",
        width: "96%",
        marginLeft: "2%",
        marginRight: "2%",
      }}
    >
      <Grid
        container
        spacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        margin={"10px"}
        justifyItems={"center"}
        justifyContent={"center"}
      >
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Box
            sx={{
              border: "0.001rem solid #8080803d",
              borderRadius: "15px",
              height: "100%",
              position: "relative",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: "25px", margin: "15px" }}>
                Trips
              </Typography>
            </Box>

            <TabContext value={Type}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange3}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Upcomming"
                    value="upcomming"
                    onClick={tripUpcoming}
                  />
                  <Tab label="Past" value="past" onClick={tripPast} />
                </TabList>
              </Box>
              <TabPanel value={Type}>
                {CarData && (
                  <>
                    <Box
                      className="mostly-customized-scrollbar"
                      sx={{
                        borderRadius: "15px",
                        color: "black",
                      }}
                    >
                      <Grid container>
                        {CarData.length > 0 && Type ? (
                          CarData.map((item: any, i: any) => {
                            return (
                              <Box
                                onClick={() => handleMyCars(item)}
                                sx={{
                                  width: "inherit",
                                  display: "flex",
                                  borderRadius: 2,
                                  cursor: "pointer",
                                }}
                                key={i}
                                className={`myCars${item.trip_id}`}
                                style={{
                                  background: `${
                                    item?.trip_id === idRef.current
                                      ? "#E85656"
                                      : "white"
                                  }`,
                                  color: `${
                                    item?.trip_id === idRef.current
                                      ? "white"
                                      : "black"
                                  }`,
                                }}
                              >
                                <Grid item lg={4} md={6} sm={12} xs={12}>
                                  <CardMedia
                                    sx={{
                                      margin: "15px",
                                      objectFit: "cover",
                                      display: "flex",
                                      borderRadius: 2,
                                      height: "70px",
                                      width: "100px",
                                    }}
                                    component={"img"}
                                    src={item.image}
                                  />
                                </Grid>
                                <Grid item lg={8} md={6} sm={12} xs={12}>
                                  <Box sx={{ margin: "15px" }} key={i}>
                                    <Typography>
                                      {item.car_make} {item.car_model}
                                    </Typography>
                                    <Typography>
                                      {format(
                                        new Date(item.end_date),
                                        "dd/mm/yyyy"
                                      )}{" "}
                                      to{" "}
                                      {format(
                                        new Date(item.end_date),
                                        "dd/mm/yyyy"
                                      )}
                                    </Typography>
                                    <Typography>
                                      {item.currency_code} {item.total_amount}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Box>
                            );
                          })
                        ) : (
                          <Box sx={{ margin: "auto" }}>
                            <HighlightOffIcon
                              sx={{
                                color: "#00000091",
                                fontSize: 50,
                                display: "block",
                                margin: "auto",
                              }}
                            />
                            <Typography
                              sx={{
                                color: "#00000091",
                                fontSize: "22px",
                                textAlign: "center",
                              }}
                            >
                              No Cars Found
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                    </Box>
                  </>
                )}
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <Box
            sx={{
              border: "0.001rem solid #8080803d",
              borderRadius: "18px",
              padding: 3,
              justifyContent: "space-around",
            }}
          >
            <Box>
              {TripDetails ? (
                <Grid container>
                  <Grid item xs={4} sx={{ margin: "auto" }}>
                    <Typography sx={{ fontSize: "22px" }}>
                      {TripDetails.car_make} {TripDetails.car_model}
                    </Typography>
                  </Grid>

                  <Grid item xs={8}>
                    <Box
                      sx={{
                        margin: "20px",
                        justifyContent: "end",
                        display: "flex",
                      }}
                    >
                      {TripDetails.status === "approved" && (
                        <Button
                          variant="contained"
                          sx={{
                            ...fullButtonRed,
                            m: 2,
                            width: "9rem",
                          }}
                          onClick={startTrip}
                        >
                          Start Trip
                        </Button>
                      )}
                      {TripDetails.status === "approved" && (
                        <Button
                          variant="contained"
                          sx={{
                            ...fullButtonRed,
                            m: 2,
                            width: "9rem",
                          }}
                          onClick={cancelTrip}
                        >
                          Cancel Trip
                        </Button>
                      )}
                      {(TripDetails.status === "booked" ||
                        TripDetails.status === "ongoing") && (
                        <Button
                          variant="contained"
                          sx={{
                            ...fullButtonRed,
                            m: 2,
                            width: "8rem",
                          }}
                          onClick={() => setOpenEndTripModal(true)}
                        >
                          End Trip
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Box>
            <Box
              sx={{
                // display: "flex",
                // backgroundColor: "#E85656",
                borderRadius: "15px",
                color: "black",
                // color: "white",
              }}
            >
              <Grid container>
                {TripDetails ? (
                  <Grid item xs={12}>
                    <Box sx={{ cursor: "context-menu" }}>
                      <Divider sx={{ width: "100%" }}></Divider>
                      <Box sx={{ margin: "20px" }}>
                        <Typography>Dates</Typography>
                        <Box sx={{ display: "flex", marginTop: "5px" }}>
                          <Grid container sx={{ display: "flex" }}>
                            <Grid item xs={6}>
                              <Typography sx={{ color: "black" }}>
                                Trip Start
                              </Typography>
                              <Typography sx={{ fontWeight: "bold" }}>
                                {TripDetails.from_date}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography sx={{ color: "black" }}>
                                Trip End
                              </Typography>
                              <Typography sx={{ fontWeight: "bold" }}>
                                {TripDetails.end_date}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                      <Divider sx={{ width: "100%" }}></Divider>
                      <Box sx={{ margin: "20px" }}>
                        <Typography>Traveller</Typography>
                        <Box>
                          <Grid container>
                            <Grid item xs={4} sx={{ display: "flex" }}>
                              {TripDetails["ownerDetails"].image_path !==
                              null ? (
                                <img
                                  src={TripDetails["ownerDetails"].image_path}
                                  style={{
                                    width: "45px",
                                    height: "45px",
                                    borderRadius: "25PX",
                                  }}
                                />
                              ) : (
                                <AccountCircleIcon
                                  sx={{
                                    color: "#00000091",
                                    fontSize: 50,
                                    display: "block",
                                    // margin: "auto",
                                  }}
                                />
                              )}
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", width: "100%" }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    margin: "auto",
                                  }}
                                >
                                  {TripDetails.traveller_name}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                      <Divider sx={{ width: "100%" }}></Divider>
                      <Box sx={{ margin: "20px" }}>
                        <Typography>Pick and Drop</Typography>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {TripDetails.pickup}
                        </Typography>
                      </Box>
                      <Divider sx={{ width: "100%" }}></Divider>
                      <Box sx={{ margin: "20px" }}>
                        <Typography>Destination</Typography>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {TripDetails.pickup}
                        </Typography>
                      </Box>
                      <Divider sx={{ width: "100%" }}></Divider>
                      <Box sx={{ margin: "20px" }}>
                        <Typography>Trip Details</Typography>
                        <Box sx={{ display: "flex", marginTop: "5px" }}>
                          <Grid container sx={{ display: "flex" }}>
                            <Grid item xs={2}>
                              <Typography sx={{ color: "black" }}>
                                Duration
                              </Typography>
                              <Typography sx={{ fontWeight: "bold" }}>
                                {TripDetails.duration}{" "}
                                {TripDetails.duration_type}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography sx={{ color: "black" }}>
                                Trip Price
                              </Typography>
                              <Typography sx={{ fontWeight: "bold" }}>
                                {TripDetails.currency_code}{" "}
                                {TripDetails.trip_rate}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography sx={{ color: "black" }}>
                                Booked On
                              </Typography>
                              <Typography sx={{ fontWeight: "bold" }}>
                                {TripDetails.booked_on}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ) : (
                  <Box sx={{ margin: "auto" }}>
                    <HighlightOffIcon
                      sx={{
                        color: "#00000091",
                        fontSize: 50,
                        display: "block",
                        margin: "auto",
                      }}
                    />
                    <Typography
                      sx={{
                        color: "#00000091",
                        fontSize: "22px",
                        textAlign: "center",
                      }}
                    >
                      Please select Car
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {TripDetails && (
        <CustomModal open={OpenEndTripModal} toggleModal={setOpenEndTripModal}>
          <Grid container>
            <CloseIcon
              sx={{
                fontSize: 50,
                display: "block",
                width: "0.51em",
                top: "0px",
                position: "absolute",
                marginTop: "0px",
                marginRight: "0px",
                marginLeft: "85%",
              }}
              onClick={() => setOpenEndTripModal(false)}
            />
            <Grid item xs={12}>
              <Box margin={3}>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      margin: "revert",
                    }}
                  >
                    End Trip
                  </Typography>
                  <Typography>Do you really want to end this trip?</Typography>
                </Box>
                <Box sx={{ marginLeft: "auto", padding: "10px" }}>
                  <Button
                    variant="contained"
                    sx={{
                      ...fullButtonRed,
                      width: "5rem",
                      margin: "10px",
                    }}
                    onClick={handleClose}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      ...fullButtonRed,
                      width: "fit-content",
                      margin: "10px",
                    }}
                    onClick={endTrip}
                  >
                    End
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CustomModal>
      )}
    </Box>
  );
}

export default Trips;
