export type CarsPointerProps = {
  text: string;
  lat: number;
  lng: number;
  carId: string;
};

export const CarsPointer = (props: CarsPointerProps) => (
  <div
    style={{
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 6px #00000029',
      color: '#1A281F',
      font: 'normal normal 14px/20px Work Sans',
      width: 'max-content',
      padding: '2px 8px',
      height: 'auto'
    }}
  >
    {props.text}
  </div>
);
