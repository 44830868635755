import axios from 'axios';
import { LatLng } from '../../../redux/locationSlice';
import config from '../../../util/config.js';
import { NearByLocation } from '../../../util/types/location';

export interface GetCarsListRequest extends LatLng {
  car_make?: string;
  car_category?: string;
  car_model?: string;
  trip_type?: string;
  rating?: string;
  trip_duration?: string;
  price_min?: string;
  price_max?: string;
  user_id?: string;
  features?: string[];
};

export interface SearchCarRequest extends LatLng {
  from_date?: string;
  end_date?: string;
  car_make?: string;
  car_category?: string;
  car_model?: string;
  trip_type?: string;
  rating?: string;
  trip_duration?: string;
  price_min?: string;
  price_max?: string;
  user_id?: string;
  features?: string[];
};

export async function getCarsList (req: GetCarsListRequest) {
  const data: GetCarsListRequest = {
    latitude: req.latitude,
    longitude: req.longitude,
    user_id: req.user_id
  };
  try {
    const response = await axios.post(config.BASE_URL + 'current-deals', data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.data.response;
  } catch (error: any) {
    throw new Error(error);
  }
};

export async function getSearchCarsList (req: SearchCarRequest) {
  const data: SearchCarRequest = {
    latitude: req.latitude,
    longitude: req.longitude,
    from_date: req.from_date,
    end_date: req.end_date,
    car_category: req.car_category,
    car_make: req.car_make,
    car_model: req.car_model,
    trip_type: req.trip_type,
    trip_duration: req.trip_duration,
    user_id: req.user_id
  };

  try {
    const response = await axios.post(config.BASE_URL + 'availability', data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.data.response;
  } catch (error: any) {
    throw new Error(error);
  }
};

export async function getCarCategory () {
  try {
    const response = await axios.get(config.BASE_URL + 'dropdowns/car_category');
    return response.data.response;
  } catch (error: any) {
    throw new Error(error);
  }
};

export async function getCarMake () {
  try {
    const response = await axios.post(config.BASE_URL + 'dropdowns/car_make');
    return response.data.response;
  } catch (error: any) {
    throw new Error(error);
  }
};

export async function getCarModels (selectedMakeId : string) {
  const data = {
    car_make_id: selectedMakeId
  };
  try {
    const response = await axios.post(config.BASE_URL + 'dropdowns/car_model', data);
    return response.data.response;
  } catch (error: any) {
    throw new Error(error);
  }
};

export async function getCarTransmission () {
  try {
    const response = await axios.get(config.BASE_URL + 'dropdowns/car_transmission');
    return response.data.response;
  } catch (error: any) {
    throw new Error(error);
  }
};

export async function getCarFeatures () {
  try {
    const response = await axios.get(config.BASE_URL + 'dropdowns/car_features');
    return response.data.response;
  } catch (error: any) {
    throw new Error(error);
  }
};

export async function getNearByLocation (req: LatLng): Promise<NearByLocation[]> {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${req.latitude}%2C${req.longitude}&radius=50000&key=AIzaSyB8svhnyEyYYRYu9phI3afRxNjodA0eiYE`
      // `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${req.latitude}%2C${req.longitude}&radius=100000&type=city&keyword=top&key=AIzaSyB8svhnyEyYYRYu9phI3afRxNjodA0eiYE`
    );
    return response.data.response;
  } catch (error: any) {
    return []
  }
}

