import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export const latLngConst = {
  latitude: 26.2172,
  longitude: 50.1971
};

export type LocationSlice = {
  latLng: LatLng;
  formattedAddress: string;
};

export interface LatLng {
  latitude: number;
  longitude: number;
}

const initialState: LocationSlice = {
  latLng: {
    ...latLngConst
  },
  formattedAddress: ''
};

export const locationSlice = createSlice({
  name: 'LocationSlice',
  initialState,
  reducers: {
    setLatLng: (state, action: PayloadAction<LocationSlice>) => {
      state.latLng = action.payload.latLng;
      state.formattedAddress = action.payload.formattedAddress;
    }
  }
});

export default locationSlice.reducer;

export const { setLatLng } = locationSlice.actions;

export const getLatLng = (state: RootState) => state.location;
