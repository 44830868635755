import { Alert, Card, Container, FormControl, Typography } from '@mui/material';
import { fullButtonRed } from '../../../mui-sx/button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { clearState, loginAsync, AuthUserSelector } from './corporateAuthSlice';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';

interface CorporateLoginState {
  // amount: string;
  email: string;
  password: string;
  // weight: string;
  // weightRange: string;
  showPassword: boolean;
}

export function CorporateLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSuccess, isError, isVerified, errorMessage, isFetching } =
    useSelector(AuthUserSelector);

  const [values, setValues] = useState<CorporateLoginState>({
    // amount: "",
    email: '',
    password: '',
    // weight: "",
    // weightRange: "",
    showPassword: false
  });

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      navigate('/dashboard');
    }
  }, [dispatch, errorMessage, isError, isSuccess, isVerified]);

  const handleChangePassword =
    (prop: keyof CorporateLoginState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleChangeEmail =
    (prop: keyof CorporateLoginState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const login = () => {
    dispatch(clearState());
    const email = values.email;
    const password = values.password;
    if (email && password) {
      dispatch(loginAsync({ email, password }));
    }
  };

  return (
    <Container maxWidth={'md'} className='home-container'>
      <Card
        sx={{
          p: 3,
          mt: 5
        }}
      >
        <Typography
          variant='h5'
          component='h2'
          sx={{
            font: 'normal normal 22px/28px Work Sans'
          }}
        >
          Welcome to Sharik
        </Typography>
        <Box
          sx={{
            maxWidth: '100%',
            m: 5
          }}
        >
          {errorMessage && (
            <Alert
              color='error'
              sx={{
                mb: 3
              }}
            >
              {errorMessage}
            </Alert>
          )}
          <TextField
            label='Email'
            variant='outlined'
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                login();
              }
            }}
            fullWidth
            onChange={handleChangeEmail('email')}
          />
          <FormControl sx={{ mt: 3, mb: 5 }} fullWidth variant='outlined'>
            <InputLabel>Password</InputLabel>
            <OutlinedInput
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChangePassword('password')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  login();
                }
              }}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label='Password'
            />
          </FormControl>
          <Link
            style={{
              float: 'right'
            }}
            to={'/forgot'}
          >
            Forgot Password
          </Link>
        </Box>
        <LoadingButton
          variant='contained'
          loading={isFetching}
          loadingIndicator='Loging In...'
          sx={{
            ...fullButtonRed
          }}
          onClick={() => {
            login();
          }}
        >
          Login
        </LoadingButton>
      </Card>
    </Container>
  );
}
