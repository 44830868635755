import { Container, Divider, Grid, Typography } from '@mui/material';
import googleLogo from './../../../assets/img/google-play-badge-2x.png';
import appleLogo from './../../../assets/img/App_Store_Badge.svg';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/system';
import InstagramIcon from '@mui/icons-material/Instagram';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';

const Footer = () => {
  return (
    <Container
      maxWidth={'xl'}
      style={{
        marginTop: '4em'
      }}
    >
      <Grid container>
        <Grid container item>
          <Grid item xs={2.5}>
            <Typography variant="h6" fontWeight={600}>
              Sharik
            </Typography>
            <Typography>About</Typography>
            <Typography>Team</Typography>
            <Typography>Policies</Typography>
            <Typography>Careers</Typography>
            <Typography>Press</Typography>
            <Typography>Help Center</Typography>
            <Typography>Sharik Shop</Typography>
          </Grid>
          <Grid item xs={2.5}>
            <Typography variant="h6" fontWeight={600}>
              Locations
            </Typography>
            <Typography>Saudi Arabia</Typography>
            <Typography>Egypt</Typography>
            <Typography>UAE</Typography>
            <Typography>Qatar</Typography>
            <Typography>Kuwait</Typography>
            <Typography>Bahrain</Typography>
            <Typography>Oman</Typography>
          </Grid>
          <Grid item xs={2.5}>
            <Typography variant="h6" fontWeight={600}>
              Explore
            </Typography>
            <Typography>Book a Car</Typography>
            <Typography>Weddings</Typography>
            <Typography>Trust & safety</Typography>
            <Typography>Sustainability</Typography>
            <Typography>FAQs</Typography>
            <Typography>Get help</Typography>
          </Grid>
          <Grid item xs={2.5}>
            <Typography variant="h6" fontWeight={600}>
              Hosting
            </Typography>
            <Typography>List your car</Typography>
            <Typography>Calculator</Typography>
            <Typography>All-Star Hosts</Typography>
            <Typography>Host tools</Typography>
            <Typography>Insurance & protection</Typography>
            <Typography>FAQs</Typography>
          </Grid>
          <Grid item xs={2}>
            <img
              src={appleLogo}
              style={{
                width: '160px'
              }}
            />
            <img
              src={googleLogo}
              style={{
                width: '183px',
                marginLeft: '-8px'
              }}
            />
            <FormControl
              sx={{
                border: 'none'
              }}
            >
              <Select
                defaultValue={'Arabic'}
                sx={{
                  border: 'none'
                }}
              >
                <MenuItem value={'Arabic'}>Arabic</MenuItem>
                <MenuItem value={'English'}>English</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          mt: 5
        }}
      >
        <Grid container item>
          <Grid item xs={2.5}>
            <Typography variant="h6" fontWeight={600}>
              Vehicle Category
            </Typography>
            <Typography>Exotic & Luxury</Typography>
            <Typography>Convertibles</Typography>
            <Typography>Sport</Typography>
            <Typography>Classics</Typography>
            <Typography>Minivans</Typography>
            <Typography>SUVs</Typography>
            <Typography>Cars</Typography>
          </Grid>
          <Grid item xs={2.5}>
            <Typography variant="h6" fontWeight={600}>
              Makes
            </Typography>
            <Typography>Mercedes</Typography>
            <Typography>BMW</Typography>
            <Typography>Range Rover</Typography>
            <Typography>Toyota</Typography>
            <Typography>Nissan</Typography>
            <Typography>Hyundai</Typography>
            <Typography>Changan</Typography>
          </Grid>
          <Grid item xs={2.5}>
            <Typography variant="h6" fontWeight={600} color={'white'}>
              Makes
            </Typography>
            <Typography>Mercedes</Typography>
            <Typography>BMW</Typography>
            <Typography>Range Rover</Typography>
            <Typography>Toyota</Typography>
            <Typography>Nissan</Typography>
            <Typography>Hyundai</Typography>
            <Typography>Changan</Typography>
          </Grid>
          <Grid item xs={2.5}>
            <Typography variant="h6" fontWeight={600}>
              Top Cities
            </Typography>
            <Typography>Riyadh</Typography>
            <Typography>Jeddah</Typography>
            <Typography>Mecca</Typography>
            <Typography>Medina</Typography>
            <Typography>Hofuf</Typography>
            <Typography>{'Ta\'lf'}</Typography>
            <Typography>Dammam</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6" fontWeight={600} color={'white'}>
              Top Cities
            </Typography>
            <Typography>Riyadh</Typography>
            <Typography>Jeddah</Typography>
            <Typography>Mecca</Typography>
            <Typography>Medina</Typography>
            <Typography>Hofuf</Typography>
            <Typography>{'Ta\'lf'}</Typography>
            <Typography>Dammam</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        light={false}
        sx={{
          mt: 2,
          mb: 2,
          borderColor: 'black'
        }}
      />
      <Box
        display={'flex'}
        justifyContent="space-between"
        sx={{
          mb: 5
        }}
      >
        <Typography>
          © 2021 Sharik. <a href='/privacy-policy' style={{ textDecoration: "auto", color: "black" }}>Privacy Policy</a>{' '}
          <FiberManualRecordRoundedIcon
            sx={{
              fontSize: '10px'
            }}
          />{' '}
          <a href='/terms-conditions' style={{ textDecoration: "auto", color: "black" }}>Terms</a>
          {' '}
          <FiberManualRecordRoundedIcon
            sx={{
              fontSize: '10px'
            }}
          />{' '}
          Sitemap
        </Typography>
        <Box>
          <FacebookIcon />
          <TwitterIcon
            sx={{
              ml: 2,
              mr: 2
            }}
          />
          <InstagramIcon />
        </Box>
      </Box>
    </Container>
  );
};

export default Footer;
