import GoogleMapReact from 'google-map-react';
import { useSelector } from 'react-redux';
import { getLatLng } from '../../../redux/locationSlice';
import { CarsPointer } from './carsPointer';

export type CarListMapPointer = {
  text: string;
  lat: number;
  lng: number;
  carId: string;
};

export type CarListMapProps = {
  pointers: CarListMapPointer[];
};

export const CarListMap = (props: CarListMapProps) => {
  // const dispatch = useDispatch();

  const { latLng } = useSelector(getLatLng);

  const _onChildClick = (key: any, childProps: any) => {
    console.log(key, childProps);
  };

  const _onChildMouseEnter = (key: any, childProps: any) => {
    // console.log(key, childProps);
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: 'AIzaSyB8svhnyEyYYRYu9phI3afRxNjodA0eiYE'
      }}
      defaultCenter={{
        lat: latLng.latitude,
        lng: latLng.longitude
      }}
      onChange={(value: GoogleMapReact.ChangeEventValue) => {}}
      onClick={(value: GoogleMapReact.ClickEventValue) => {
        // console.log(value);
        // dispatch(setLatLng({ latitude: value.lat, longitude: value.lng }));
      }}
      defaultZoom={11}
      onChildClick={_onChildClick}
      onChildMouseEnter={_onChildMouseEnter}
      options={{
        clickableIcons: true
      }}
    >
      {props.pointers.map((pointer, key) => {
        return (
          <CarsPointer
            key={key}
            lat={pointer.lat}
            lng={pointer.lng}
            text={pointer.text}
            carId={pointer.carId}
          />
        );
      })}
    </GoogleMapReact>
  );
};
