import { SxProps } from '@mui/material';

export const button: SxProps | undefined = {
  height: '48px',
  borderRadius: '10px',
  color: '#000',
  font: 'normal bold 16px/22px Work Sans'
};
// font: "normal bold 20px/22px Work Sans",

export const fullButton: SxProps | undefined = {
  ...button,
  font: 'normal normal 20px/22px Work Sans',
  width: '100%'
};

export const buttonRed: SxProps | undefined = {
  ...button,
  color: '#fff',
  background: '#E85656 0% 0% no-repeat padding-box',
  '&:hover': {
    backgroundColor: '#E85656'
  }
};

export const fullButtonRed: SxProps | undefined = {
  ...fullButton,
  color: '#fff',
  font: 'normal normal 20px/22px Work Sans',
  background: '#E85656 0% 0% no-repeat padding-box',
  '&:hover': {
    backgroundColor: '#E85656'
  }
};
