import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import SharikRoutes from './routes/Routes';
import axios from 'axios';
import { logout as logoutCor } from './features/corporate/login/corporateAuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { languageSelectorSelector, logout } from './features/login/authSlice';
import { useEffect } from 'react';
import Geocode from 'react-geocode';
import { setLatLng } from './redux/locationSlice';

/** Create Global Theme */
const theme = createTheme({
  typography: {
    fontFamily: 'Work Sans',
    button: {
      textTransform: 'none',
      fontWeight: 700
    }
  }
});

function App() {
  const dispatch = useDispatch();
  const language = useSelector(languageSelectorSelector);

  axios.interceptors.request.use(function (config) {
    if (!config.headers) {
      config.headers = {
        language: language
      };
    } else {
      config.headers['app-lang'] = language;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        dispatch(logoutCor());
        dispatch(logout());
        window.location.href = '/';
      }
    }
  );

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const pos = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      };
      try {
        Geocode.setApiKey('AIzaSyB8svhnyEyYYRYu9phI3afRxNjodA0eiYE');
        const address = await Geocode.fromLatLng(
          pos.latitude + '',
          pos.longitude + ''
        );
        dispatch(
          setLatLng({
            latLng: { latitude: pos.latitude, longitude: pos.longitude },
            formattedAddress: address.results[0].formatted_address
          })
        );
      } catch (error) {
        dispatch(
          setLatLng({
            latLng: { latitude: pos.latitude, longitude: pos.longitude },
            formattedAddress: ''
          })
        );
      }
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SharikRoutes />
    </ThemeProvider>
  );
}

export default App;
