import axios from 'axios';
import { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import config from '../../../util/config.js';
import { carObj, ResponseInterface } from './../../../util/types';
import { Card } from '@mui/material';
import { SideBar } from '../sidebar/sidebar';
import { useSelector } from 'react-redux';
import { userAccessInfoSelector } from '../login/corporateAuthSlice';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

export default function MyCars() {
  const [carList, setCarList] = useState<carObj[]>([]);
  const userData = useSelector(userAccessInfoSelector);

  const statusUpdate = (car_id: any, status: any) => {
    if (userData?.userID) {
      axios
        .patch(
          config.BASE_URL + 'car/status/' + car_id,
          {
            status: status
          },
          {
            headers: {
              'x-access-token': localStorage.getItem('token') || '',
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )
        .then((res: ResponseInterface<carObj[]>) => {
          getMyCars();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const deleteCar = (car_id: any, userId: any) => {
    if (userData?.userID) {
      axios
        .patch(
          config.BASE_URL + 'car/delete/' + car_id,
          { user_id: userId },
          {
            headers: {
              'x-access-token': localStorage.getItem('token') || '',
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )
        .then((res: ResponseInterface<carObj[]>) => {
          getMyCars();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const statusAdminUpdate = (car_id: any, status: any, userId: any) => {
    if (userData?.userID) {
      axios
        .patch(
          config.BASE_URL + '/car/admin-status/' + car_id,
          {
            status: status,
            user_id: userId
          },
          {
            headers: {
              'x-access-token': localStorage.getItem('token') || '',
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )
        .then((res: ResponseInterface<carObj[]>) => {
          getMyCars();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const columns: GridColDef<carObj>[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'car_make',
      headerName: 'Make Car',
      width: 120
    },
    {
      field: 'car_model',
      headerName: 'Car Model',
      width: 120
    },
    {
      field: 'car_category',
      headerName: 'Car Category',
      width: 120
    },
    {
      field: 'duration_parameter',
      headerName: 'Duration Parameter',
      width: 180
    },
    {
      field: 'rental_rate',
      headerName: 'Rental Rate',
      width: 120
    },
    {
      field: 'trip_type',
      headerName: 'Car Type',
      width: 120
    },
    // {
    //   field: 'rating',
    //   headerName: 'Rating',
    //   width: 120
    // },
    {
      field: 'verified_str',
      headerName: 'Corporate Approval',
      width: 150
    },
    {
      field: 'status_str',
      headerName: 'Status',
      width: 120
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 120,
      renderCell: (params: GridRenderCellParams<any, carObj, any>) => {
        return (
          <>
            {(userData?.roleId === 5 && parseInt(params.row.verified) === 1) ||
            (userData?.roleId === 6 && parseInt(params.row.status) === 1) ? (
              <VisibilityOffIcon
                color='error'
                titleAccess={userData?.roleId === 5 ? 'Reject' : 'De-Activete'}
                sx={{
                  cursor: 'pointer'
                }}
                onClick={() => {
                  if (userData?.roleId === 5) {
                    statusAdminUpdate(
                      params.row.car_id,
                      'reject',
                      params.row.owner_id
                    );
                  } else {
                    statusUpdate(params.row.car_id, 'inactive');
                  }
                }}
              />
            ) : (
              <VisibilityIcon
                color='success'
                sx={{
                  cursor: 'pointer'
                }}
                titleAccess={userData?.roleId === 5 ? 'Verify' : 'Activate'}
                onClick={() => {
                  if (userData?.roleId === 5) {
                    statusAdminUpdate(
                      params.row.car_id,
                      'verify',
                      params.row.owner_id
                    );
                  } else {
                    statusUpdate(params.row.car_id, 'active');
                  }
                }}
              />
            )}
            <DeleteIcon
              sx={{
                cursor: 'pointer',
                ml: 2
              }}
              titleAccess='Delete Car'
              onClick={() => {
                deleteCar(
                  params.row.car_id,
                  userData?.roleId === 5
                    ? params.row.owner_id
                    : userData?.userID
                );
              }}
            />
          </>
        );
      }
    },
    {
      field: 'view',
      headerName: 'View',
      width: 120,
      renderCell: (params: GridRenderCellParams<any, carObj, any>) => {
        return userData?.roleId === 5 ? (
          <Link to={'/car/' + params.row.car_id + '/' + params.row.owner_id}>
            View
          </Link>
        ) : (
          <Link to={'/car/' + params.row.car_id}>View</Link>
        );
      }
    }
  ];

  useEffect(() => {
    getMyCars();
  }, [userData]);

  const getMyCars = () => {
    if (userData?.userID) {
      axios
        .post(
          config.BASE_URL + 'listed-cars',
          {
            user_id: userData.userID
          },
          {
            headers: {
              'x-access-token': localStorage.getItem('token') || '',
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )
        .then((res: ResponseInterface<carObj[]>) => {
          const data =
            res.data?.response?.data?.map((ele) => {
              return {
                ...ele,
                id: ele.car_id,
                status_str: parseInt(ele.status) === 1 ? 'Active' : 'In-Active',
                verified_str:
                  parseInt(ele.verified) === 1 ? 'Verified' : 'Pending'
              };
            }) || [];

          setCarList(data);
        });
    }
  };
  if (userData?.roleId === 5) {
    columns.splice(1, 0, {
      field: 'title',
      headerName: 'Branch',
      width: 150
    });
  }
  return (
    <SideBar>
      <Card
        sx={{
          height: '650px',
          p: 2
        }}
      >
        <DataGrid
          rows={carList}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          // checkboxSelection
          showCellRightBorder
          disableSelectionOnClick
        />
      </Card>
    </SideBar>
  );
}
