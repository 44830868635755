import { Box, Card, Divider, Grid, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React from "react";

export default function Notification() {
  return (
    <>
      <Grid
        item
        sx={{ margin: "auto" }}
        container
        alignItems={"center"}
        justifyContent={"center"}
        margin={"auto"}
        xs={7}
        mt={15}
        mb={5}
      >
        <Card
          sx={{
            border: "0.5px solid #dddddd",
            borderRadius: "14px",
            width: "100%",
            height: "100%",
          }}
        >
          <Box sx={{ margin: "auto", display: "flex" }}>
            <Grid
              item
              xs={10}
              sx={{
                padding: "20px 10px 10px 10px",
              }}
            >
              <Typography
                sx={{
                  justifyContent: "end",
                  fontSize: "22px",
                  fontWeight: "bold",
                }}
              >
                Notifications
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                margin: "auto",
                justifyContent: "center",
              }}
            >
              <Typography>Clear All</Typography>
            </Grid>
          </Box>
          <Box
            sx={{
              // display: "flex",
              // backgroundColor: "#E85656",
              borderRadius: "15px",
              color: "black",
              cursor: "context-menu",
              // color: "white",
            }}
          >
            <Grid container>
              <Box sx={{ width: "inherit" }}>
                <Grid container sx={{ display: "flex" }}>
                  <Grid item xs={10}>
                    <Box sx={{ margin: "20px" }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Lorem ipsum dolor
                      </Typography>
                      <Typography>Lorem ipsum dolor sit amet</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: "flex",
                      margin: "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ textAlign: "center" }}>10:35</Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ width: "100%" }}></Divider>
                <Grid container sx={{ display: "flex" }}>
                  <Grid item xs={10}>
                    <Box sx={{ margin: "20px" }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Lorem ipsum dolor
                      </Typography>
                      <Typography>Lorem ipsum dolor sit amet</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: "flex",
                      margin: "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ textAlign: "center" }}>4h</Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ width: "100%" }}></Divider>
                <Grid container sx={{ display: "flex" }}>
                  <Grid item xs={10}>
                    <Box sx={{ margin: "20px" }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Lorem ipsum dolor
                      </Typography>
                      <Typography>Lorem ipsum dolor sit amet</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: "flex",
                      margin: "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ textAlign: "center" }}>
                      Yesterday 12:35
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ width: "100%" }}></Divider>
                <Box sx={{ margin: "20px" }}>
                  <Box>
                    <Grid container>
                      <Grid item xs={1} sx={{ display: "flex" }}>
                        <AccountCircleIcon
                          sx={{
                            color: "#00000091",
                            fontSize: 50,
                            display: "block",
                            margin: "auto",
                          }}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Sara Foster
                        </Typography>
                        <Typography>
                          Hi, Lorem ipsum dolor sit amet...
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{
                          display: "flex",
                          margin: "auto",
                          justifyContent: "end",
                        }}
                      >
                        <Typography sx={{ textAlign: "center" }}>
                          Yesterday 12:35
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Divider sx={{ width: "100%" }}></Divider>
                <Box sx={{ margin: "20px" }}>
                  <Box>
                    <Grid container>
                      <Grid item xs={1} sx={{ display: "flex" }}>
                        <AccountCircleIcon
                          sx={{
                            color: "#00000091",
                            fontSize: 50,
                            display: "block",
                            margin: "auto",
                          }}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Sara Foster
                        </Typography>
                        <Typography>
                          Hi, Lorem ipsum dolor sit amet...
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{
                          display: "flex",
                          margin: "auto",
                          justifyContent: "end",
                        }}
                      >
                        <Typography sx={{ textAlign: "center" }}></Typography>
                        Yesterday 12:35
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Card>
      </Grid>
    </>
  );
}
