import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import { useState } from "react";
import { Avatar, Divider } from "@mui/material";

export function Review() {
  const userdata = [
    {
      name: "Andy Rock",
      rating: 4,
      review:
        "Integer at faucibus urna. Nullam Condimentun leo id elit saguttis auctor curabutu elementun nauc a leo impredirr nec elemntun dua elementun etiam elemntun euiim commond perion elefend enget nore",
      date: "25/08/2021",
      profile:
        "https://yt3.ggpht.com/ytc/AKedOLSG4NBoqdK6dhlYH45ZYKeoZgFebNl-Awo5fS9vQg=s900-c-k-c0x00ffffff-no-rj",
    },

    {
      name: "Andy Rock",
      rating: 4,
      review:
        "Integer at faucibus urna. Nullam Condimentun leo id elit saguttis auctor curabutu elementun nauc a leo impredirr nec elemntun dua elementun etiam elemntun euiim commond perion elefend enget nore",
      date: "25/08/2021",
      profile:
        "https://yt3.ggpht.com/ytc/AKedOLSG4NBoqdK6dhlYH45ZYKeoZgFebNl-Awo5fS9vQg=s900-c-k-c0x00ffffff-no-rj",
    },
  ];

  const [rating, setRating] = useState(4);

  function userCard(
    name: any,
    ratings: any,
    review: any,
    date: any,
    profile: any,
    i:number
  ) {
    return (
      <Box sx={{ width: "100%" }} mt={3} key={i}>
        <Box
          display={"flex"}
          sx={{ width: "100%" }}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexDirection={"row"}
        >
          <Box display={"flex"} alignItems={"center"} flexDirection={"row"}>
            <Avatar alt="Remy Sharp" src={profile} />
            <Box
              display={"flex"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Typography ml={2} variant="body2" sx={{ color: "black" }}>
                {name}
              </Typography>
              <Rating name="read-only" value={rating} readOnly />
            </Box>
          </Box>

          <Box>
            <Typography variant="body2" color="text.secondary">
              {date}
            </Typography>
          </Box>
        </Box>
        <Box m={2}>
          <Typography variant="body2" color="black">
            {review}
          </Typography>
        </Box>

        <Divider flexItem />
      </Box>
    );
  }

  return (
    <Box
      mt={3}
      ml={3}
      sx={{ width: "90%" }}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <Box
        m={2}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        sx={{
          background: "#FAFAFA 0% 0% no-repeat padding-box",
          width: "100%",
          height: "100px",
        }}
      >
        <Rating name="read-only" value={rating} readOnly />
        <Typography variant="body2" color="text.secondary">
          10 Reviews
        </Typography>
      </Box>

      <Box mt={2} sx={{ width: "100%" }}>
        {userdata.map((e, i) => (
            userCard(e.name, e.rating, e.review, e.date, e.profile, i)
        ))}
      </Box>
    </Box>
  );
}
