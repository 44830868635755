import * as React from 'react';
import Box from '@mui/material/Box';
import { FormControl, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { AddTripInfoRequest } from './interface/addCarInterface';
import { useDispatch, useSelector } from 'react-redux';
import { addTripInfoSelector, setTripInfoFormState } from './slice/addTripInfoSlice';
import { addCarInfoSelector } from './slice/addCarInfoSlice';

export default function AddTripDetails () {
  const tripTypeOptions = [{
    value: 'hour',
    label: 'Hourly'
  }, {
    value: 'day',
    label: 'Daily'
  }, {
    value: 'month',
    label: 'Monthly'
  }];
  const dispatch = useDispatch();
  const [selectedTripType, setSelectedTripType] = React.useState<string>('');
  const { tripInfoData } = useSelector(addTripInfoSelector);
  const { car_id } = useSelector(addCarInfoSelector);
  const [formValues, setFormValues] = React.useState<AddTripInfoRequest>({ ...tripInfoData });

  React.useEffect(() => {
    dispatch(setTripInfoFormState(formValues));
  }, [formValues]);

  React.useEffect(() => {
    setFormValues({ ...formValues, car_id: car_id });
  }, [car_id]);

  const onTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    selectedTripType: string
  ) => {
    setSelectedTripType(selectedTripType);
    setFormValues({ ...formValues, trip_type: selectedTripType, max_duration: 0 });
  };

  const onTripDurationChange = (event: SelectChangeEvent) => {
    const tripDuration = Number(event?.target.value);
    setFormValues({ ...formValues, max_duration: tripDuration });
  };

  const onPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rate = Number(event?.target.value);
    setFormValues({ ...formValues, rental_rate: rate });
  };

  const getDurationOptions = () => {
    const n = selectedTripType === 'hour' ? 24 : selectedTripType === 'day' ? 30 : selectedTripType === 'month' ? 60 : 0;
    const element = [];
    for (let i = 0; i < n; i++) {
      element.push(<MenuItem value={i + 1} key={i}>{i + 1}</MenuItem>);
    };
    return element;
  };

  return (
        <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 3, width: '45%' },
              width: '100%'
            }}
            noValidate
            autoComplete="off"
        >
            <Typography> *All fields are mandatory </Typography>
            <Typography variant='caption'>Trip Type</Typography>
            <Grid container>
                <ToggleButtonGroup
                    color="primary"
                    value={selectedTripType}
                    exclusive
                    onChange={onTypeChange}
                >
                    {
                        tripTypeOptions && tripTypeOptions.length > 0 && tripTypeOptions.map(val => (
                            <ToggleButton value={val.value} key={val.value}>{val.label}</ToggleButton>
                        ))
                    }
                </ToggleButtonGroup>
            </Grid>
            <FormControl sx={{ m: 1 }}>
                <InputLabel>Trip Duration</InputLabel>
                <Select
                    labelId="trip-duration-label"
                    id="trip-duration"
                    label="Trip Duration"
                    value={ tripInfoData.max_duration.toString() || '' }
                    onChange={onTripDurationChange}
                >
                    <MenuItem value={0}>
                        <em>None</em>
                    </MenuItem>
                    { getDurationOptions() }
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="price">Price</InputLabel>
                <OutlinedInput
                    id="price"
                    type='number'
                    value={ tripInfoData.rental_rate || 0 }
                    onChange={onPriceChange}
                    endAdornment={<InputAdornment position="end">SAR</InputAdornment>}
                    label="Amount"
                />
            </FormControl>
        </Box >
  );
}
